let user = {
  username: '',
  userType: '',
};

const getUserData = () => {
  console.log('GETING USER DATA FROM THE ROOTS');
  if (localStorage.length > 0) {
    const user_type = JSON.parse(localStorage.getItem('usertype'));
    user = {
      username: localStorage.getItem('username'),
      usertype: user_type,
    };
    console.log('USER DATA ==>', user);
    return user;
  }
  // console.log("DOES THIS HAPPEN?")
  // fetch(`https://3j6h0vbrp9.execute-api.us-west-2.amazonaws.com/authcerojwt/api/v1/user`, {
  //       headers: {
  //         Authorization: `Bearer ${token.idToken}`,
  //       },
  //     }).then(response => response.json())
  //       .then(data => {
  //         console.log("DATA RESULT ==>",data);
  //         const { can_answer_survey, can_view_dashboard, username } = data.user;
  //         user= {
  //             username: username,
  //             can_answer_survey:can_answer_survey,
  //             can_view_dashboard:can_view_dashboard,
  //         }
  //         // localStorage.setItem('can_answer_survey', can_answer_survey);
  //         // localStorage.setItem('can_view_dashboard', can_view_dashboard);
  //         // localStorage.setItem('username', username);
  //         if(window.location.origin.href !== `${window.location.origin}/dashboard`){
  //           window.location.href =`${window.location.origin}/dashboard`
  //         }
  //         return user;
  //       });
};

export { getUserData };
