/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as am4core from "@amcharts/amcharts4/core";

import MainNav from "components/ui/navbar";
import SearchBar from "components/dashboard/searchBar";
import Intertopic from "components/dashboard/Intertopic";
import RelatedTerms from "components/dashboard/RelatedTerms";
import styles from "styles/modules/newDashboard.module.scss";

// API
import {
  getStaticQa,
  getSemantics,
  getNseComparison,
  getWordCloud,
} from "utils/dashboardCalls/summaryCalls";
import { getQa, getQaLong } from "utils/dashboardCalls/q&aCalls";
import {
  getIntertopic,
  getTopTerms,
  getRelation,
} from "utils/dashboardCalls/overviewCalls";
import {
  getEmotionmeter,
  getSentiment,
  getTopTermsEmotions,
  getTermsSpecificEmotion,
  getEmotionGraph,
} from "utils/dashboardCalls/emotionsCalls";

import QuestionAnswering from "../graphic/Q&A";

import Summary from "./Summary";
import Overview from "./Overview";
import EmotionalProfile from "./EmotionalProfile";
import QA from "./QA";

import "styles/generic.css";

const state = {
  loaded: false,
  loading: false,
  data: {},
  error: false,
};

const initialTerms = ["libertad", "pago", "mujer", "hogar", "amor"];

const NewDashboard = () => {
  const [key, setKey] = useState("summary");
  const [showExamples, setShowExamples] = useState(true);

  // Data
  const [searchTerm, setSearchTerm] = useState("");

  const [semantics, setSemantics] = useState(state);
  const [nseComparison, setNseComparison] = useState(state);
  const [qa, setQa] = useState(state);
  const [topTenTerms, setTopTenTerms] = useState(state);
  const [intertopic, setIntertopic] = useState(state);
  const [relation, setRelation] = useState(state);
  const [emotionData, setEmotionData] = useState(state);
  const [sentimentData, setSentimentData] = useState(state);
  const [topTermsSentiment, setTopTermsSentiment] = useState(state);
  const [topTermsSpecific, setTopTermsSpecific] = useState(state);
  const [emotionGraph, setEmotionGraph] = useState(state);
  const [wordCloud, setWordCloud] = useState(state);
  const [qaLong, setQaLong] = useState(state);

  const [qaExample, setQaExample] = useState([]);
  const [initialTerm, setInitialTerm] = useState([]);

  const { watch, setValue, register } = useForm();

  am4core.options.autoDispose = true;

  useEffect(() => {
    // const getQaExample = async () => {
    //   const response = await getStaticQa();
    //   setQaExample(response);
    // };

    // getQaExample();
    const selected = initialTerms[Math.floor(Math.random() * 5)];
    setInitialTerm(selected);
    getWordCloudData(selected);
    // Para ir prendiendo la lambda:
    getQaLong("qué es mamá?").then((res) => {
      console.log("Res qa", res);
    });
  }, []);

  // Fetchers
  // Summary
  const getSemanticsData = async (query) => {
    try {
      setSemantics({ ...state, loading: true });
      const data = await getSemantics(query);
      setSemantics({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setSemantics({ ...state, loading: false, error: true });
    }
  };

  const getNseComparisonData = async (query) => {
    try {
      setNseComparison({ ...state, loading: true });
      const data = await getNseComparison(query);
      setNseComparison({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setNseComparison({ ...state, loading: false, error: true });
    }
  };

  const getQaData = async (query, question) => {
    try {
      setQa({ ...state, loading: true });
      const data = await getQa(query, question);
      console.log("got qa data||");
      console.log(data);
      setQa({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setQa({ ...state, loading: false, error: true });
    }
  };

  const getTopTermsData = async (query) => {
    try {
      setTopTenTerms({ ...state, loading: true });
      const data = await getTopTerms(query);
      setTopTenTerms({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setTopTenTerms({ ...state, loading: false, error: true });
    }
  };

  const getEmotionMeterData = async (query) => {
    try {
      setEmotionData({ ...state, loading: true });
      const data = await getEmotionmeter(query);
      setEmotionData({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setEmotionData({ ...state, loading: false, error: true });
    }
  };

  // Overview

  const getIntertopicData = async (query) => {
    try {
      setIntertopic({ ...state, loading: true });
      const data = await getIntertopic(query);
      setIntertopic({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setIntertopic({ ...state, loading: false, error: true });
    }
  };

  const getRelationData = async (query1, query2) => {
    try {
      setRelation({ ...state, loading: true });
      const data = await getRelation(query1, query2);
      setRelation({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setRelation({ ...state, loading: false, error: true });
    }
  };

  // Emotional Profile

  const getSentimentData = async (query) => {
    try {
      setSentimentData({ ...state, loading: true });
      const data = await getSentiment(query);
      setSentimentData({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setSentimentData({ ...state, loading: false, error: true });
    }
  };

  const getTopTermsEmotionsData = async (query) => {
    try {
      setTopTermsSentiment({ ...state, loading: true });
      const data = await getTopTermsEmotions(query);
      setTopTermsSentiment({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setTopTermsSentiment({ ...state, loading: false, error: true });
    }
  };

  const getTermsSpecificEmotionData = async (query, emotion) => {
    try {
      setTopTermsSpecific({ ...state, loading: true });
      const data = await getTermsSpecificEmotion(query, emotion);
      setTopTermsSpecific({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setTopTermsSpecific({ ...state, loading: false, error: true });
    }
  };

  const getWordCloudData = async (query) => {
    try {
      setWordCloud({ ...state, loading: true });
      const data = await getWordCloud(query);
      setWordCloud({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setWordCloud({ ...state, loading: false, error: true });
    }
  };

  const getEmotionGraphData = async (query, emotion) => {
    try {
      setEmotionGraph({ ...state, loading: true });
      const data = await getEmotionGraph(query, emotion);
      setEmotionGraph({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setEmotionGraph({ ...state, loading: false, error: true });
    }
  };

  // QA
  const getQaLongData = async (question) => {
    try {
      setQaLong({ ...state, loading: true });
      const data = await getQaLong(question);
      setQaLong({ ...state, loading: false, loaded: true, data });
    } catch (error) {
      setQaLong({ ...state, loading: false, error: true });
    }
  };

  const getAllData = (query) => {
    getSemanticsData(query);
    getNseComparisonData(query);
    getQaData(query, "que es");
    getIntertopicData(query);
    getTopTermsData(query);
    getWordCloudData(query);
    getEmotionMeterData(query);
    getSentimentData(query);
    getTopTermsEmotionsData(query);
    getTermsSpecificEmotionData(query, "felicidad");
    getEmotionGraphData(query, "felicidad");
  };

  return (
    <Container fluid>
      <MainNav />
      <h1 className={styles.title}>
        Explora el mapa temático de la cultura mexicana
      </h1>
      <h2 className={styles.subtitle}>
        Navega sobre el mapa de temáticas para explorar las palabras dentro de
        ellas. Haz click sobre una palabra para analizar su contexto
      </h2>
      <Intertopic
        searchTopic={(term) => {
          setSearchTerm(term);
          getAllData(term);
          setShowExamples(false);
          setValue("searchQuery", term);
        }}
      />
      <Col xs={12} md={12}>
        <SearchBar
          getSummary={(term) => {
            setSearchTerm(term);
            getAllData(term);
            setShowExamples(false);
          }}
          register={register}
          watch={watch}
        />
      </Col>
      {showExamples ? (
        <Row className={styles.examples}>
          <Col xs={12} md={12}>
            <h4 className="mb20">Algunos ejemplos de búsquedas</h4>
          </Col>
          <Col xs={12} md={6}>
            <RelatedTerms
              wordCloud={wordCloud}
              getWordCloudData={getWordCloudData}
              term={initialTerm}
            />
          </Col>
          <Col xs={12} md={6}>
            <QuestionAnswering questionData={qaExample} />
          </Col>
        </Row>
      ) : (
        <Col xs={12} md={12}>
          <Tabs id="controlled-tab-example" activeKey={key} onSelect={setKey}>
            <Tab eventKey="summary" title="Summary">
              <Summary
                searchTerm={searchTerm}
                semantics={semantics}
                getSemanticsData={getSemanticsData}
                qa={qa}
                getQaData={getQaData}
                topTenTerms={topTenTerms}
                getTopTermsData={getTopTermsData}
                nseComparison={nseComparison}
                getNseComparisonData={getNseComparisonData}
                sentimentData={sentimentData}
                getSentimentData={getSentimentData}
                emotionData={emotionData}
                getEmotionMeterData={getEmotionMeterData}
              />
            </Tab>

            <Tab eventKey="overview" title="Overview">
              <Overview
                searchTerm={searchTerm}
                intertopic={intertopic}
                getIntertopicData={getIntertopicData}
                semantics={semantics}
                getSemanticsData={getSemanticsData}
                topTenTerms={topTenTerms}
                getTopTermsData={getTopTermsData}
                relation={relation}
                getRelationData={getRelationData}
                register={register}
                watch={watch}
                wordCloud={wordCloud}
                getWordCloudData={getWordCloudData}
              />
            </Tab>

            <Tab eventKey="emocional" title="Perfil emocional">
              <EmotionalProfile
                searchTerm={searchTerm}
                sentimentData={sentimentData}
                getSentimentData={getSentimentData}
                topTermsSentiment={topTermsSentiment}
                getTopTermsEmotionsData={getTopTermsEmotionsData}
                emotionData={emotionData}
                getEmotionMeterData={getEmotionMeterData}
                topTermsSpecific={topTermsSpecific}
                getTermsSpecificEmotionData={getTermsSpecificEmotionData}
                emotionGraph={emotionGraph}
                getEmotionGraphData={getEmotionGraphData}
              />
            </Tab>

            <Tab eventKey="qa" title="Q&A">
              <QA
                searchTerm={searchTerm}
                qa={qa}
                getQaData={getQaData}
                qaLong={qaLong}
                getQaLongData={getQaLongData}
                register={register}
                watch={watch}
              />
            </Tab>

            {semantics.loaded && (
              <Tab
                tabClassName={styles.docsFound}
                title={`"${searchTerm}" ha sido encontrado ${semantics.data?.docs_found} veces`}
              />
            )}
          </Tabs>
        </Col>
      )}
    </Container>
  );
};

export default NewDashboard;
