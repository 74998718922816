import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import sentimentData from '../../data/top_sentimiento.json';

const SentimentChart = ({ sentimentData2 }) => {
  // Themes begin
  am4core.useTheme(am4themes_animated);
  // Themes end

  /* Create chart instance */
  const chart1 = am4core.create('chartdiv', am4charts.RadarChart);

  /* Create axes */
  const xAxis1 = chart1.xAxes.push(new am4charts.ValueAxis());
  xAxis1.renderer.maxLabelPosition = 0;
  xAxis1.renderer.labels.template.opacity = 0;

  const yAxis1 = chart1.yAxes.push(new am4charts.ValueAxis());
  yAxis1.renderer.labels.template.verticalCenter = 'bottom';
  yAxis1.renderer.labels.template.horizontalCenter = 'right';
  yAxis1.renderer.labels.template.opacity = 0;

  /* Create and configure series */
  const series1 = chart1.series.push(new am4charts.RadarSeries());
  series1.bullets.push(new am4charts.CircleBullet());
  series1.strokeOpacity = 0;
  series1.dataFields.valueX = 'x';
  series1.dataFields.valueY = 'y';
  series1.dataFields.value = 'value';
  series1.dataFields.color = 'color';
  series1.name = series1.dataFields.title = 'title';
  series1.sequencedInterpolation = true;
  series1.tooltip.pointerOrientation = 'vertical';

  // chart1.data = [{
  //   "title": "Palabra1",
  //   "x": 50,
  //   "y": 80,
  //   "value": 48,
  //   "color":"#315074"
  // }, {
  //   "title": "Palabra2",
  //   "x": 30,
  //   "y": 70,
  //   "value": 100,
  //   "color":"#355e87"
  // }, {
  //   "title": "Palabra3",
  //   "x":70,
  //   "y": 70,
  //   "value": 20,
  //   "color":"#396c9c"
  // }, {
  //   "title": "Palabra4",
  //   "x": 10,
  //   "y": 80,
  //   "value": 50,
  //   "color":"#3f7bb0"
  // }, {
  //   "title": "Palabra5",
  //   "x": 10,
  //   "y": 20,
  //   "value": 10,
  //   "color":"#448bc8"
  // }, {
  //   "title": "Palabra6",
  //   "x": 60,
  //   "y": 10,
  //   "value": 10,
  //   "color":"blue"
  // }, {
  //   "title": "Palabra7",
  //   "x": 60,
  //   "y": 70,
  //   "value": 40,
  //   "color":"#6f9fd3"
  // }, {
  //   "title": "Palabra8",
  //   "x": 60,
  //   "y": 20,
  //   "value": 10,
  //   "color":"#90b1dc"
  // }, {
  //   "title": "Palabra9",
  //   "x": 100,
  //   "y": 20,
  //   "value": 50,
  //   "color":"#adc3e5"
  // }, {
  //   "title": "Palabra10",
  //   "x": 90,
  //   "y": 80,
  //   "value": 10,
  //   "color":"#c9d7ee"
  // }];

  // Get Data
  const data = [];
  function getColor(y) {
    if (y > 55) {
      return '#001C5A';
    } if (y <= 55 && y > 53) {
      return '#052A7A';
    } if (y <= 53 && y > 50) {
      return '#0B3384';
    } if (y <= 50 && y > 48) {
      return '#123B8C';
    } if (y <= 48 && y > 45) {
      return '#183D8F';
    } if (y <= 45 && y > 43) {
      return '#21489E';
    } if (y <= 43 && y > 40) {
      return '#';
    } if (y <= 40 && y > 38) {
      return '#1F4B9D';
    } if (y <= 38 && y > 35) {
      return '#2553A5';
    } if (y <= 35 && y > 33) {
      return '#21489E';
    } if (y <= 33 && y > 30) {
      return '#2C5BAD';
    } if (y <= 30 && y > 28) {
      return '#3263B5';
    } if (y <= 28 && y > 25) {
      return '#396BBE';
    } if (y <= 25 && y > 23) {
      return '#4073C6';
    } if (y <= 23 && y > 20) {
      return '#467BCE';
    } if (y <= 20 && y > 18) {
      return '#538BDE';
    } if (y <= 18 && y > 15) {
      return '#5D9BDC';
    } if (y <= 15 && y > 13) {
      return '#598FC8';
    } if (y <= 13 && y > 10) {
      return '#6EA1D6';
    }if (y <= 10 && y > 8) {
      return '#81B1E4';
    }if (y <= 8 && y > 5) {
      return '#8CB9E9';
    } else {
      return '#98C2F0';
    }
  }

  sentimentData2.labels.map((concept, index) => {
    if (index < 10) {
      data.push({
        title: concept,
        x: (index + 1) * 10,
        y: sentimentData2.data[index],
        value: 30,
        color: getColor(sentimentData2.data[index]),
      });
    }
  });

  chart1.data = data;

  chart1.scrollbarX = new am4core.Scrollbar();
  // chart.scrollbarX.minHeight = 5;
  chart1.scrollbarX.width = 100;
  chart1.scrollbarX.parent = chart1.bottomAxesContainer;
  chart1.scrollbarY = new am4core.Scrollbar();
  chart1.scrollbarY.height = 100;

  const bullet1 = series1.bullets.push(new am4core.Circle());
  bullet1.fill = am4core.color('color');
  bullet1.propertyFields.fill = 'color';
  bullet1.strokeOpacity = 0;
  bullet1.strokeWidth = 0;
  bullet1.fillOpacity = 1;
  bullet1.stroke = am4core.color('color');
  bullet1.hiddenState.properties.opacity = 0;
  bullet1.tooltipText = '[bold]{title}:[/] {value.value}';
  bullet1.title = '[bold]{title}:[/] {value.value}';

  const labelBullet1 = series1.bullets.push(new am4charts.LabelBullet());
  labelBullet1.label.text = '{title}';
  labelBullet1.label.dy = 0;

  const hoverState1 = bullet1.states.create('hover');
  hoverState1.properties.fillOpacity = 1;
  hoverState1.properties.strokeOpacity = 1;

  series1.heatRules.push({
    target: bullet1,
    min: 10,
    max: 30,
    property: 'radius',
    logarithmic: true,
  });

  return <div id="chartdiv" style={{ width: '100%', height: '500px' }} />;
};

export default SentimentChart;
