import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Accordion,
  useAccordionToggle,
  Alert,
} from 'react-bootstrap';
import parse from 'html-react-parser';

import '../styles/carga.css';
import '../styles/generic.css';
import '../styles/validation.css';
import statesData from '../data/ciudades.json';
import {
  getProjectsList,
  getClientsList,
  validatePath,
} from '../utils/validationCalls';

const DocumentInfo = ({
  pathInfo,
  fullProjects,
  fullClients,
  pathValidated,
}) => {
  const accessToken = localStorage.getItem('accessToken');
  const originalPathInfo = { ...pathInfo };

  // State variables
  const [projects, setProjects] = useState(fullProjects);
  const [clients, setClients] = useState(fullClients);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [validated, setValidated] = useState({
    nse: null,
    pais: null,
    estado: null,
    ciudad: null,
    proyecto: null,
    cliente: null,
  });
  const [warning, setWarning] = useState(false);
  const [buttonRedClass, setButtonRedClass] = useState({
    nse: 'round-button btn-red',
    pais: 'round-button btn-red',
    estado: 'round-button btn-red',
    ciudad: 'round-button btn-red',
    proyecto: 'round-button btn-red',
    cliente: 'round-button btn-red',
  });
  const [buttonGreenClass, setButtonGreenClass] = useState({
    nse: 'round-button btn-green',
    pais: 'round-button btn-green',
    estado: 'round-button btn-green',
    ciudad: 'round-button btn-green',
    proyecto: 'round-button btn-green',
    cliente: 'round-button btn-green',
  });
  const [labelClass, setLabelClass] = useState({
    nse: 'obj-info',
    pais: 'obj-info',
    estado: 'obj-info',
    ciudad: 'obj-info',
    proyecto: 'obj-info',
    cliente: 'obj-info',
  });
  const [validateButton, setValidateButton] = useState(
    'btn-blue validation-button-small ftn11 text-center'
  );

  useEffect(() => {
    // run on load
    if (pathInfo.info.estado && statesData[pathInfo.info.estado]) {
      setCities(statesData[pathInfo.info.estado]);
    }
  });

  function CustomToggle({ type_key, children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
      console.log('totally custom!')
    );

    return (
      <button
        id={`${pathInfo.id}-${type_key}-button-red`}
        type="button"
        className={buttonRedClass[type_key]}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  async function checkFinished() {
    let empty_warning = false;

    // chack that all the fields have a non-empty value
    for (const key in validated) {
      if (validated[key] === null || validated[key] === '') {
        empty_warning = true;
      }
    }

    setWarning(empty_warning);

    if (!empty_warning) {
      setValidateButton('btn-gray validation-button-small ftn11 text-center');
      const response = await validatePath(pathInfo.id, validated, accessToken);
      if (response === 'OK') {
        pathValidated(pathInfo.id);
      }
    }
  }

  function changeStyles(key, new_value, isOriginal) {
    if (new_value !== '') {
      // change styles only if new value isn't empty
      pathInfo.info[key] = new_value;

      setButtonGreenClass({
        ...buttonGreenClass,
        [key]: 'round-button btn-gray',
      });

      setButtonRedClass({
        ...buttonRedClass,
        [key]: 'round-button btn-gray',
      });

      setLabelClass({
        ...labelClass,
        [key]: 'validated-box',
      });

      if (!isOriginal) {
        // click toggle button
        document.getElementById(`${pathInfo.id}-${key}-button-red`).click();
      }
    }
  }

  function saveValid(isOriginal, key) {
    let new_val = '';
    let objId = '';

    if (isOriginal) {
      objId = `${pathInfo.id}-${key}-og`;
      new_val = document.getElementById(objId).textContent;
    } else {
      objId = `${pathInfo.id}-${key}`;
      new_val = document.getElementById(objId).value;

      if (
        (new_val === 'otro' || new_val === '') &&
        (key === 'proyecto' || key === 'cliente' || key === 'pais')
      ) {
        objId = `${objId}-text`;
        new_val = document.getElementById(objId).value;
      }
    }

    validated[key] = new_val;
    changeStyles(key, new_val, isOriginal);

    setValidated(validated);
  }

  function renderProjects() {
    const dd_options = [
      <option value="No sé">No s&eacute;</option>,
      <option value="otro"> Otro </option>,
    ];
    projects.forEach((p) => {
      dd_options.push(
        <option key={p} value={p}>
          {parse(p)}
        </option>
      );
    });

    return dd_options;
  }

  function changeProject(dd_id) {
    const new_project = document.getElementById(dd_id).value;
    // change client list based on the selected project
    if (new_project === 'otro') {
      document.getElementById(`${dd_id}-text`).classList.remove('hidden');
    } else {
      document.getElementById(`${dd_id}-text`).classList.add('hidden');
      if (new_project == 'No sé') {
        setClients(fullClients);
      } else {
        getClientsList(new_project, accessToken).then((data) => {
          setClients(data);
        });
      }
    }
  }

  function renderClients() {
    const dd_options = [
      <option value="No sé">No s&eacute;</option>,
      <option value="otro"> Otro </option>,
    ];
    clients.forEach((c) => {
      dd_options.push(
        <option key={c} value={c}>
          {parse(c)}
        </option>
      );
    });

    return dd_options;
  }

  function changeClient(dd_id) {
    // Change project list based on the selected client
    const new_client = document.getElementById(dd_id).value;
    if (new_client === 'otro') {
      document.getElementById(`${dd_id}-text`).classList.remove('hidden');
    } else {
      document.getElementById(`${dd_id}-text`).classList.add('hidden');
      if (new_client === 'No sé') {
        setProjects(fullProjects);
      } else {
        getProjectsList(new_client, accessToken).then((data) => {
          setProjects(data);
        });
      }
    }
  }

  function changeCountry(dd_id) {
    const new_country = document.getElementById(dd_id).value;
    if (new_country === 'otro') {
      document.getElementById(`${dd_id}-text`).classList.remove('hidden');
      setStates([]);
      setCities([]);
    } else {
      document.getElementById(`${dd_id}-text`).classList.add('hidden');
      if (new_country == 'México') {
        setStates(Object.keys(statesData));
      } else {
        setStates([]);
      }
      setCities([]);
    }
  }

  function renderCities() {
    const dd_options = [
      <option value="No sé">No s&eacute;</option>,
      <option value="No aplica">No aplica</option>,
    ];
    cities.sort().forEach((c) => {
      dd_options.push(
        <option key={c} value={c}>
          {parse(c)}
        </option>
      );
    });

    return dd_options;
  }

  function changeState(dd_id) {
    // load city's state (if it's a mexican city)
    const new_state = document.getElementById(dd_id).value;
    if (!statesData[new_state]) {
      setCities([]);
    } else {
      setCities(statesData[new_state]);
    }
  }

  function renderStates() {
    const dd_options = [
      <option value="No sé">No s&eacute;</option>,
      <option value="No aplica">No aplica</option>,
    ];
    states.forEach((state) => {
      dd_options.push(
        <option key={state} value={state}>
          {parse(state)}
        </option>
      );
    });
    return dd_options;
  }

  return (
    <Form id={pathInfo.id} className="mb60" inline>
      <Col md={4} sm={12}>
        <p className="mr-2 inline-label font-medium ftn15 mb10">
          Ejemplo - Portada de documento dentro de la carpeta
        </p>
        <div className="img-doc" sm={12}>
          <img alt="portada" src={pathInfo.first_page} className="img-fluid" />
        </div>
      </Col>
      <Col md={8} sm={12}>
        <Row>
          <Col md={3} sm={12} className="pb10">
            <p className="my-1 mr-2 inline-label font-medium ftn15">
              Fecha de creación
            </p>
            <div id="DateCreation" className="inputValid max-width-100">
              {pathInfo.created_date}
            </div>
          </Col>
          <Col md={9} sm={12} className="pb10">
            <p className="my-1 mr-2 inline-label font-medium ftn15">
              Ubicación de la Carpeta
            </p>
            <div id="docPath" className="inputValid max-width-100">
              {' '}
              {pathInfo.path.replaceAll('/', ' > ')}{' '}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12} className="inline-label">
            <Accordion className="pt10p pb5p">
              <Card className="card-content">
                <p className="my-1 mr-2 inline-label ftn11">
                  A continuación marca si el <b>NSEyC</b> detectado es correcto
                  o incorrecto:
                </p>
                <Card.Header className="p0">
                  <Row>
                    <Form.Group className="FilterContainer">
                      <div
                        id={`${pathInfo.id}-nse-og`}
                        className={labelClass.nse}
                      >
                        {pathInfo.info.nse}
                      </div>
                    </Form.Group>
                    <Form.Group className="inline-label">
                      <button
                        id={`${pathInfo.id}-nse-button-green`}
                        type="button"
                        className={buttonGreenClass.nse}
                        onClick={(e) => saveValid(true, 'nse')}
                      >
                        <img
                          src="/img/icon-checkmark.svg"
                          className=""
                          width="100%"
                        />
                      </button>
                      <CustomToggle
                        type_key="nse"
                        id={`${pathInfo.id}-nse-toggle`}
                        className={buttonRedClass.nse}
                        eventKey="0"
                      >
                        <img
                          src="/img/icon-cross.svg"
                          className=""
                          width="100%"
                        />
                      </CustomToggle>
                    </Form.Group>
                  </Row>
                </Card.Header>

                <Col md={12} sm={12} className="pt10">
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="p0">
                      <p className="font-medium txt_left pb10 ftn11">
                        Ingresa la respuesta correcta
                      </p>
                      <Form inline>
                        <Form.Group className="FilterContainer">
                          <Form.Group className="FilterContainer">
                            <Form.Control
                              id={`${pathInfo.id}-nse`}
                              as="select"
                              className="my-1 mr-sm-2 selectProj"
                              custom
                            >
                              <option value="Alto">Alto</option>
                              <option value="Medio Alto">Medio Alto</option>
                              <option value="Medio Típico">
                                Medio T&iacute;pico
                              </option>
                              <option value="Medio Bajo">Medio Bajo</option>
                              <option value="Bajo">Bajo</option>
                              <option value="No sé">No s&eacute;</option>
                              <option value="No aplica">No aplica</option>
                            </Form.Control>
                          </Form.Group>
                        </Form.Group>
                        <Form.Group className="inline-label float-r">
                          <button
                            type="button"
                            id="btnRegisterForm"
                            className="SearchButton ftn11"
                            onClick={(e) => saveValid(false, 'nse')}
                          >
                            Enviar respuesta
                          </button>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Col>
              </Card>
            </Accordion>
          </Col>
          <Col md={6} sm={12} className="inline-label">
            <Accordion className="pt10p pb5p">
              <Card className="card-content">
                <p className="my-1 mr-2 inline-label ftn11">
                  A continuación marca si el <b>pa&iacute;s</b> detectado es
                  correcto o incorrecto:
                </p>
                <Card.Header className="p0">
                  <Row>
                    <Form.Group className="FilterContainer">
                      <div
                        id={`${pathInfo.id}-pais-og`}
                        className={labelClass.pais}
                      >
                        {pathInfo.info.pais}
                      </div>
                    </Form.Group>
                    <Form.Group className="inline-label">
                      <button
                        id={`${pathInfo.id}-pais-button-green`}
                        type="button"
                        className={buttonGreenClass.pais}
                        onClick={(e) => saveValid(true, 'pais')}
                      >
                        <img
                          src="/img/icon-checkmark.svg"
                          className=""
                          width="100%"
                        />
                      </button>
                      <CustomToggle
                        type_key="pais"
                        id={`${pathInfo.id}-pais-button-red`}
                        className={buttonRedClass.pais}
                        eventKey="0"
                      >
                        <img
                          src="/img/icon-cross.svg"
                          className=""
                          width="100%"
                        />
                      </CustomToggle>
                    </Form.Group>
                  </Row>
                </Card.Header>

                <Col md={12} sm={12} className="pt10">
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="p0">
                      <p className="font-medium txt_left pb10 ftn11">
                        Ingresa la respuesta correcta
                      </p>
                      <Form inline>
                        <Form.Group className="FilterContainer">
                          <Form.Group className="FilterContainer">
                            <Form.Control
                              id={`${pathInfo.id}-pais`}
                              as="select"
                              className="my-1 mr-sm-2 selectProj"
                              onChange={(e) =>
                                changeCountry(`${pathInfo.id}-pais`)
                              }
                              custom
                            >
                              <option value="No sé">No s&eacute;</option>
                              <option value="México">M&eacute;xico</option>
                              <option value="Chile">Chile</option>
                              <option value="Perú">Per&uacute;</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Canadá">Canad&aacute;</option>
                              <option value="otro">Otro</option>
                            </Form.Control>
                            <Form.Control
                              id={`${pathInfo.id}-pais-text`}
                              className="inputTerms inputNewClient hidden"
                              placeholder="Ingresa el país"
                            />
                          </Form.Group>
                        </Form.Group>
                        <Form.Group className="inline-label float-r">
                          <button
                            type="button"
                            id="btnRegisterForm"
                            className="SearchButton ftn11"
                            onClick={(e) => saveValid(false, 'pais')}
                          >
                            Enviar respuesta
                          </button>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Col>
              </Card>
            </Accordion>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12} className="inline-label">
            <Accordion className="pt10p pb5p">
              <Card className="card-content">
                <p className="my-1 mr-2 inline-label ftn11">
                  A continuación marca si el <b>estado</b> detectado es correcto
                  o incorrecto:
                </p>
                <Card.Header className="p0">
                  <Row>
                    <Form.Group className="FilterContainer">
                      <div
                        id={`${pathInfo.id}-estado-og`}
                        className={labelClass.estado}
                      >
                        {pathInfo.info.estado}
                      </div>
                    </Form.Group>
                    <Form.Group className="inline-label">
                      <button
                        id={`${pathInfo.id}-estado-button-green`}
                        type="button"
                        className={buttonGreenClass.estado}
                        onClick={(e) => saveValid(true, 'estado')}
                      >
                        <img
                          src="/img/icon-checkmark.svg"
                          className=""
                          width="100%"
                        />
                      </button>
                      <CustomToggle
                        type_key="estado"
                        id={`${pathInfo.id}-estado-button-red`}
                        className={buttonRedClass.estado}
                        eventKey="0"
                      >
                        <img
                          src="/img/icon-cross.svg"
                          className=""
                          width="100%"
                        />
                      </CustomToggle>
                    </Form.Group>
                  </Row>
                </Card.Header>

                <Col md={12} sm={12} className="pt10">
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="p0">
                      <p className="font-medium txt_left pb10 ftn11">
                        Ingresa la respuesta correcta
                      </p>
                      <Form inline>
                        <Form.Group className="FilterContainer">
                          <Form.Group className="FilterContainer">
                            <Form.Control
                              id={`${pathInfo.id}-estado`}
                              as="select"
                              className="my-1 mr-sm-2 selectProj"
                              onChange={(e) =>
                                changeState(`${pathInfo.id}-estado`)
                              }
                              custom
                            >
                              {renderStates()}
                            </Form.Control>
                          </Form.Group>
                        </Form.Group>
                        <Form.Group className="inline-label float-r">
                          <button
                            type="button"
                            id="btnRegisterForm"
                            className="SearchButton ftn11"
                            onClick={(e) => saveValid(false, 'estado')}
                          >
                            Enviar respuesta
                          </button>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Col>
              </Card>
            </Accordion>
          </Col>
          <Col md={6} sm={12} className="inline-label">
            <Accordion className="pt10p pb5p">
              <Card className="card-content">
                <p className="my-1 mr-2 inline-label ftn11">
                  A continuación marca si la <b>ciudad</b> detectada es
                  correcta:
                </p>
                <Card.Header className="p0">
                  <Row>
                    <Form.Group className="FilterContainer">
                      <div
                        id={`${pathInfo.id}-ciudad-og`}
                        className={labelClass.ciudad}
                      >
                        {pathInfo.info.ciudad}
                      </div>
                    </Form.Group>
                    <Form.Group className="inline-label">
                      <button
                        id={`${pathInfo.id}-ciudad-button-green`}
                        type="button"
                        className={buttonGreenClass.ciudad}
                        onClick={(e) => saveValid(true, 'ciudad')}
                      >
                        <img
                          src="/img/icon-checkmark.svg"
                          className=""
                          width="100%"
                        />
                      </button>
                      <CustomToggle
                        type_key="ciudad"
                        id={`${pathInfo.id}-ciudad-button-red`}
                        className={buttonRedClass.ciudad}
                        eventKey="0"
                      >
                        <img
                          src="/img/icon-cross.svg"
                          className=""
                          width="100%"
                        />
                      </CustomToggle>
                    </Form.Group>
                  </Row>
                </Card.Header>

                <Col md={12} sm={12} className="pt10">
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="p0">
                      <p className="font-medium txt_left pb10 ftn11">
                        Ingresa la respuesta correcta
                      </p>
                      <Form inline>
                        <Form.Group className="FilterContainer">
                          <Form.Group className="FilterContainer">
                            <Form.Control
                              id={`${pathInfo.id}-ciudad`}
                              as="select"
                              className="my-1 mr-sm-2 selectProj"
                              custom
                            >
                              {renderCities()}
                            </Form.Control>
                          </Form.Group>
                        </Form.Group>
                        <Form.Group className="inline-label float-r">
                          <button
                            type="button"
                            id="btnRegisterForm"
                            className="SearchButton ftn11"
                            onClick={(e) => saveValid(false, 'ciudad')}
                          >
                            Enviar respuesta
                          </button>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Col>
              </Card>
            </Accordion>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12} className="inline-label">
            <Accordion className="pt10p pb5p">
              <Card className="card-content">
                <p className="my-1 mr-2 inline-label ftn11">
                  A continuación marca si el <b>cliente</b> detectado es
                  correcto:
                </p>
                <Card.Header className="p0">
                  <Row>
                    <Form.Group className="FilterContainer">
                      <div
                        id={`${pathInfo.id}-cliente-og`}
                        className={labelClass.cliente}
                      >
                        {pathInfo.info.cliente}
                      </div>
                    </Form.Group>
                    <Form.Group className="inline-label">
                      <button
                        id={`${pathInfo.id}-cliente-button-green`}
                        type="button"
                        className={buttonGreenClass.cliente}
                        onClick={(e) => saveValid(true, 'cliente')}
                      >
                        <img
                          src="/img/icon-checkmark.svg"
                          className=""
                          width="100%"
                        />
                      </button>
                      <CustomToggle
                        type_key="cliente"
                        id={`${pathInfo.id}-cliente-button-red`}
                        className={buttonRedClass.cliente}
                        eventKey="0"
                      >
                        <img
                          src="/img/icon-cross.svg"
                          className=""
                          width="100%"
                        />
                      </CustomToggle>
                    </Form.Group>
                  </Row>
                </Card.Header>

                <Col md={12} sm={12} className="pt10">
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="p0">
                      <p className="font-medium txt_left pb10 ftn11">
                        Ingresa la respuesta correcta
                      </p>
                      <Form inline>
                        <Form.Group className="FilterContainer">
                          <Col md={12}>
                            <Form.Group className="FilterContainer">
                              <Form inline>
                                <Form.Control
                                  id={`${pathInfo.id}-cliente`}
                                  as="select"
                                  className="my-1 mr-sm-2 select-width"
                                  onChange={(e) =>
                                    changeClient(`${pathInfo.id}-cliente`)
                                  }
                                  custom
                                >
                                  {renderClients()}
                                </Form.Control>
                                <Form.Control
                                  id={`${pathInfo.id}-cliente-text`}
                                  className="inputTerms inputNewClient hidden"
                                  placeholder="Ingresa al Cliente"
                                />
                              </Form>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        <Form.Group className="inline-label float-r">
                          <button
                            type="button"
                            id="btnRegisterForm"
                            className="SearchButton ftn11"
                            onClick={(e) => saveValid(false, 'cliente')}
                          >
                            Enviar respuesta
                          </button>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Col>
              </Card>
            </Accordion>
          </Col>
          <Col md={6} sm={12} className="inline-label">
            <Accordion className="pt10p pb5p">
              <Card className="card-content">
                <p className="my-1 mr-2 inline-label ftn11">
                  A continuación marca si el <b>proyecto</b> detectado es
                  correcto:
                </p>
                <Card.Header className="p0">
                  <Row>
                    <Form.Group className="FilterContainer">
                      <div
                        id={`${pathInfo.id}-proyecto-og`}
                        className={labelClass.proyecto}
                      >
                        {pathInfo.info.proyecto}
                      </div>
                    </Form.Group>
                    <Form.Group className="inline-label">
                      <button
                        id={`${pathInfo.id}-proyecto-button-green`}
                        type="button"
                        className={buttonGreenClass.proyecto}
                        onClick={(e) => saveValid(true, 'proyecto')}
                      >
                        <img
                          src="/img/icon-checkmark.svg"
                          className=""
                          width="100%"
                        />
                      </button>
                      <CustomToggle
                        type_key="proyecto"
                        id={`${pathInfo.id}-proyecto-button-red`}
                        className={buttonRedClass.proyecto}
                        eventKey="0"
                      >
                        <img
                          src="/img/icon-cross.svg"
                          className=""
                          width="100%"
                        />
                      </CustomToggle>
                    </Form.Group>
                  </Row>
                </Card.Header>

                <Col md={12} sm={12} className="pt10">
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="p0">
                      <p className="font-medium txt_left pb10 ftn11">
                        Ingresa la respuesta correcta
                      </p>
                      <Form inline>
                        <Form.Group className="FilterContainer">
                          <Col md={12}>
                            <Form.Group className="FilterContainer">
                              <Form inline>
                                <Form.Control
                                  id={`${pathInfo.id}-proyecto`}
                                  as="select"
                                  className="my-1 mr-sm-2 select-width"
                                  onChange={(e) =>
                                    changeProject(`${pathInfo.id}-proyecto`)
                                  }
                                  custom
                                >
                                  {renderProjects()}
                                </Form.Control>
                                <Form.Control
                                  id={`${pathInfo.id}-proyecto-text`}
                                  className="inputTerms inputNewClient hidden"
                                  placeholder="Ingresa el proyecto"
                                />
                              </Form>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                        <Form.Group className="inline-label float-r">
                          <button
                            type="button"
                            id="btnRegisterForm"
                            className="SearchButton ftn11"
                            onClick={(e) => saveValid(false, 'proyecto')}
                          >
                            Enviar respuesta
                          </button>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Col>
              </Card>
            </Accordion>
          </Col>
        </Row>
        <Row>
          <Col className="pt10 center-column">
            {warning ? (
              <Alert variant="warning" className={alert}>
                Por favor valida todos los campos antes de enviar
              </Alert>
            ) : null}
            <button
              type="button"
              id="btnRegisterForm"
              className={validateButton}
              onClick={checkFinished}
              name="subcategoria"
            >
              Validar entidad
            </button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default DocumentInfo;
