import React, { useState, useEffect } from "react";
import clsx from "clsx";
import max from "lodash/max";
import { Row, Col, Card, Accordion, Form } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import QaLong from "components/dashboard/qaLong";
import ComponentWithState from "components/ComponentWithState";
import styles from "styles/modules/newDashboard.module.scss";

import QuestionAnswering from "../graphic/Q&A";

function QA({
  searchTerm,
  qa,
  getQaData,
  qaLong,
  getQaLongData,
  register,
  watch,
}) {
  // const [showOther, setShowOther] = useState(false)

  // useEffect(() => {
  //   if(qaLong.loaded == true){
  //     setShowOther(true)
  //   }
  //   if(qaLong.loading == true || qaLong.data === undefined){
  //     setShowOther(false)
  //   }
  // }, qaLong)

  return (
    <Col md={12} sm={12} className="contSummary">
      <Row>
        <Col md={6} sm={12} className={clsx(styles.section, "pt25")}>
          {(qa.loading || qa.error) && (
            <p className="ftn13 pb10 txt_bold">
              Top 10 respuestas ¿Qué es "{searchTerm}"? de 10,263 Documentos
              <Tooltip
                arrow
                title={`Esta gráfica muestra las respuestas más probables a la pregunta de ¿Qué
              es ____? Donde la línea en blanco representa el término buscado. Por
              ejemplo, ante la pregunta de ¿Qué es salud?, el modelo puede responder,
              "Salud es fisioterapia", "Salud es tiempo libre", etc. El índice de
              certeza se refiere a qué tan bien la respuesta responde a la pregunta.`}
                placement="top"
              >
                <InfoIcon
                  style={{ width: 12, color: "#242424", marginLeft: 5 }}
                />
              </Tooltip>
            </p>
          )}
          <ComponentWithState
            state={qa}
            reload={() => getQaData(searchTerm, "que es")}
          >
            <QuestionAnswering questionData={qa.data} searchTerm={searchTerm} />
          </ComponentWithState>
          {/* Otras Respuestas QALONG */}
          {/* {showOther ? (
            <>
            <Col md={12} sm={12} className="back-gray borderR-15 mt20 p20">
            <div>
              <p className="ftn13 pl20 pr30 pt10">{qaLong.data[6].text}</p>
            </div>
            </Col>
            <Col md={12} sm={12} className="back-gray borderR-15 mt20 p20">
            <div>
              <p className="ftn13 pl20 pr30 pt10">{qaLong.data[7].text}</p>
            </div>
            </Col>
            <Col md={12} sm={12} className="back-gray borderR-15 mt20 p20">
            <div>
              <p className="ftn13 pl20 pr30 pt10">{qaLong.data[8].text}</p>
            </div>
            </Col>
            <Col md={12} sm={12} className="back-gray borderR-15 mt20 p20">
            <div>
              <p className="ftn13 pl20 pr30 pt10">{qaLong.data[9].text}</p>
            </div>
            </Col>
            </>
          ) : null} */}
        </Col>
        <Col className={clsx(styles.section, "pt25")}>
          <div>
            <p className="ftn13 pb10 txt_bold">
              Realiza tu propia búsqueda
              <Tooltip arrow title="Falta descripción" placement="top">
                <InfoIcon
                  style={{ width: 12, color: "#242424", marginLeft: 5 }}
                />
              </Tooltip>
            </p>
          </div>
          <QaLong
            qaLong={qaLong}
            searchTerm={searchTerm}
            getQaLongData={getQaLongData}
            register={register}
            watch={watch}
          />
        </Col>
      </Row>
    </Col>
  );
}

export default QA;
