import React, { useEffect } from "react";
import clsx from "clsx";
import max from "lodash/max";
import { Row, Col } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import ComponentWithState from "components/ComponentWithState";
import TopTerms from "components/dashboard/topTerms";
import styles from "styles/modules/newDashboard.module.scss";

import DoughnutChar from "../graphic/DoughnutChar";
import QuestionAnswering from "../graphic/Q&A";
import SunburstChart from "../graphic/SunburstChart";
import SentimentChart2 from "../graphic/SentimentChart2";
import Emotionmeter from "../graphic/Emotionmeter";

function Summary({
  searchTerm,
  semantics,
  getSemanticsData,
  qa,
  getQaData,
  topTenTerms,
  getTopTermsData,
  nseComparison,
  getNseComparisonData,
  sentimentData,
  getSentimentData,
  emotionData,
  getEmotionMeterData,
}) {
  const getTopEmotion = () => {
    const keys = Object.keys(emotionData.data);
    const values = Object.values(emotionData.data);
    const maxValue = max(values);
    return emotionData.loaded
      ? keys.filter((key) => emotionData.data[key] === maxValue)
      : [];
  };

  const topEmotion = getTopEmotion();

  return (
    <Col md={12} sm={12} className="contSummary">
      <Row>
        <Col md={6} sm={12} className={clsx(styles.section, "pt25")}>
          <p className="ftn13 pb10 txt_bold">
            Semánticas generales con las que se relaciona “{searchTerm}”
            <Tooltip
              arrow
              title="Esta gráfica muestra las clasificaciones semánticas más frecuentes
              para los párrafos en donde se encuentra el término buscado. El
              porcentaje mostrado es la proporción de textos en que dicha
              clasificación semántica aparece con respecto al total de parráfos en
              donde se encuentra el término buscado."
              placement="top"
            >
              <InfoIcon
                style={{ width: 12, color: "#242424", marginLeft: 5 }}
              />
            </Tooltip>
          </p>
          <ComponentWithState
            state={semantics}
            reload={() => getSemanticsData(searchTerm)}
          >
            <DoughnutChar semanticData={semantics.data?.result} />
          </ComponentWithState>
        </Col>
        <Col md={6} sm={12} className={clsx(styles.section, "pt25")}>
          {(qa.loading || qa.error) && (
            <p className="ftn13 pb10 txt_bold">
              Top 10 respuestas ¿Qué es "{searchTerm}"? de 10,263 Documentos
              <Tooltip
                arrow
                title={`Esta gráfica muestra las respuestas más probables a la pregunta de ¿Qué
              es ____? Donde la línea en blanco representa el término buscado. Por
              ejemplo, ante la pregunta de ¿Qué es salud?, el modelo puede responder,
              "Salud es fisioterapia", "Salud es tiempo libre", etc. El índice de
              certeza se refiere a qué tan bien la respuesta responde a la pregunta.`}
                placement="top"
              >
                <InfoIcon
                  style={{ width: 12, color: "#242424", marginLeft: 5 }}
                />
              </Tooltip>
            </p>
          )}
          <ComponentWithState
            state={qa}
            reload={() => getQaData(searchTerm, "que es")}
          >
            <QuestionAnswering questionData={qa.data} searchTerm={searchTerm} />
          </ComponentWithState>
        </Col>
        <TopTerms
          topData={topTenTerms}
          searchTerm={searchTerm}
          reload={() => getTopTermsData(searchTerm)}
        />
        <Col md={6} sm={12} className={clsx(styles.section, "pt25")}>
          <div>
            <p className="ftn13 pb10 txt_bold">
              Top 10 términos en todo el universo de búsqueda relacionado al
              término “{searchTerm}” comparando por Nivel Socieconómico y
              Cultural.
              <Tooltip
                arrow
                title="Los diez términos más frecuentes dentro de la lista de los párrafos
                en que aparece el término buscado agrupados por nivel socieconómico
                y cultural. El puntaje mostrado en cada nivel representa la
                frecuencia de aparición de ese término en dicho nivel."
                placement="top"
              >
                <InfoIcon
                  style={{ width: 12, color: "#242424", marginLeft: 5 }}
                />
              </Tooltip>
            </p>
          </div>
          <ComponentWithState
            state={nseComparison}
            reload={() => {
              getNseComparisonData(searchTerm);
            }}
          >
            {/* {nseComparison} */}
            <SunburstChart nseData={nseComparison.data} />
          </ComponentWithState>
        </Col>
        <Col md={6} sm={12} className={clsx(styles.section, "pt25")}>
          <div>
            <p className="ftn13 pb10 txt_bold">
              Top 10 términos en todo el universo de búsqueda relacionado al
              término “{searchTerm}” comparando por sentimiento.
              <Tooltip
                arrow
                title="Los diez términos más frecuentes dentro de la lista de párrafos en
                que aparece el término buscado y que corresponden a un sentimiento
                predominante. Mientras más cerca se encuentren del centro, los
                términos se relacionan con el término de búsqueda de manera más
                positiva."
                placement="top"
              >
                <InfoIcon
                  style={{ width: 12, color: "#242424", marginLeft: 5 }}
                />
              </Tooltip>
            </p>
          </div>
          <ComponentWithState
            state={sentimentData}
            reload={() => getSentimentData(searchTerm)}
          >
            <SentimentChart2 sentimentData={sentimentData.data} />
          </ComponentWithState>
        </Col>
        <Col md={12} sm={12} className={clsx(styles.section, "pt25 pb25")}>
          <div>
            <p className="ftn13 pb10 txt_bold">
              Emotionmeter
              <Tooltip
                arrow
                title={`Porcentaje de párrafos en que el terminó buscado se encuentra en un
                contexto etiquetado con alguna de las siguientes emociones
                universales: Alegría, Miedo, Enojo, Tristeza o Disgusto. Los
                porcentajes corresponden a la proporción de instancias en que se usó
                el término en el contexto de alguna de estas emociones respecto al
                total de menciones del término. Los porcentajes pueden no sumar cien
                debido a que algunos textos fueron catalogados con un sentimiento
                "neutral".`}
                placement="top"
              >
                <InfoIcon
                  style={{ width: 12, color: "#242424", marginLeft: 5 }}
                />
              </Tooltip>
            </p>
          </div>
          <Row>
            <Col md={9} sm={12}>
              <Row>
                <ComponentWithState
                  state={emotionData}
                  reload={() => getEmotionMeterData(searchTerm)}
                >
                  <Emotionmeter emotionData={emotionData.data} />
                </ComponentWithState>
              </Row>
            </Col>
            <Col md={3} sm={12}>
              <p className="ftn15 pb10 txt_bold">
                El término “{searchTerm}” se asocia predominantemente con la
                emoción {topEmotion.join(", ") || "- -"}.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default Summary;
