import React, { useState, useEffect } from 'react';
// import topData from "../../data/top_10_terminos.json";
import { Row, Col, Carousel, Form, Table } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import parse from 'html-react-parser';
import clsx from 'clsx';


import ComponentWithState from 'components/ComponentWithState';
import TermsRelation from 'components/dashboard/termsRelation';
import styles from 'styles/modules/newDashboard.module.scss';

const RelatedOverview = ({
  relation,
  searchTerm,
  getRelationData,
  register,
  watch,
  setContextOutside,
}) => {
  const [searchTerm2, setSearchTerm2] = useState('');

  const searchTermRelation = () => {
    const { searchQuery2 } = watch();
    setSearchTerm2(searchQuery2);
    getRelationData(searchTerm, searchQuery2);
    setContextOutside(null);
  };

  return (
    <>
      <p className="ftn13 pb10">
        Realiza una búsqueda para encontrar la relación entre “{searchTerm}” y
        algún concepto adicional
        <Row className="pt10 pb10" >
          <Col md={4} sm={12} className="searchTerm d-flex">
            <Form.Control
              onKeyDown={({ key }) => key === 'Enter' && searchTermRelation()}
              id="searchQuery2"
              className="obj-info-large"
              placeholder="Escuela"
              {...register('searchQuery2')}
            />
          </Col>
          <Col>
            <button
              type="button"
              id="SearchButton2"
              className="SearchButton"
              onClick={searchTermRelation}
            >
              Buscar
            </button>
          </Col>
        </Row>
        <ComponentWithState
          state={relation}
          reload={() => getRelationData(searchTerm, searchTerm2)}
        >
          {relation.data !== undefined ? (
            <TermsRelation
            relation={relation.data}
            searchTerm={searchTerm}
            searchTerm2={searchTerm2}
            setContextOutside={setContextOutside}
          />
          ) : null}
        </ComponentWithState>
      </p>
    </>
  );
};

export default RelatedOverview;
