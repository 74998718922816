import React, { useState } from 'react';
import { useMutation } from 'react-query';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import Logo from 'img/deep_bitacora-logo@2x.png';

import { getAnswers } from '../../utils/searchCalls';
import CardSkeleton from './CardSkeleton';

import styles from './styles.module.scss';

const FormSelect = ({ options, value, onChange, label, id }) => (
  <FormControl variant="outlined" fullWidth>
    <InputLabel id={id}>{label}</InputLabel>
    <Select
      labelId={id}
      id={`${id}-select`}
      value={value}
      onChange={onChange}
      label={label}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const Searcher = () => {
  const [search, setSearch] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [filters, setFilters] = useState({});
  const answers = useMutation(() =>
    getAnswers({ question: search, size: 10, filters })
  );

  return (
    <div className={styles.container}>
      <img src="/img/background1.svg" alt="Mancha" className={styles.bg1} />
      <img src="/img/background2.svg" alt="Mancha" className={styles.bg2} />
      <img src="/img/background3.svg" alt="Mancha" className={styles.bg3} />
      <Container>
        <Grid container>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            item
            xs={12}
            className={styles.header}
          >
            <img src={Logo} alt="Deep Bitacora" className={styles.logo} />
            <div className={styles.buttons}>
              <a>Ayuda</a>
              <button type="button">Cerrar sesión</button>
            </div>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={styles.main}
          >
            <h2>¿Qué le quieres preguntar hoy a los mexicanos?</h2>
            <p>
              Realiza una pregunta abierta a nuestra base de datos de estudios
              antropológicos en México. Las respuestas que se muestran provienen
              de más de 644 investigaciones sociales levantadas por Bitácora
              Social a lo largo de 11 años.
            </p>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              className={styles.search}
            >
              <Grid item xs={8}>
                <input
                  id="search"
                  value={search}
                  onChange={({ target }) => setSearch(target.value)}
                  placeholder="Ej: ¿Por qué les gusta comer en restaurantes?"
                  className={styles.searchInput}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  type="button"
                  color="secondary"
                  onClick={() => setShowFilters(!showFilters)}
                  className={styles.filtersButton}
                  endIcon={<img src="/img/filter.svg" alt="Filtro" />}
                >
                  Filtros
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={() => {
                    answers.mutate();
                    setShowAnswers(true);
                  }}
                  className={styles.searchButton}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
            {showFilters ? (
              <Grid
                container
                direction="row"
                spacing={3}
                className={styles.filters}
              >
                <Grid item xs={12} md={12}>
                  <h3>Filtros</h3>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormSelect
                    id="semantic"
                    value={filters.semantic}
                    onChange={(e) =>
                      setFilters({ ...filters, semantic: e.target.value })
                    }
                    label="Semántica"
                    options={['Todos', 'México', 'Estados Unidos']}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormSelect
                    id="emotion"
                    value={filters.emotion}
                    onChange={(e) =>
                      setFilters({ ...filters, emotion: e.target.value })
                    }
                    label="Emoción"
                    options={['Todos', 'México', 'Estados Unidos']}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <FormSelect
                    id="city"
                    value={filters.city}
                    onChange={(e) =>
                      setFilters({ ...filters, city: e.target.value })
                    }
                    label="Ciudad"
                    options={['Todos', 'México', 'Estados Unidos']}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <FormSelect
                    id="state"
                    value={filters.state}
                    onChange={(e) =>
                      setFilters({ ...filters, state: e.target.value })
                    }
                    label="Estado"
                    options={['Todos', 'México', 'Estados Unidos']}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <FormSelect
                    id="nseyc"
                    value={filters.nseyc}
                    onChange={(e) =>
                      setFilters({ ...filters, nseyc: e.target.value })
                    }
                    label="NSEyC"
                    options={['Todos', 'México', 'Estados Unidos']}
                  />
                </Grid>
              </Grid>
            ) : (
              <div className={styles.tickets}>
                <p>Búsquedas sugeridas</p>
                <div className={styles.questions}>
                  {[
                    '¿Qué lo motiva a comprar ___?',
                    '¿Dónde compra _____?',
                    '¿Qué disfruta de la vida?',
                    '¿Cuándo compra _____?',
                    '¿Cuándo va a _____?',
                    '¿Cuál es su ____ favorito?',
                    '¿Por qué va a ____?',
                    '¿Por qué es su ___ favorito?',
                    '¿Por qué le gusta _____?',
                  ].map((option) => (
                    <button
                      key={option}
                      type="button"
                      onClick={() => setSearch(option)}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            )}
            {showAnswers && (
              <Grid container spacing={3} className={styles.answers}>
                <Grid item xs={12} sm={6}>
                  <h3>En resumen, esta es la respuesta a “{search}”</h3>
                  <Paper className={styles.paper}>
                    <CardSkeleton
                      type="summary"
                      loading={answers.isLoading}
                      error={answers.isError}
                      onReload={answers.mutate}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h3>Emotionmeter</h3>
                  <CardSkeleton
                    type="emotionMeter"
                    loading={answers.isLoading}
                    error={answers.isError}
                    onReload={answers.mutate}
                  />
                </Grid>
                <Grid item xs={12}>
                  <h3>
                    Estas son algunas verbalizaciones que responden a tu
                    pregunta:
                  </h3>
                  <CardSkeleton
                    type="passages"
                    loading={answers.isLoading}
                    error={answers.isError}
                    onReload={answers.mutate}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Searcher;
