import React, { useState, useEffect } from 'react';
import { Col, Image } from 'react-bootstrap';
import '../../styles/generic.css';
// import emotionData from '../../data/emotionmeter.json'

const Emotionmeter = ({ emotionData }) => {
  // Emotion Data
  const [alegria, setAlegria] = useState(
    `${(emotionData.alegria * 100).toFixed(2).toString()}%`
  );
  const [miedo, setMiedo] = useState(
    `${(emotionData.miedo * 100).toFixed(2).toString()}%`
  );
  const [enojo, setEnojo] = useState(
    `${(emotionData.enojo * 100).toFixed(2).toString()}%`
  );
  const [disgusto, setDisgusto] = useState(
    `${(emotionData.disgusto * 100).toFixed(2).toString()}%`
  );
  const [tristeza, setTristeza] = useState(
    `${(emotionData.tristeza * 100).toFixed(2).toString()}%`
  );

  const styleAlegria = {
    backgroundColor: '#7CB347',
    height: alegria,
  };
  const styleMiedo = {
    backgroundColor: '#DF7B36',
    height: miedo,
  };
  const styleEnojo = {
    backgroundColor: '#D33F37',
    height: enojo,
  };
  const styleDisgusto = {
    backgroundColor: '#766098',
    height: disgusto,
  };
  const styleTristeza = {
    backgroundColor: '#648BC8',
    height: tristeza,
  };

  return (
    <>
      <Col className="w20p d-flex p10">
        <div className="termometro">
          <Image
            src="./img/caritas_BS_Feliz.png"
            roundedCircle
            width="100px"
            style={{ zIndex: '10' }}
          />
          <div className="barra">
            <div style={styleAlegria} className="nivel" />
          </div>
        </div>
        <div className="p10 pt30">
          <p className="ftn11">Alegría</p>
          <p className="txt_bold ftn20"> {alegria} </p>
        </div>
      </Col>
      <Col className="w20p d-flex p10">
        <div className="termometro">
          <Image
            src="./img/caritas_BS_Miedo.png"
            roundedCircle
            width="100px"
            style={{ zIndex: '10' }}
          />
          <div className="barra">
            <div style={styleMiedo} className="nivel" />
          </div>
        </div>
        <div className="p10 pt30">
          <p className="ftn11">Miedo</p>
          <p className="txt_bold ftn20"> {miedo} </p>
        </div>
      </Col>
      <Col className="w20p d-flex p10">
        <div className="termometro">
          <Image
            src="./img/caritas_BS_Enojado.png"
            roundedCircle
            width="100px"
            style={{ zIndex: '10' }}
          />
          <div className="barra">
            <div style={styleEnojo} className="nivel" />
          </div>
        </div>
        <div className="p10 pt30">
          <p className="ftn11">Enojo</p>
          <p className="txt_bold ftn20"> {enojo} </p>
        </div>
      </Col>
      <Col className="w20p d-flex p10">
        <div className="termometro">
          <Image
            src="./img/caritas_BS_disgusto.png"
            roundedCircle
            width="100px"
            style={{ zIndex: '10' }}
          />
          <div className="barra">
            <div style={styleDisgusto} className="nivel" />
          </div>
        </div>
        <div className="p10 pt30">
          <p className="ftn11">Disgusto</p>
          <p className="txt_bold ftn20"> {disgusto} </p>
        </div>
      </Col>
      <Col className="w20p d-flex p10">
        <div className="termometro">
          <Image
            src="./img/caritas_BS_tristeza.png"
            roundedCircle
            width="100px"
            style={{ zIndex: '10' }}
          />
          <div className="barra">
            <div style={styleTristeza} className="nivel" />
          </div>
        </div>
        <div className="p10 pt30">
          <p className="ftn11">Tristeza</p>
          <p className="txt_bold ftn20"> {tristeza} </p>
        </div>
      </Col>
    </>
  );
};

export default Emotionmeter;
