import React, { useState, useEffect } from 'react';
// import topData from "../../data/top_10_terminos.json";
import { Row, Col, Carousel, Form, Table } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import parse from 'html-react-parser';
import clsx from 'clsx';

import ComponentWithState from 'components/ComponentWithState';
import ComponentQa from 'components/dashboard/componentQa';
import styles from 'styles/modules/newDashboard.module.scss';
import 'styles/generic.css';

const QaLong = ({ qaLong, searchTerm, getQaLongData, register, watch }) => {
  const [question, setQuestion] = useState('');

  const searchQuestion = () => {
    const { newQuestion } = watch();
    setQuestion(newQuestion);
    getQaLongData(newQuestion);
  };


  return (
    <>
      <p className="ftn13 pb10">
        Realiza una búsqueda relacionada con el término “{searchTerm}”
        <Row className="pt10 pb10">
          <Col md={4} sm={12} className="searchTerm d-flex">
            <Form.Control
              onKeyDown={({ key }) => key === 'Enter' && searchTermRelation()}
              id="newQuestion"
              className="obj-info-large"
              placeholder="Escuela"
              {...register('newQuestion')}
            />
          </Col>
          <Col>
            <button
              type="button"
              id="SearchButton2"
              className="SearchButton"
              onClick={searchQuestion}
            >
              Buscar
            </button>
          </Col>
        </Row>
        <ComponentWithState
          state={qaLong}
          reload={() => getQaLongData(question)}
        >
          {qaLong.data !== undefined ? (
            <ComponentQa
              qaLong={qaLong.data}
              searchTerm={searchTerm}
              question= {question}
            />
          ) : null}
        </ComponentWithState>
      </p>
    </>
  );
};

export default QaLong;
