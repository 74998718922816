import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Accordion,
  useAccordionToggle,
  Alert,
} from 'react-bootstrap';
import parse from 'html-react-parser';

import '../styles/carga.css';
import '../styles/generic.css';
import '../styles/validation.css';
import { validateOrgs } from '../utils/validationEntitiesCalls';

const EntitiesInfo = ({ orgInfo, entityValidated }) => {
  const accessToken = localStorage.getItem('accessToken');

  // State variables
  const [institution, setInstitution] = useState(orgInfo.org_type);
  const [category, setCategory] = useState(orgInfo.category);
  const [subcategory, setSubcategory] = useState(orgInfo.subcategory);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);

  const [subcategoryOpen, setSubcategoryOpen] = useState(false);

  const [warning, setWarning] = useState(false);

  const [buttonRedClass, setButtonRedClass] = useState({
    institucion: 'round-button btn-red',
    categoria: 'round-button btn-red',
    subcategoria: 'round-button btn-red',
  });
  const [buttonGreenClass, setButtonGreenClass] = useState({
    institucion: 'round-button btn-green',
    categoria: 'round-button btn-green',
    subcategoria: 'round-button btn-green',
  });
  const [labelClass, setLabelClass] = useState({
    institucion: 'obj-info',
    categoria: 'obj-info',
    subcategoria: 'obj-info',
  });
  const [validateButton, setValidateButton] = useState(
    'btn-blue validation-button-small ftn11 text-center'
  );

  const handleOriginal = (e) => {
    setLabelClass({
      ...labelClass,
      [e.target.name]: 'validated-box',
    });
    setButtonGreenClass({
      ...buttonGreenClass,
      [e.target.name]: 'round-button btn-gray',
    });
    setButtonRedClass({
      ...buttonRedClass,
      [e.target.name]: 'round-button btn-gray',
    });
    if (e.target.id === 'notOriginal') {
      // Sin el timeout no cambia el color de los botones
      setTimeout(() => {
        document
          .getElementById(`${orgInfo.id}-${e.target.name}-button-red`)
          .click();
      }, 10);
    }
  };

  function handleCategoriesOptions(institution) {
    institution.map((category) =>
      setCategoryOptions((categoryOptions) => [
        ...categoryOptions,
        category.category,
      ])
    );
  }

  function handleSubcategoriesOptions(institution, category) {
    console.log('handling subcategories...');
    institution.map((cat) => {
      if (cat.category === category) {
        console.log(cat.subcategories);
        setSubcategoryOptions(cat.subcategories);
        // Por si no le dan click
        if (subcategoryOpen === false) {
          setSubcategory(cat.subcategories[0]);
          setSubcategoryOpen(true);
        }
        // setSubcategory(cat.subcategories[0])
      }
    });
  }

  function CustomToggle({ type_key, children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      if (eventKey === '0') {
        setCategory();
        setSubcategory();
      } else if (eventKey === '1') {
        setSubcategory();
        setButtonGreenClass({
          ...buttonGreenClass,
          institucion: 'round-button btn-gray',
        });
        setButtonRedClass({
          ...buttonRedClass,
          institucion: 'round-button btn-gray',
        });
        // Si se abre el acordeon la lista esta vacía:
        if (categoryOptions.length === 0) {
          setCategory('Comercio');
        }
        // Reiniciamos la lista
        setCategoryOptions([]);
        if (institution === 'Pública') {
          handleCategoriesOptions(orgInfo.options['Pública']);
          console.log(categoryOptions);
        } else if (institution === 'Privada') {
          handleCategoriesOptions(orgInfo.options.Privada);
          console.log(categoryOptions);
        } else {
          setCategoryOptions(['No aplica']);
          setCategory('No aplica');
          setSubcategoryOptions(['No aplica']);
          setSubcategory('No aplica');
        }
      } else if (eventKey === '2') {
        console.log(subcategoryOptions, 'subcategories');
        setButtonGreenClass({
          ...buttonGreenClass,
          categoria: 'round-button btn-gray',
        });
        setButtonRedClass({
          ...buttonRedClass,
          categoria: 'round-button btn-gray',
        });
        if (subcategoryOptions.length === 0) {
          setSubcategoryOpen(true);
          console.log('subcategory open:', subcategoryOpen);
        }
        setSubcategoryOptions([]);
        if (category === 'Comercio') {
          // setSubcategory('Productos')
          handleSubcategoriesOptions(orgInfo.options.Privada, 'Comercio');
        } else if (category === 'Gobierno') {
          handleSubcategoriesOptions(orgInfo.options['Pública'], 'Gobierno');
        } else {
          setSubcategoryOptions(['No aplica']);
          setSubcategory('No aplica');
        }
      }
    });

    return (
      <button
        id={`${orgInfo.id}-${type_key}-button-red`}
        type="button"
        className={buttonRedClass[type_key]}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  function renderCategories() {
    const instCat = [];
    categoryOptions.map((category) => {
      instCat.push(
        <option value={category} key={category}>
          {category}
        </option>
      );
    });

    return instCat;
  }

  function renderSubCategories() {
    const instSubCat = [];
    subcategoryOptions.map((subcategory) => {
      instSubCat.push(
        <option value={subcategory} key={subcategory}>
          {subcategory}
        </option>
      );
    });

    return instSubCat;
  }

  const handleValidation = (e) => {
    console.log(institution, category, subcategory);
    if (institution === null || category === null || subcategory === null) {
      setWarning(true);
    } else {
      setWarning(false);
      setValidateButton('btn-gray validation-button-small ftn11 text-center');
      const validateEntity = async () => {
        const response = await validateOrgs(
          orgInfo.id,
          institution,
          category,
          subcategory,
          accessToken
        );
        if (response === 'OK') {
          console.log('Validated!');
          entityValidated(orgInfo.id);
        }
      };
      validateEntity();
    }
  };

  // Poner en negritas texto del extracto o de la definición
  function renderExtract() {
    return <p className="ftn13">{parse(orgInfo.extract)}</p>;
  }
  function renderDefinition() {
    return (
      <p className="ftn13">
        <span className="font-medium">Definición: </span>
        {parse(orgInfo.description)}{' '}
      </p>
    );
  }

  return (
    <Col id={orgInfo.id} md={12} sm={12} className="inline-label p0 pb35">
      <Form inline>
        <Col md={6} sm={12} className="p0">
          <Col className="paragraph-text back-gray p20 borderR-15">
            <div>
              <p className="ftn13 font-medium">Extracto</p>
              {renderExtract()}
            </div>
            <div className="pt10">
              <p className="ftn13 font-medium">Entidad: {orgInfo.entity}</p>
              {renderDefinition()}
            </div>
          </Col>
        </Col>

        <Col md={6} sm={12}>
          <p className="my-1 mr-10 inline-label ftn11">
            A continuación marca si la categoría es correcta o incorrecta:
          </p>
          <Accordion className="pb10">
            <Card>
              <Col md={12} sm={12} inline>
                <Form inline>
                  <Col md={6} sm={12}>
                    <Card.Header className="p0">
                      <p className="font-medium txt_left pb10 ftn11">
                        Tipo de Institución
                      </p>
                      <Row>
                        <Form.Group className="FilterContainer">
                          <div
                            id={`${orgInfo.id}-inst-og`}
                            className={labelClass.institucion}
                          >
                            {institution}
                          </div>
                          {/* <Form.Control id="SearchQuery" className="inputTerms"  placeholder="Pública" /> */}
                        </Form.Group>
                        <Form.Group className="inline-label pl10 moreButton pb10">
                          <button
                            name="institucion"
                            type="button"
                            className={buttonGreenClass.institucion}
                            onClick={handleOriginal}
                          >
                            <img
                              src="/img/icon-checkmark.svg"
                              className=""
                              width="100%"
                              name="institucion"
                            />
                          </button>
                          <CustomToggle
                            type_key="institucion"
                            className={buttonRedClass.institucion}
                            eventKey="0"
                          >
                            <img
                              src="/img/icon-cross.svg"
                              className=""
                              width="100%"
                            />
                          </CustomToggle>
                        </Form.Group>
                      </Row>
                    </Card.Header>
                  </Col>

                  <Col md={5} sm={4} className="pt10">
                    <Accordion.Collapse className="answer" eventKey="0">
                      <Card.Body className="p0">
                        <p className="font-medium txt_left pb10 ftn11">
                          Selecciona la opción correcta
                        </p>
                        <Form inline>
                          <Form.Group className="FilterContainer pr0">
                            <Form.Group
                              className="FilterContainer pr0"
                              name="institucion"
                              onChange={(e) => setInstitution(e.target.value)}
                            >
                              <Form.Check
                                id="orgType"
                                inline
                                label="Pública"
                                value="Pública"
                                type="radio"
                                name="institucion"
                              />
                              <Form.Check
                                id="orgType"
                                inline
                                label="Privada"
                                value="Privada"
                                type="radio"
                                name="institucion"
                              />
                              <Form.Check
                                id="orgType"
                                inline
                                label="Ninguna"
                                value="Ninguna"
                                type="radio"
                                name="institucion"
                              />
                              <Form.Check
                                id="orgType"
                                inline
                                label="No sé"
                                value="unknown"
                                type="radio"
                                name="institucion"
                              />
                            </Form.Group>
                          </Form.Group>
                        </Form>
                        <button
                          type="button"
                          id="notOriginal"
                          className="SearchButton ftn11"
                          onClick={handleOriginal}
                          name="institucion"
                        >
                          Enviar respuesta
                        </button>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Col>
                </Form>
              </Col>
            </Card>
          </Accordion>
          <Accordion className="pb10">
            <Card>
              <Col md={12} sm={12} inline>
                <Form inline>
                  <Col md={6} sm={12}>
                    <Card.Header className="p0">
                      <p className="font-medium txt_left pb10 ftn11">
                        Categoria
                      </p>
                      <Row>
                        <Form.Group className="FilterContainer">
                          <div
                            id={`${orgInfo.id}-inst-og`}
                            className={labelClass.categoria}
                          >
                            {category}
                          </div>
                          {/* <Form.Control id="SearchQuery" className="inputTerms"  placeholder="Comercial" /> */}
                        </Form.Group>
                        <Form.Group className="inline-label pl10 moreButton pb10">
                          <button
                            name="categoria"
                            type="button"
                            className={buttonGreenClass.categoria}
                            onClick={handleOriginal}
                          >
                            <img
                              name="categoria"
                              src="/img/icon-checkmark.svg"
                              className=""
                              width="100%"
                            />
                          </button>
                          <CustomToggle
                            type_key="categoria"
                            className={buttonRedClass.categoria}
                            eventKey="1"
                          >
                            <img
                              src="/img/icon-cross.svg"
                              className=""
                              width="100%"
                            />
                          </CustomToggle>
                        </Form.Group>
                      </Row>
                    </Card.Header>
                  </Col>

                  <Col md={6} sm={12} className="pt10">
                    <Accordion.Collapse eventKey="1" className="answer">
                      <Card.Body className="p0">
                        <p className="font-medium txt_left pb10 ftn11">
                          Selecciona la opción correcta
                        </p>
                        <Form inline>
                          <Form.Group className="FilterContainer pr0">
                            <Form.Group className="FilterContainer pr0">
                              <Form.Control
                                id="categoria"
                                name="categoria"
                                as="select"
                                className="my-1 mr-sm-2 selectProj pr0"
                                custom
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                {renderCategories()}
                              </Form.Control>
                            </Form.Group>
                          </Form.Group>
                        </Form>
                        <button
                          type="button"
                          id="notOriginal"
                          className="SearchButton ftn11"
                          onClick={handleOriginal}
                          name="categoria"
                        >
                          Enviar respuesta
                        </button>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Col>
                </Form>
              </Col>
            </Card>
          </Accordion>
          <Accordion className="pb10">
            <Card>
              <Col md={12} sm={12} inline>
                <Form inline>
                  <Col md={6} sm={12}>
                    <Card.Header className="p0">
                      <p className="font-medium txt_left pb10 ftn11">
                        Subcategoria
                      </p>
                      <Row>
                        <Form.Group className="FilterContainer">
                          <div
                            id={`${orgInfo.id}-inst-og`}
                            className={labelClass.subcategoria}
                          >
                            {subcategory}
                          </div>
                        </Form.Group>
                        <Form.Group className="inline-label pl10 moreButton pb10">
                          <button
                            name="subcategoria"
                            type="button"
                            className={buttonGreenClass.subcategoria}
                            onClick={handleOriginal}
                          >
                            <img
                              name="subcategoria"
                              src="/img/icon-checkmark.svg"
                              className=""
                              width="100%"
                            />
                          </button>
                          <CustomToggle
                            type_key="subcategoria"
                            className="btn-red"
                            eventKey="2"
                          >
                            <img
                              src="/img/icon-cross.svg"
                              className=""
                              width="100%"
                            />
                          </CustomToggle>
                        </Form.Group>
                      </Row>
                    </Card.Header>
                  </Col>

                  <Col md={6} sm={12} className="pt10">
                    <Accordion.Collapse eventKey="2" className="answer">
                      <Card.Body className="p0">
                        <p className="font-medium txt_left pb10 ftn11">
                          Selecciona la opción correcta
                        </p>
                        <Form inline>
                          <Form.Group className="FilterContainer pr0">
                            <Form.Control
                              id="subcategoria"
                              name="subcategoria"
                              as="select"
                              className="my-1 mr-sm-2 selectProj pr0"
                              custom
                              onChange={(e) => setSubcategory(e.target.value)}
                            >
                              {renderSubCategories()}
                            </Form.Control>
                          </Form.Group>
                        </Form>
                        <button
                          type="button"
                          id="notOriginal"
                          className="SearchButton ftn11"
                          onClick={handleOriginal}
                          name="subcategoria"
                        >
                          Enviar respuesta
                        </button>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Col>
                </Form>
              </Col>
            </Card>
          </Accordion>
          <Accordion className="pb10">
            <Col className="pt10">
              {warning ? (
                <Alert variant="warning" className={alert}>
                  Por favor valida todos los campos antes de enviar
                </Alert>
              ) : null}
              <button
                type="button"
                id="btnRegisterForm"
                className={validateButton}
                onClick={handleValidation}
                name="subcategoria"
              >
                Validar entidad
              </button>
            </Col>
          </Accordion>
        </Col>
      </Form>
    </Col>
  );
};

export default EntitiesInfo;
