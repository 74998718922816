import React, { useState, seEffect, Fragment, createRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Carousel,
  CardColumns,
  Card,
  Accordion,
  useAccordionToggle,
  Modal,
  Image,
} from "react-bootstrap";
import MainNav from "../components/ui/navbar";
import GeneralSelect from "../components/utils/generalSelect";
import parse from "html-react-parser";
import Dropzone from "react-dropzone";
import "../styles/carga.css";
import "../styles/busqueda.css";
import "../styles/generic.css";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const Busqueda = () => {
  const access_token = JSON.parse(localStorage.getItem("auth0"));
  const apiUrl = `${process.env.REACT_APP_BACKEND_API_HOST}/api/v2/search_api/`;
  if (!access_token) {
    window.location.href = "/";
  }

  // document.getElementById('Loader').style.display = 'none';
  const [searchData, setSearchData] = useState([]);
  const [onlyQuotes, setOnlyQuotes] = useState(false);
  const [searchDocuments, setSearchDocuments] = useState([]);
  const [currentDocProject, setCurrentDocProject] = useState("Todos");
  const [valueData, setValueData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [valueDocuments, setValueDocuments] = useState([]);
  const [pageDocs, setPageDocs] = useState([]);
  const [currentDocuments, setCurrentDocuments] = useState([]);
  const [showOops, setShowOOps] = useState(false);
  const [checked, setChecked] = useState(true);

  // const onlyQuotes = false;
  // const setOnlyQuotes = () => {
  //   return null;
  // };

  let filteredData = searchData;
  if (onlyQuotes !== false) {
    filteredData = searchData.filter((data) => {
      return data.is_quote === true;
    });
  } else {
    filteredData = searchData;
  }

  let documentProjects = [];
  if (searchDocuments.length > 0) {
    documentProjects = searchDocuments.map((doc) => {
      return doc.proyecto;
    });
    documentProjects = ["Todos", ...new Set(documentProjects)];
  }
  // filter search documents based on project
  let filteredDocuments = [];
  if (currentDocProject !== "Todos") {
    filteredDocuments = searchDocuments.filter((doc) => {
      return doc.proyecto === currentDocProject;
    });
  } else {
    filteredDocuments = searchDocuments;
  }

  // Limpiar el localstorage de busquedas pasadads
  /*
    localStorage.removeItem('num_pages_terms');
    localStorage.removeItem('current_page_terms');
    localStorage.removeItem('search_after_terms');
    localStorage.removeItem('total_results_terms');
    localStorage.removeItem('last_index_terms');
    localStorage.removeItem('num_pages_documents');
    localStorage.removeItem('current_page_documents');
    localStorage.removeItem('search_after_documents');
    localStorage.removeItem('total_results_documents');
    */

  const SearchButton = (e) => {
    // document.getElementById('Loader').style.display = 'block';
    // alert('click');
    // SearchQuery ProjectName CityName StateName nseOption
    if (e) {
      e.preventDefault();
    }

    document
      .getElementById("bg-principal")
      .classList.remove("bg-search-principal");
    document
      .getElementById("bg-shadow")
      .classList.remove("pb230", "pt230", "bg-shadow");

    // document.getElementById('showText').checked = true;
    // document.getElementById('showImage').checked = true;
    const imageResult = document.getElementsByClassName("imageResult");
    for (var i = 0; i < imageResult.length; i += 1) {
      imageResult[i].style.display = "inline-block";
    }
    const textResult = document.getElementsByClassName("textResult");
    for (var i = 0; i < textResult.length; i += 1) {
      textResult[i].style.display = "inline-block";
    }

    const SearchQuery = document.getElementById("SearchQuery").value;

    const projectObj = document.getElementById("ProjectName");
    let ProjectName = projectObj.options[projectObj.selectedIndex].text;
    if (ProjectName === "Todos") {
      ProjectName = "";
    }

    // const StateName = document.getElementById('StateName').value;
    const stateObj = document.getElementById("StateName");
    let StateName = stateObj.options[stateObj.selectedIndex].text;
    if (StateName === "Todos") {
      StateName = "";
    }

    // const StateName = document.getElementById('CityteName').value;
    const cityObj = document.getElementById("CityName");
    let CityName = cityObj.options[cityObj.selectedIndex].text;
    if (CityName === "Todos") {
      CityName = "";
    }

    // const StateName = '';

    // const nseOption = document.getElementById('nseOption').value;
    const nseObj = document.getElementById("nseOption");
    let nseOption = nseObj.options[nseObj.selectedIndex].text;
    if (nseOption === "Todos") {
      nseOption = "";
    }

    const searchType = document.querySelector(
      'input[name="SearchType"]:checked'
    ).value;
    const searchCoincidence = document.querySelector(
      'input[name="SearchCoincidence"]:checked'
    ).value;

    const access_token = JSON.parse(localStorage.getItem("auth0"));

    // console.log('SearchQuery ProjectName CityName StateName nseOption', SearchQuery, ProjectName, CityName, StateName, nseOption);

    async function TermsFunction() {
      console.log("fetching the terms");
      await fetch(
        `${apiUrl}search_term?term=${SearchQuery}&size=${20}&search_type=${searchType}&match_type=${searchCoincidence}&project=${ProjectName}&state=${StateName}&city=${CityName}&nse=${nseOption}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token.id_token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("terms data");
          console.log(data);
          setSearchData(data.data);
          setValueData(data.total_results);
          setPageData(data.num_pages);
          setCurrentData("0");
          // setSearchDocuments(data.documents);
          setShowOOps(true);
          localStorage.setItem("last_index_terms", data.last_index);
          localStorage.setItem("num_pages_terms", data.num_pages);
          localStorage.setItem("current_page_terms", "0");
          localStorage.setItem("search_after_terms", data.search_after);
          localStorage.setItem("total_results_terms", data.total_results);
          if (data.num_pages > 1) {
            localStorage.setItem("current_page_terms", "1");
            setCurrentData("1");
          }

          localStorage.setItem("prev_after_terms", data.search_after);
          localStorage.setItem("prev_last_terms", data.last_index);
        });
    }

    async function DocumentsFunction() {
      await fetch(
        `${apiUrl}search_documents?term=${SearchQuery}&size=${20}&search_type=${searchType}&match_type=${searchCoincidence}&project=${ProjectName}&state=${StateName}&city=${CityName}&nse=${nseOption}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token.id_token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("data documentos");
          console.log(data);
          setSearchDocuments(data.documents);
          setValueDocuments(data.total_documents);
          setPageDocs(data.num_pages);
          setCurrentDocuments("0");
          setShowOOps(true);
          // localStorage.setItem('last_index_terms', data.last_index);
          localStorage.setItem("num_pages_documents", data.num_pages);
          localStorage.setItem("current_page_documents", "0");
          localStorage.setItem("search_after_documents", data.search_after);
          localStorage.setItem("total_results_documents", data.total_documents);
          if (data.num_pages > 1) {
            localStorage.setItem("current_page_documents", "3");
            setCurrentDocuments("1");
          }
        });
    }

    TermsFunction();
    DocumentsFunction();
  };

  const pageTerms = (direction, currentData) => {
    // document.getElementById('Loader').style.display = 'block';
    // alert('click');
    // SearchQuery ProjectName CityName StateName nseOption

    // document.getElementById('showText').checked = true;
    // document.getElementById('showImage').checked = true;
    const imageResult = document.getElementsByClassName("imageResult");
    for (var i = 0; i < imageResult.length; i += 1) {
      imageResult[i].style.display = "inline-block";
    }
    const textResult = document.getElementsByClassName("textResult");
    for (var i = 0; i < textResult.length; i += 1) {
      textResult[i].style.display = "inline-block";
    }

    const SearchQuery = document.getElementById("SearchQuery").value;

    const projectObj = document.getElementById("ProjectName");
    let ProjectName = projectObj.options[projectObj.selectedIndex].text;
    if (ProjectName === "Todos") {
      ProjectName = "";
    }

    // const StateName = document.getElementById('StateName').value;
    const stateObj = document.getElementById("StateName");
    let StateName = stateObj.options[stateObj.selectedIndex].text;
    if (StateName === "Todos") {
      StateName = "";
    }
    // const CityName = document.getElementById('StateName').value;
    const cityObj = document.getElementById("CityName");
    let CityName = stateObj.options[cityObj.selectedIndex].text;
    if (CityName === "Todos") {
      CityName = "";
    }
    // const StateName = '';

    // const nseOption = document.getElementById('nseOption').value;
    const nseObj = document.getElementById("nseOption");
    let nseOption = nseObj.options[nseObj.selectedIndex].text;
    if (nseOption === "Todos") {
      nseOption = "";
    }

    const searchType = document.querySelector(
      'input[name="SearchType"]:checked'
    ).value;
    const searchCoincidence = document.querySelector(
      'input[name="SearchCoincidence"]:checked'
    ).value;

    const access_token = JSON.parse(localStorage.getItem("auth0"));

    const lastIndex = localStorage.getItem("last_index_terms");
    const searchAfter = localStorage.getItem("search_after_terms");

    // console.log('currentData: ',currentData);

    switch (direction) {
      case "NEXT":
        const cp = currentData;
        setCurrentData(cp * 1 + 1);

        // Seteamos los parametros de la busqueda anterior antes de sobre escribirlos con la nueva
        const oldIndex = localStorage.getItem("last_index_terms");
        const oldAfter = localStorage.getItem("search_after_terms");
        console.log("El actual es: ", oldIndex, oldAfter);
        localStorage.setItem("prev_after_terms", oldAfter);
        localStorage.setItem("prev_last_terms", oldIndex);
        async function nextPage() {
          await fetch(
            `${apiUrl}scroll_terms?term=${SearchQuery}&size=${20}&last_index=${lastIndex}&search_after=${searchAfter}&search_type=${searchType}&match_type=${searchCoincidence}&project=${ProjectName}&state=${StateName}&nse=${nseOption}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${access_token.id_token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "",
                "Access-Control-Allow-Headers":
                  "Origin, X-Requested-With, Content-Type, Accept",
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setSearchData(data.data);
              setShowOOps(true);
              localStorage.setItem("last_index_terms", data.last_index);
              localStorage.setItem("search_after_terms", data.search_after);
              console.log(
                "El nuevo es es: ",
                localStorage.getItem("last_index_terms"),
                localStorage.getItem("search_after_terms")
              );
            });
        }
        nextPage();
        break;
      case "PREV":
        if (currentData * 1 == 2) {
          SearchButton();
        } else {
          const cp = currentData;
          setCurrentData(cp * 1 - 1);

          const oldIndex = localStorage.getItem("prev_last_terms");
          const oldAfter = localStorage.getItem("prev_after_terms");
          console.log("el anterior es: ", oldIndex, oldAfter);
          async function prevPage() {
            await fetch(
              `${apiUrl}scroll_terms?term=${SearchQuery}&size=${20}&last_index=${oldIndex}&search_after=${oldAfter}&search_type=${searchType}&match_type=${searchCoincidence}&project=${ProjectName}&state=${StateName}&nse=${nseOption}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${access_token.id_token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "",
                  "Access-Control-Allow-Headers":
                    "Origin, X-Requested-With, Content-Type, Accept",
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setSearchData(data.data);
                setShowOOps(true);
                localStorage.setItem("last_index_terms", data.last_index);
                localStorage.setItem("search_after_terms", data.search_after);
                console.log(
                  "El nuevo es es: ",
                  localStorage.getItem("last_index_terms"),
                  localStorage.getItem("search_after_terms")
                );
              });
          }
          prevPage();
        }
        break;
    }
  };

  const pageDocuments = (direction, currentDocuments) => {
    // document.getElementById('Loader').style.display = 'block';
    // alert('click');
    // SearchQuery ProjectName CityName StateName nseOption

    // document.getElementById('showText').checked = true;
    // document.getElementById('showImage').checked = true;
    const imageResult = document.getElementsByClassName("imageResult");
    for (var i = 0; i < imageResult.length; i += 1) {
      imageResult[i].style.display = "inline-block";
    }
    const textResult = document.getElementsByClassName("textResult");
    for (var i = 0; i < textResult.length; i += 1) {
      textResult[i].style.display = "inline-block";
    }

    const SearchQuery = document.getElementById("SearchQuery").value;

    const projectObj = document.getElementById("ProjectName");
    let ProjectName = projectObj.options[projectObj.selectedIndex].text;
    if (ProjectName === "Todos") {
      ProjectName = "";
    }

    // const StateName = document.getElementById('StateName').value;
    const stateObj = document.getElementById("StateName");
    let StateName = stateObj.options[stateObj.selectedIndex].text;
    if (StateName === "Todos") {
      StateName = "";
    }
    // const StateName = '';

    // const nseOption = document.getElementById('nseOption').value;
    const nseObj = document.getElementById("nseOption");
    let nseOption = nseObj.options[nseObj.selectedIndex].text;
    if (nseOption === "Todos") {
      nseOption = "";
    }

    const searchType = document.querySelector(
      'input[name="SearchType"]:checked'
    ).value;
    const searchCoincidence = document.querySelector(
      'input[name="SearchCoincidence"]:checked'
    ).value;

    const access_token = JSON.parse(localStorage.getItem("auth0"));

    const lastIndex = localStorage.getItem("last_index_terms");
    const searchAfter = localStorage.getItem("search_after_terms");

    // console.log('currentData: ',currentData);

    switch (direction) {
      case "NEXT":
        const cp = currentDocuments;
        setCurrentDocuments(cp * 1 + 1);

        // Seteamos los parametros de la busqueda anterior antes de sobre escribirlos con la nueva
        const oldIndex = localStorage.getItem("last_index_docs");
        const oldAfter = localStorage.getItem("search_after_docs");
        localStorage.setItem("prev_after_docs", oldAfter);
        localStorage.setItem("prev_last_docs", oldIndex);
        async function nextPage() {
          await fetch(
            `${apiUrl}scroll_documents?term=${SearchQuery}&size=${20}&search_after=${searchAfter}&search_type=${searchType}&match_type=${searchCoincidence}&project=${ProjectName}&state=${StateName}&nse=${nseOption}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${access_token.id_token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "",
                "Access-Control-Allow-Headers":
                  "Origin, X-Requested-With, Content-Type, Accept",
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setSearchData(data.documents);
              setShowOOps(true);
              localStorage.setItem("last_index_docs", data.last_index);
              localStorage.setItem("search_after_docs", data.search_after);
            });
        }
        nextPage();
        break;
      case "PREV":
        if (currentDocuments * 1 == 2) {
          SearchButton();
        } else {
          const cp = currentDocuments;
          setCurrentData(cp * 1 - 1);

          const oldIndex = localStorage.getItem("prev_last_docs");
          const oldAfter = localStorage.getItem("prev_after_docs");
          console.log("el anterior es: ", oldIndex, oldAfter);
          async function prevPage() {
            await fetch(
              `${apiUrl}scroll_terms?term=${SearchQuery}&size=${20}&search_after=${oldAfter}&search_type=${searchType}&match_type=${searchCoincidence}&project=${ProjectName}&state=${StateName}&nse=${nseOption}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${access_token.id_token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "",
                  "Access-Control-Allow-Headers":
                    "Origin, X-Requested-With, Content-Type, Accept",
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setSearchData(data.documents);
                setShowOOps(true);
                localStorage.setItem("last_index_docs", data.last_index);
                localStorage.setItem("search_after_docs", data.search_after);
              });
          }
          prevPage();
        }
        break;
    }
  };

  const downloadExtract = async (slideId, fileName) => {
    // console.log('slide_id, file_name', slide_id, file_name);
    const parsedSlideId = encodeURIComponent(slideId);
    const parsedFileName = encodeURIComponent(fileName);

    const access_token = JSON.parse(localStorage.getItem("auth0"));
    console.log(access_token.id_token);
    console.log("parsedSlideId, parsedFileName", parsedSlideId, parsedFileName);
    async function downloadExtractFunction() {
      await fetch(
        `https://dl6t5ecf86.execute-api.us-west-2.amazonaws.com/api/v2/search_api/download_extract_csv?slide_id=${parsedSlideId}&file_name=${parsedFileName}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token.id_token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log('data',data);
          window.open(data.url);
        });
    }
    downloadExtractFunction();
  };

  const downloadDocument = (s3Url) => {
    const access_token = JSON.parse(localStorage.getItem("auth0"));
    // Replace weird characters in URL
    const parsedS3Url = encodeURIComponent(s3Url);

    async function downloadDocumentFunction() {
      await fetch(
        `https://dl6t5ecf86.execute-api.us-west-2.amazonaws.com/api/v2/search_api/download_from_url?s3_url=${parsedS3Url}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token.id_token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          window.open(data.url);
        });
    }
    downloadDocumentFunction();
  };

  const downloadZip = (keyword, s3Keys) => {
    const accessToken = JSON.parse(localStorage.getItem("auth0"));
    // Replace weird characters in URL
    const parsedKeyword = encodeURIComponent(keyword);

    async function downloadZipFile() {
      await fetch(
        `https://dl6t5ecf86.execute-api.us-west-2.amazonaws.com/api/v2/search_api//download_zipfile?keyword=${parsedKeyword}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken.id_token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
          body: JSON.stringify({ s3_keys: [s3Keys] }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          window.open(data.url);
        });
    }
    downloadZipFile();
  };

  const downloadCSV = () => {
    const SearchQuery = document.getElementById("SearchQuery").value;
    const access_token = JSON.parse(localStorage.getItem("auth0"));
    async function downloadCSV() {
      await fetch(`${apiUrl}download_full_csv?term=${SearchQuery}&size=${10}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token.id_token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log('data',data);
          window.open(data.url);
        });
    }
    downloadCSV();
  };

  function ActivateBtn(e) {
    // console.log(e.target.value);
    if (e.target.value.length >= 3) {
      document.getElementById("SearchButton").disabled = false;
    } else {
      document.getElementById("SearchButton").disabled = true;
    }
  }
  function detectEnter(e) {
    e.preventDefault();
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      SearchButton(e);
    }
  }
  function toggleImage(e) {
    // console.log(e.target.checked);
    if (e.target.checked) {
      var elems = document.getElementsByClassName("imageResult");
      for (var i = 0; i < elems.length; i += 1) {
        elems[i].style.display = "inline-block";
      }
    } else {
      var elems = document.getElementsByClassName("imageResult");
      for (var i = 0; i < elems.length; i += 1) {
        elems[i].style.display = "none";
      }
    }
  }
  function toggleText(e) {
    // console.log(e.target.checked);
    if (e.target.checked) {
      var elems = document.getElementsByClassName("textResult");
      for (var i = 0; i < elems.length; i += 1) {
        elems[i].style.display = "inline-block";
      }
    } else {
      var elems = document.getElementsByClassName("textResult");
      for (var i = 0; i < elems.length; i += 1) {
        elems[i].style.display = "none";
      }
    }
  }

  function toggleTerm(e) {
    // console.log(e);
  }

  const directionButtons = (direction) => {
    return (
      <span
        aria-hidden="true"
        className={direction === "Next" ? "button-next" : "button-prev"}
      >
        <img src="/img/navcar.svg" className="iconArrowCarousel" />
      </span>
    );
  };

  const renderSearchData = () => {
    if (searchData.length > 0) {
      return filteredData.map(function (item, i) {
        const p = document.getElementById("SearchQuery").value;
        let newText = "";
        const parser = new DOMParser();
        newText = item.text.replace(new RegExp(p, "g"), `<b>${p}</b>`);
        if (item.images) {
        }
        if (item.images.length >= 1) {
          return (
            <Card className="busqueda-itemContainer imageResult" key={i}>
              <Row className="busqueda-itemResult">
                <div>
                  <Col
                    md={12}
                    sm={12}
                    className="text-right busqueda-itemName font-regular"
                  >
                    {item.created_date}
                  </Col>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Archivo:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      {item.file_name}
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Proyecto:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      {item.proyecto}
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">NSEyC:</div>
                    <div className="busqueda-itemDesc font-regular">
                      {item.nse}
                    </div>
                  </div>
                  <div className="busqueda-itemCont text-left font-regular">
                    {parse(newText)}
                  </div>
                  <div className="text-left tagCointainer">
                    <div className="busqueda-itemName font-medium">
                      Palabra encontrada:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      <ul className="busueda-wordTag">
                        <li className="font-medium">{p}</li>
                        {/* <li className="font-medium">Danone</li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="icon-container">
                      <button
                        type="button"
                        onClick={() => {
                          console.log(item.file_name);
                          console.log("this is the item");
                          console.log(item);
                          downloadDocument(item.s3_url);
                        }}
                      >
                        <img
                          src="/img/download_blue.svg"
                          className="img-icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="CarouselContainer">
                  <Carousel
                    interval={null}
                    nextIcon={directionButtons("Next")}
                    prevIcon={directionButtons("Previous")}
                  >
                    {item.images.map(function (itemx, x) {
                      return (
                        <Carousel.Item>
                          <img
                            src={itemx}
                            className="img-fluid img-carousel"
                            key={x}
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
              </Row>
            </Card>
          );
        }
        return (
          <Card className="busqueda-itemContainer textResult" key={i}>
            <div className="busqueda-itemResult">
              <Col
                md={12}
                sm={12}
                className="text-right busqueda-itemName font-regular"
              >
                {item.created_date}
              </Col>
              <div className="text-left">
                <div className="busqueda-itemName font-medium">Archivo:</div>
                <div className="busqueda-itemDesc font-regular">
                  {item.file_name}
                </div>
              </div>
              <div className="text-left">
                <div className="busqueda-itemName font-medium">Proyecto:</div>
                <div className="busqueda-itemDesc font-regular">
                  {item.proyecto}
                </div>
              </div>
              <div className="text-left">
                <div className="busqueda-itemName font-medium">NSEyC:</div>
                <div className="busqueda-itemDesc font-regular">{item.nse}</div>
              </div>
              <div className="busqueda-itemCont text-left font-regular">
                {parse(newText)}
              </div>
              <div className="text-left tagCointainer">
                <div className="busqueda-itemName font-medium">
                  Palabra encontrada:
                </div>
                <div className="busqueda-itemDesc font-regular">
                  <ul className="busueda-wordTag">
                    <li className="font-medium">{p}</li>
                    {/* <li className="font-medium">Danone</li> */}
                  </ul>
                </div>
              </div>
              <div className="text-right">
                <div className="icon-container">
                  <button
                    type="button"
                    onClick={() => {
                      console.log("this is item");
                      console.log(item);
                      downloadDocument(item.s3_url);
                    }}
                  >
                    <img src="/img/download_blue.svg" className="img-icon" />
                  </button>
                </div>
              </div>
            </div>
          </Card>
        );
      });
    }
    // else{
    // return(
    {
      /* <div className="busqueda-title font-medium text-center">Sin resultados de busqueda</div> */
    }
    // )
    // }
  };

  const renderSearchDocuments = () => {
    if (searchDocuments.length > 0) {
      return (
        <Col md={12} sm={12} className="TableContainer">
          <h4 className="busqueda-title font-medium">
            Listado de archivos donde fue encontrado
          </h4>
          <GeneralSelect
            options={documentProjects}
            setSelected={setCurrentDocProject}
          />
          <h4>{currentDocProject}</h4>
          <Col md={12} sm={12} className="table-container">
            <Table
              bordered
              hover
              responsive
              className="table-base busqueda-table"
            >
              <thead>
                <tr>
                  <th className="font-medium text-left table-head-item">
                    Nombre
                  </th>
                  <th className="font-medium text-left table-head-item">
                    Proyecto
                  </th>
                  <th className="font-medium text-left table-head-item">
                    Cliente
                  </th>
                  <th className="font-medium text-left table-head-item">
                    NSEyC
                  </th>
                  <th className="font-medium text-left table-head-item">
                    Fecha
                  </th>
                  <th className="font-medium text-left table-head-item" />
                </tr>
              </thead>
              <tbody>
                {filteredDocuments.map(function (item, i) {
                  // console.log(item);
                  return (
                    <tr className="table-row-item">
                      <td className="cell-item">{item.file_name}</td>
                      <td className="cell-item">{item.proyecto}</td>
                      <td className="cell-item">{item.cliente}</td>
                      <td className="cell-item">{item.nse}</td>
                      <td className="cell-item">{item.created_date}</td>
                      <td className="cell-item">
                        <button
                          type="button"
                          className="galletita"
                          onClick={() => downloadDocument(item.s3_url)}
                        >
                          <img
                            src="/img/download_blue.svg"
                            className="img-icon"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>

          {renderPaginateDocuments(valueDocuments, pageDocs, currentDocuments)}
        </Col>
      );
    }
    // else{
    // return(
    {
      /* <div className="busqueda-title font-medium text-center">Sin resultados de busqueda</div> */
    }
    // )
    // }
  };

  const renderStringResult = () => {
    if (searchData.length == 0 && searchDocuments.length == 0 && showOops) {
      // alert('ambos vacios');
      return (
        <Col md={12} sm={12} className="noresultContariner text-center">
          <h4 className="title text-center">Ups… </h4>
          <h4 className="subtitle text-center">
            No encontramos datos con estas características :(
          </h4>
        </Col>
      );
    }

    return (
      <Col md={12} sm={12} className="ResultTextContainer">
        {showOops ? (
          <Row>
            <h4 className="busqueda-title font-medium exportCSV pt25">
              Resultados: Encontrado en {valueData} párrafos y en{" "}
              {valueDocuments} archivos, de 10,263 Documentos
            </h4>
            {/* <span className="exportCSV pt25">
              <a  target="_blank">
                <img src="/img/download_black.svg" className="img-icon" />
              </a>
            </span> 
            */}
            <CardColumns className="searchWindowContainer">
              <div>
                <Form.Check
                  id="showImage"
                  inline
                  label="Solo mostrar citas"
                  type="checkbox"
                  defaultChecked={onlyQuotes}
                  onChange={() => setOnlyQuotes((current) => !current)}
                />
              </div>
            </CardColumns>

            <CardColumns className="searchWindowContainer">
              {renderSearchData()}
            </CardColumns>
            {renderPaginateTerms(valueData, pageData, currentData)}
          </Row>
        ) : null}
      </Col>
    );
  };

  const renderPaginateTerms = (valueData, pageData, currentData) => {
    // Si el total de paginas es mayor a 1, mostramos paginador, si no, solo el total
    const CurrentPage = localStorage.getItem("current_page_terms");
    if (pageData > 1) {
      return (
        <Col md={12} sm={12}>
          <Row>
            <Col md={6} sm={12} className="text-left">
              {(() => {
                if (currentData >= 2) {
                  return (
                    <a
                      onClick={() => {
                        pageTerms("PREV", currentData);
                      }}
                    >
                      <img src="/img/prev.svg" className="iconPagePrev" />
                    </a>
                  );
                }
              })()}

              {currentData}

              {(() => {
                if (currentData < pageData) {
                  return (
                    <a
                      onClick={() => {
                        pageTerms("NEXT", currentData);
                      }}
                    >
                      <img src="/img/next.svg" className="iconPageNext" />
                    </a>
                  );
                }
              })()}
            </Col>
            <Col md={6} sm={12} className="text-right">
              Páginas totales: {pageData}
            </Col>
          </Row>
        </Col>
      );
    }
    if (showOops) {
      return (
        <Col md={12} sm={12}>
          <Row>
            <Col md={12} sm={12}>
              Páginas totales: {pageData}
            </Col>
          </Row>
        </Col>
      );
    }
  };

  const renderPaginateDocuments = (valueData, pageData, currentData) => {
    // Si el total de paginas es mayor a 1, mostramos paginador, si no, solo el total
    if (pageData > 1) {
      return (
        <Col md={12} sm={12}>
          <Row>
            <Col md={6} sm={12} className="text-left">
              {(() => {
                if (currentData >= 2) {
                  return (
                    <a
                      onClick={() => {
                        pageDocuments("PREV", currentData);
                      }}
                    >
                      <img src="/img/next.svg" className="iconPagePrev" />
                    </a>
                  );
                }
              })()}

              {currentData}

              {(() => {
                if (currentData < pageData) {
                  return (
                    <a
                      onClick={() => {
                        pageDocuments("NEXT", currentData);
                      }}
                    >
                      <img src="/img/next.svg" className="iconPageNext" />
                    </a>
                  );
                }
              })()}
            </Col>
            <Col md={6} sm={12} className="text-right">
              Páginas totales: {pageData}
            </Col>
          </Row>
        </Col>
      );
    }
    if (showOops) {
      return (
        <Col md={12} sm={12}>
          <Row>
            <Col md={12} sm={12}>
              Páginas totales: {pageData}
            </Col>
          </Row>
        </Col>
      );
    }
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
      console.log("totally custom!")
    );

    return (
      <button
        type="button"
        className="filterButton d-flex"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  function DateAndTimePickers() {
    return (
      <TextField
        id="date"
        type="date"
        defaultValue="2021-04-05"
        className="TextField"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  return (
    <Container fluid>
      <Row>
        <MainNav />
        <Col className="p0 container-fluid" md={12} sm={12}>
          <Col
            id="bg-principal"
            className="p0 bg-search-principal"
            md={12}
            sm={12}
          >
            <Col id="bg-shadow" className="bg-shadow pb230 pt230">
              <Col md={12} sm={12} className="p0">
                <Form inline>
                  <Col md={12} sm={12} className="p0">
                    <Accordion>
                      <Card>
                        <Card.Header className="form-inline">
                          <Col md={12} sm={12}>
                            <h4
                              className="busqueda-title font-medium txt_random txt_left"
                              id="searchTitle"
                            >
                              Búsqueda
                            </h4>
                          </Col>
                          <Col md={4} sm={12} className="searchTerm d-flex">
                            <Form.Control
                              id="SearchQuery"
                              className="inputTerms"
                              onChange={ActivateBtn}
                              onKeyUp={detectEnter}
                              placeholder="Término de búsqueda"
                            />
                            <CustomToggle eventKey="0">
                              Filtros
                              <img
                                id="filterBtn"
                                src="/img/icon_filter.svg"
                                className="img-icon"
                              />
                            </CustomToggle>
                          </Col>

                          <Col md={7} sm={12} className="form-inline">
                            <Form.Group className="FilterContainer">
                              <Form.Label className="lblchkbx">
                                Tipo de búsqueda:{" "}
                              </Form.Label>
                              <Form.Check
                                id="showTerm"
                                inline
                                label="Término"
                                value="term"
                                type="radio"
                                name="SearchType"
                                defaultChecked={checked}
                                onChange={toggleTerm}
                              />
                              <Form.Check
                                id="showPhrase"
                                inline
                                label="Frase"
                                value="phrase"
                                type="radio"
                                name="SearchType"
                                defaultChecked={checked}
                                onChange={toggleTerm}
                              />
                            </Form.Group>

                            <Form.Group className="FilterContainer">
                              <Form.Label className="lblchkbx">
                                Coincidencia:{" "}
                              </Form.Label>
                              <Form.Check
                                id="showExact"
                                inline
                                label="Exacta"
                                value="exact"
                                type="radio"
                                name="SearchCoincidence"
                                defaultChecked={checked}
                              />
                              <Form.Check
                                id="showGeneral"
                                inline
                                label="General"
                                value="general"
                                type="radio"
                                name="SearchCoincidence"
                                defaultChecked={checked}
                              />
                            </Form.Group>

                            <Form.Group className="FilterContainer">
                              <Form.Label className="lblchkbx">
                                Formato:{" "}
                              </Form.Label>
                              <Form.Check
                                id="showText"
                                inline
                                label="Texto"
                                type="checkbox"
                                defaultChecked={checked}
                                onChange={toggleText}
                              />
                              <Form.Check
                                id="showImage"
                                inline
                                label="Imagen"
                                type="checkbox"
                                defaultChecked={checked}
                                onChange={toggleImage}
                              />
                            </Form.Group>
                          </Col>

                          <Col
                            md={1}
                            sm={12}
                            className="form-inline justCont-flexE"
                          >
                            <Form.Group>
                              <button
                                id="SearchButton"
                                className="SearchButton"
                                onClick={SearchButton}
                              >
                                Buscar
                              </button>
                            </Form.Group>
                          </Col>
                        </Card.Header>
                        <Accordion.Collapse
                          eventKey="0"
                          className="container-fluid"
                        >
                          <Card.Body>
                            <Col className="back_filter">
                              <Col md={12} sm={12} className="p0">
                                <p className="filter-title font-medium txt_random txt_left">
                                  Filtros
                                </p>
                              </Col>
                              <Form.Label
                                className="my-1 mr-2 inline-label"
                                htmlFor="inlineFormCustomSelectPref"
                              >
                                Proyecto
                              </Form.Label>
                              <Form.Control
                                id="ProjectName"
                                as="select"
                                className="my-1 mr-sm-2 selectProj"
                                custom
                              >
                                <option value="">Todos</option>
                                <option value="">Campo Nescaf&eacute;</option>
                                <option value="">
                                  Proyecto econom&iacute;a familiar demanda
                                </option>
                                <option value="">
                                  Sazonadores Nestl&eacute;
                                </option>
                                <option value="">Familias progresistas</option>
                                <option value="">Desayunos</option>
                                <option value="">Campo yogurt</option>
                                <option value="">
                                  Econom&iacute;a Familiar
                                </option>
                                <option value="">Seguimiento changarros</option>
                                <option value="">
                                  Oxxo comer fuera de casa
                                </option>
                                <option value="">
                                  Lo que entra en casa de las familias de clase
                                  media mexicanas
                                </option>
                                <option value="">Estilo de vida NIVELES</option>
                                <option value="">
                                  Campo limpio Mabe M&eacute;xico
                                </option>
                                <option value="">Campo leche</option>
                                <option value="">Campo vivienda</option>
                                <option value="">ADO</option>
                              </Form.Control>

                              <Form.Label
                                className="my-1 mr-2 inline-label"
                                htmlFor="inlineFormCustomSelectPref"
                              >
                                Estado
                              </Form.Label>
                              <Form.Control
                                id="StateName"
                                as="select"
                                className="my-1 mr-sm-2"
                                custom
                              >
                                <option>Todos</option>
                                <option value="DIF">
                                  Ciudad de M&eacute;xico
                                </option>
                                <option value="AGS">Aguascalientes</option>
                                <option value="BCN">Baja California</option>
                                <option value="BCS">Baja California Sur</option>
                                <option value="CAM">Campeche</option>
                                <option value="CHP">Chiapas</option>
                                <option value="CHI">Chihuahua</option>
                                <option value="COA">Coahuila</option>
                                <option value="COL">Colima</option>
                                <option value="DUR">Durango</option>
                                <option value="GTO">Guanajuato</option>
                                <option value="GRO">Guerrero</option>
                                <option value="HGO">Hidalgo</option>
                                <option value="JAL">Jalisco</option>
                                <option value="MEX">M&eacute;xico</option>
                                <option value="MIC">Michoac&aacute;n</option>
                                <option value="MOR">Morelos</option>
                                <option value="NAY">Nayarit</option>
                                <option value="NLE">Nuevo Le&oacute;n</option>
                                <option value="OAX">Oaxaca</option>
                                <option value="PUE">Puebla</option>
                                <option value="QRO">Quer&eacute;taro</option>
                                <option value="ROO">Quintana Roo</option>
                                <option value="SLP">
                                  San Luis Potos&iacute;
                                </option>
                                <option value="SIN">Sinaloa</option>
                                <option value="SON">Sonora</option>
                                <option value="TAB">Tabasco</option>
                                <option value="TAM">Tamaulipas</option>
                                <option value="TLX">Tlaxcala</option>
                                <option value="VER">Veracruz</option>
                                <option value="YUC">Yucat&aacute;n</option>
                                <option value="ZAC">Zacatecas</option>
                              </Form.Control>

                              <Form.Label
                                className="my-1 mr-2 inline-label"
                                htmlFor="inlineFormCustomSelectPref"
                              >
                                Ciudad
                              </Form.Label>
                              <Form.Control
                                id="CityName"
                                as="select"
                                className="my-1 mr-sm-2"
                                custom
                              >
                                <option>Todos</option>
                                <option value="DIF">
                                  Ciudad de M&eacute;xico
                                </option>
                              </Form.Control>

                              <Form.Label
                                className="my-1 mr-2 inline-label"
                                htmlFor="inlineFormCustomSelectPref"
                              >
                                Fecha
                              </Form.Label>
                              <Form.Control
                                id="DateName"
                                as="textfield"
                                className="my-1 mr-sm-2"
                                custom
                              >
                                <DateAndTimePickers />
                              </Form.Control>

                              <Form.Label
                                className="my-1 mr-2 inline-label"
                                htmlFor="inlineFormCustomSelectPref"
                              >
                                NSEyC
                              </Form.Label>
                              <Form.Control
                                id="nseOption"
                                as="select"
                                className="my-1 mr-sm-2"
                                custom
                              >
                                <option value="">Todos</option>
                                <option value="">Alto</option>
                                <option value="">Medio alto</option>
                                <option value="">Medio t&iacute;pico</option>
                                <option value="">Medio bajo</option>
                                <option value="">Bajo</option>
                              </Form.Control>
                            </Col>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Col>
                </Form>
              </Col>
            </Col>
          </Col>
        </Col>

        <Container>
          {renderStringResult()}
          <Row>
            {/*
                            NO BORRAR ESTA TABLA/CODIGO ABAJO
                            SE QUITA TEMPORALMENTE, PERO SERÁ UTILIZADA
                        <Col md={12} sm={12} className="TableContainer" >
                            <h4 className="busqueda-title font-medium">Listado de proyectos donde fue encontrado</h4>
                            <Col md={12} sm={12} className="table-container">
                                <Table bordered hover responsive className="table-base busqueda-table">
                                    <thead>
                                        <tr>
                                            <th className="font-medium text-left table-head-item">Nombre</th>
                                            <th className="font-medium text-left table-head-item">Cliente</th>
                                            <th className="font-medium text-left table-head-item">Area que lo solicitó</th>
                                            <th className="font-medium text-left table-head-item">Temáticas</th>
                                            <th className="font-medium text-left table-head-item">Fecha</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="table-row-item">
                                            <td className="cell-item">Lácteos Lala</td>
                                            <td className="cell-item">Lala</td>
                                            <td className="cell-item">Mercadotecnia</td>
                                            <td className="cell-item">Alimentos</td>
                                            <td className="cell-item">01/01/2021</td>
                                        </tr>
                                        <tr className="table-row-item">
                                            <td className="cell-item">Lácteos Lala</td>
                                            <td className="cell-item">Lala</td>
                                            <td className="cell-item">Mercadotecnia</td>
                                            <td className="cell-item">Alimentos</td>
                                            <td className="cell-item">01/01/2021</td>
                                        </tr>
                                        <tr className="table-row-item">
                                            <td className="cell-item">Lácteos Lala</td>
                                            <td className="cell-item">Lala</td>
                                            <td className="cell-item">Mercadotecnia</td>
                                            <td className="cell-item">Alimentos</td>
                                            <td className="cell-item">01/01/2021</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Col> */}

            {renderSearchDocuments()}
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Busqueda;
