// const url =
//   "https://1astdjd11g.execute-api.us-west-2.amazonaws.com/api/v1/emotions/";

const url = `${process.env.REACT_APP_BACKEND_VIZ_API_HOST}/api/v1/emotions/`;

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept",
};

export async function getEmotionmeter(term) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(`${url}emotion_meter?term_to_lookup=${term}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  });
  // if(response.status >= 400 && response.status < 600){
  //     console.log("Capture Error Token")
  //     window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
  //     window.location.href = '/';
  // }
  // else{
  //     return await response.json();
  // }
  return response.json();
}

export async function getSentiment(term) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(
    `${url}sentiment_circles?term_to_lookup=${term}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }
  );
  // if(response.status >= 400 && response.status < 600){
  //     console.log("Capture Error Token")
  //     window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
  //     window.location.href = '/';
  // }
  // else{
  //     return await response.json();
  // }

  return response.json();
}

export async function getTopTermsEmotions(term) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(`${url}top_five_tokens?term_to_lookup=${term}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  });
  // if(response.status >= 400 && response.status < 600){
  //     console.log("Capture Error Token")
  //     window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
  //     window.location.href = '/';
  // }
  // else{
  //     return await response.json();
  // }
  return response.json();
}

export async function getTermsSpecificEmotion(term, emotion) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(
    `${url}top_ten_tokens?term_to_lookup=${term}&emotion=${emotion}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }
  );
  // if(response.status >= 400 && response.status < 600){
  //     console.log("Capture Error Token")
  //     window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
  //     window.location.href = '/';
  // }
  // else{
  //     return await response.json();
  // }
  return response.json();
}

export async function getEmotionGraph(term, emotion) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(
    `${url}emotion_graph?term_to_lookup=${term}&emotion=${emotion}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }
  );

  return response.json();
}
