import React, { createRef, Component } from 'react';
import { Network, Node, Edge } from '@lifeomic/react-vis-network';
// import Skeleton from '@material-ui/lab/Skeleton';
import { Col } from 'react-bootstrap';

import 'styles/generic.css';

const colors = {
  felicidad: ['#6B9B3E', '#80B44E', '#B0E380'],
  miedo: ['#B74E29', '#D77843', '#EBAB75'],
  enojo: ['#A53227', '#D33F37', '#D95D4C'],
  disgusto: ['#402A6F', '#68518C', '#AA9BC3'],
  tristeza: ['#355E87', '#4282B4', '#ADC3E5'],
};

export class EmotionNetwork extends Component {
  networkComponent = createRef();


  constructor(props) {
    super(props);
    this.state = {
      context: null,
      showContext: false
    };
  }

  componentDidMount() {
    this.networkComponent.current.network.on('click', (event) => {
      if (event.items.length === 1) {
        this.edgeClicked(event.items[0].edgeId);
      }
    });
  }

  edgeClicked(edgeId) {
    const nodes = edgeId.split('-');
    this.props.emotiongraph.edges.map((edge) => {
      if (edge.source === nodes[0] && edge.target === nodes[1]) {
        console.log(edge.context);
        this.setState({ context: edge.context, showContext: true})
        // Falta mostrar contexto
      }
    });
  }

  render() {
    const { emotiongraph, emotion } = this.props;

    function getNodeSize(level) {
      return 50 - 10 * level;
    }

    function getNodeColor(level) {
      if (level === 0) {
        return colors[emotion][0];
      }
      if (level === 1) {
        return colors[emotion][1];
      }

      return colors[emotion][2];
    }

    function getEdgeWidth(value) {
      if (value > 0 && value < 10) {
        return 10;
      }
      // else if (value > 70 && value < 90){
      //   return 80;
      // }

      return value;
    }

    function renderNodes() {
      const nodes = [];
      emotiongraph.nodes.map((node) => {
        if (node.level === 0) {
          nodes.push(
            <Node
              id={node.label}
              label={node.label}
              size={getNodeSize(node.level)}
              x={350}
              y={-280}
              color={getNodeColor(node.level)}
            />
          );
        } else {
          nodes.push(
            <Node
              id={node.label}
              label={node.label}
              size={getNodeSize(node.level)}
              color={getNodeColor(node.level)}
            />
          );
        }
      });
      return nodes;
    }

    function renderEdges() {
      const edges = [];
      emotiongraph.edges.map((edge) => {
        edges.push(
          <Edge
            id={`${edge.source}-${edge.target}`}
            from={edge.source}
            to={edge.target}
            width={getEdgeWidth(edge.value)}
          />
        );
      });
      return edges;
    }

    // Falta poner No se encontraron resultados******************************************
    // emotiongraph.nodes.map((node, index) => {
    //   if (index <= 9){
    //     console.log(node.label)
    //   }
    // })

    const Options = {
      physics: {
        barnesHut: {
          gravitationalConstant: -6000,
          centralGravity: 0.4,
          springLength: 90,
          springConstant: 0.001,
        },
        minVelocity: 0.75,
      },
      width: '100%',
      height: '700px',
      layout: {
        randomSeed: undefined,
        improvedLayout: true,
        hierarchical: {
          enabled: false,
          levelSeparation: 150,
          nodeSpacing: 100,
          treeSpacing: 200,
          blockShifting: true,
          edgeMinimization: true,
          parentCentralization: true,
          direction: 'UD', // UD, DU, LR, RL
          sortMethod: 'hubsize', // hubsize, directed
        },
      },
      nodes: {
        shape: 'dot',
        font: {
          color: 'black',
          size: 40,
          strokeWidth: 5,
          strokeColor: 'white',
        },
      },
      edges: {
        color: {
          color: '#b3b6b7 ',
          highlight: '#707272',
        },
        smooth: {
          type: 'vertical',
          forceDirection: 'none',
          roundness: 0.3,
        },
      },
    };

    return (
      <>
        <div>
        <Network
          options={Options}
          ref={this.networkComponent}
          // position={(1, -2)}
        >
          {renderNodes()}
          {renderEdges()}
        </Network>
        </div>
        {this.state.showContext ? (
          <Col className="back-gray borderR-15 mt20 p20">
            <div>
              <p className="ftn13 pb10"> {this.state.context} </p>
            </div>
          </Col>
        ) : null}
      </>
    );
  }
}

export default EmotionNetwork;
