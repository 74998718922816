import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import '../styles/index.css';
import "../styles/login.css";
import AuthService from '../services/AuthService';


const Login = () => {

  const auth = new AuthService();

  document.body.classList.add('bodyLogin');
  document.body.style.background = "url('/img/bg_login.png')";


  useEffect(()=>{
    localStorage.clear();
    auth.lock.show();
  },[])
    

  return (
    <div className="bgImage">
      <motion.div className="form-usuario" 
        initial={{ y: -500, opacity: 0}}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.8 }}
      >
        
      </motion.div>
    </div>
  );
}
 
export default Login;