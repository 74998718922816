import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MainNav from '../components/ui/navbar';
import '../styles/proyecto.css';

const Proyecto = () => {
  console.log('test');

  return (
    <Container fluid>
      <Row>
        <MainNav />
        <Container>
          <Col md={12} sm={12}>
            <h4 className="lider-title font-medium">Proyectos activos</h4>
          </Col>
          <Row>
            <Col md={4} sm={12} className="lider-itemContainer">
              <Row className="lider-itemResult">
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <ul className="lider-wordTag">
                    <li className="font-medium">Nuevo</li>
                  </ul>
                </Col>
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <div className="text-left">
                    <a className="linkViewMore font-medium">Ver más detalles</a>
                    <div className="icon-container">
                      <a>
                        <img src="/img/see.svg" className="img-icon" />
                      </a>
                    </div>
                    <div className="icon-container">
                      <a>
                        <img src="/img/edit.svg" className="img-icon" />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Registro:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      23/02/2021
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Nombre:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Lácteos lala
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Cliente:
                    </div>
                    <div className="busqueda-itemDesc font-regular">Lala</div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">NSE:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Medio típico
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Temáticas:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Alimentos
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Área responsable:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Análisis
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Campo:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Sondeo casa, Sondeo calle, registro fotográfico
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4} sm={12} className="lider-itemContainer">
              <Row className="lider-itemResult">
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <ul className="lider-wordTag">
                    <li className="font-medium">Nuevo</li>
                  </ul>
                </Col>
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <div className="text-left">
                    <a className="linkViewMore font-medium">Ver más detalles</a>
                    <div className="icon-container">
                      <a>
                        <img src="/img/see.svg" className="img-icon" />
                      </a>
                    </div>
                    <div className="icon-container">
                      <a>
                        <img src="/img/edit.svg" className="img-icon" />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Registro:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      23/02/2021
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Nombre:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Lácteos lala
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Cliente:
                    </div>
                    <div className="busqueda-itemDesc font-regular">Lala</div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">NSE:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Medio típico
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Temáticas:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Alimentos
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Área responsable:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Análisis
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Campo:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Sondeo casa, Sondeo calle, registro fotográfico
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4} sm={12} className="lider-itemContainer">
              <Row className="lider-itemResult">
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <ul className="lider-wordTag">
                    <li className="font-medium">Nuevo</li>
                  </ul>
                </Col>
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <div className="text-left">
                    <a className="linkViewMore font-medium">Ver más detalles</a>
                    <div className="icon-container">
                      <a>
                        <img src="/img/see.svg" className="img-icon" />
                      </a>
                    </div>
                    <div className="icon-container">
                      <a>
                        <img src="/img/edit.svg" className="img-icon" />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Registro:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      23/02/2021
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Nombre:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Lácteos lala
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Cliente:
                    </div>
                    <div className="busqueda-itemDesc font-regular">Lala</div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">NSE:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Medio típico
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Temáticas:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Alimentos
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Área responsable:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Análisis
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Campo:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Sondeo casa, Sondeo calle, registro fotográfico
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4} sm={12} className="lider-itemContainer">
              <Row className="lider-itemResult">
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <ul className="lider-wordTag">
                    <li className="font-medium">Nuevo</li>
                  </ul>
                </Col>
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <div className="text-left">
                    <a className="linkViewMore font-medium">Ver más detalles</a>
                    <div className="icon-container">
                      <a>
                        <img src="/img/see.svg" className="img-icon" />
                      </a>
                    </div>
                    <div className="icon-container">
                      <a>
                        <img src="/img/edit.svg" className="img-icon" />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Registro:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      23/02/2021
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Nombre:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Lácteos lala
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Cliente:
                    </div>
                    <div className="busqueda-itemDesc font-regular">Lala</div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">NSE:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Medio típico
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Temáticas:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Alimentos
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Área responsable:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Análisis
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Campo:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Sondeo casa, Sondeo calle, registro fotográfico
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4} sm={12} className="lider-itemContainer">
              <Row className="lider-itemResult">
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <ul className="lider-wordTag">
                    <li className="font-medium">Nuevo</li>
                  </ul>
                </Col>
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <div className="text-left">
                    <a className="linkViewMore font-medium">Ver más detalles</a>
                    <div className="icon-container">
                      <a>
                        <img src="/img/see.svg" className="img-icon" />
                      </a>
                    </div>
                    <div className="icon-container">
                      <a>
                        <img src="/img/edit.svg" className="img-icon" />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Registro:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      23/02/2021
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Nombre:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Lácteos lala
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Cliente:
                    </div>
                    <div className="busqueda-itemDesc font-regular">Lala</div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">NSE:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Medio típico
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Temáticas:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Alimentos
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Área responsable:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Análisis
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Campo:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Sondeo casa, Sondeo calle, registro fotográfico
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4} sm={12} className="lider-itemContainer">
              <Row className="lider-itemResult">
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <ul className="lider-wordTag">
                    <li className="font-medium">Nuevo</li>
                  </ul>
                </Col>
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <div className="text-left">
                    <a className="linkViewMore font-medium">Ver más detalles</a>
                    <div className="icon-container">
                      <a>
                        <img src="/img/see.svg" className="img-icon" />
                      </a>
                    </div>
                    <div className="icon-container">
                      <a>
                        <img src="/img/edit.svg" className="img-icon" />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Registro:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      23/02/2021
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Nombre:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Lácteos lala
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Cliente:
                    </div>
                    <div className="busqueda-itemDesc font-regular">Lala</div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">NSE:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Medio típico
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Temáticas:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Alimentos
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Área responsable:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Análisis
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Campo:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Sondeo casa, Sondeo calle, registro fotográfico
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4} sm={12} className="lider-itemContainer">
              <Row className="lider-itemResult">
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <ul className="lider-wordTag">
                    <li className="font-medium">Nuevo</li>
                  </ul>
                </Col>
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <div className="text-left">
                    <a className="linkViewMore font-medium">Ver más detalles</a>
                    <div className="icon-container">
                      <a>
                        <img src="/img/see.svg" className="img-icon" />
                      </a>
                    </div>
                    <div className="icon-container">
                      <a>
                        <img src="/img/edit.svg" className="img-icon" />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Registro:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      23/02/2021
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Nombre:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Lácteos lala
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Cliente:
                    </div>
                    <div className="busqueda-itemDesc font-regular">Lala</div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">NSE:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Medio típico
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Temáticas:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Alimentos
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Área responsable:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Análisis
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Campo:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Sondeo casa, Sondeo calle, registro fotográfico
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4} sm={12} className="lider-itemContainer">
              <Row className="lider-itemResult">
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <ul className="lider-wordTag">
                    <li className="font-medium">Nuevo</li>
                  </ul>
                </Col>
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <div className="text-left">
                    <a className="linkViewMore font-medium">Ver más detalles</a>
                    <div className="icon-container">
                      <a>
                        <img src="/img/see.svg" className="img-icon" />
                      </a>
                    </div>
                    <div className="icon-container">
                      <a>
                        <img src="/img/edit.svg" className="img-icon" />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Registro:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      23/02/2021
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Nombre:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Lácteos lala
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Cliente:
                    </div>
                    <div className="busqueda-itemDesc font-regular">Lala</div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">NSE:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Medio típico
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Temáticas:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Alimentos
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Área responsable:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Análisis
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Campo:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Sondeo casa, Sondeo calle, registro fotográfico
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4} sm={12} className="lider-itemContainer">
              <Row className="lider-itemResult">
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <ul className="lider-wordTag">
                    <li className="font-medium">Nuevo</li>
                  </ul>
                </Col>
                <Col md={6} sm={6} className="lider-headDataContainer">
                  <div className="text-left">
                    <a className="linkViewMore font-medium">Ver más detalles</a>
                    <div className="icon-container">
                      <a>
                        <img src="/img/see.svg" className="img-icon" />
                      </a>
                    </div>
                    <div className="icon-container">
                      <a>
                        <img src="/img/edit.svg" className="img-icon" />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Registro:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      23/02/2021
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Nombre:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Lácteos lala
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Cliente:
                    </div>
                    <div className="busqueda-itemDesc font-regular">Lala</div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">NSE:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Medio típico
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={6} className="lider-dataContainer">
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Temáticas:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Alimentos
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">
                      Área responsable:
                    </div>
                    <div className="busqueda-itemDesc font-regular">
                      Análisis
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="busqueda-itemName font-medium">Campo:</div>
                    <div className="busqueda-itemDesc font-regular">
                      Sondeo casa, Sondeo calle, registro fotográfico
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Proyecto;
