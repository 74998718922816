import { React, useEffect, useRef } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";
import "../styles/proyecto.css";

const Registro = () => {
  const container = useRef();
  const accessToken = localStorage.getItem("accessToken");
  const apiUrl = `${process.env.REACT_APP_BACKEND_API_HOST}`;

  useEffect(() => {
    createWidget("EtkG0Mzx", {
      container: container.current,
      size: "100",
      onSubmit: (event) => {
        console.log("Submitted Typeform", event.response_id);
        // Cambio Local Storage
        const user = JSON.parse(localStorage.getItem("User"));
        const aux = {
          id: user.id,
          username: user.username,
          email: user.email,
          usertype: user.usertype,
          answered_typeform: true,
          views: user.views,
        };
        const saveAux = JSON.stringify(aux);
        localStorage.setItem("User", saveAux);
        // Patch
        userAnswer(true);
      },
    });
  }, []);

  async function userAnswer(answered) {
    await fetch(`${apiUrl}/api/v3/user/?answered_typeform=${answered}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        window.location.href = "/callback";
      });
  }

  return (
    // <Container fluid>
    //         {/* <iframe
    //             title="Survey"
    //             width="100%"
    //             height="490"
    //             src={`https://deepdivedatascienceapi.typeform.com/to/EtkG0Mzx`}
    //         />
    //         <button id="endValidation" className="btn-blue validation-button font-medium text-center" >Terminar Registro</button> */}
    // </Container>
    <div ref={container} className="registro" />
  );
};

export default Registro;
