import React, { memo } from 'react';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot';

function Chart({ bubbles, categories, onClickTopic, limits, sizes, colors }) {
  return (
    <ResponsiveSwarmPlot
      data={bubbles}
      tooltip={({ data, color }) => (
        <div
          style={{
            fontSize: 10,
            paddingLeft: 14,
            paddingRight: 14,
            paddingTop: 8,
            paddingBottom: 8,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: color,
            background: 'rgba(255, 255, 255, 0.7)',
            borderRadius: 5,
          }}
        >
          <span>{data.topic_id}</span>
          <span style={{ marginLeft: 20 }}>Size: {data.size}</span>
        </div>
      )}
      groups={categories}
      colors={colors}
      colorBy="group"
      identity="topic_id"
      value="position"
      groupBy="classification"
      spacing={5}
      onClick={onClickTopic}
      valueScale={{
        type: 'linear',
        min: limits.min,
        max: limits.max,
        reverse: false,
      }}
      size={{ key: 'size', values: [sizes.min, sizes.max], sizes: [30, 250] }}
      layout="horizontal"
      forceStrength={8}
      simulationIterations={99}
      borderColor={{
        from: 'color',
        modifiers: [
          ['darker', 1],
          ['opacity', 0.5],
        ],
      }}
      margin={{ top: 150, right: 50, bottom: 120, left: 100 }}
      enableGridX={false}
      enableGridY={false}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
    />
  );
}

export default memo(Chart);
