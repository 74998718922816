import { colors } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Bar, defaults, Chart } from 'react-chartjs-2';

// defaults.plugins.legend.position = 'top';
Chart.defaults.plugins.legend.display = 'false';

const BarChar = ({ labels, scores, backgroundColors }) => {
  return (
    <div>
      <Bar
        data={{
          labels,
          datasets: [
            {
              label: 'Términos relacionados',
              data: scores,
              backgroundColor: backgroundColors,
              borderWidth: 1,
            },
          ],
        }}
        height={300}
        width={400}
        options={{
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
        }}
      />
    </div>
  );
};
export default BarChar;
