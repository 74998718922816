// const url = 'https://1astdjd11g.execute-api.us-west-2.amazonaws.com/api/v1';
const url = `${process.env.REACT_APP_BACKEND_VIZ_API_HOST}/api/v1`;

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept",
};

export async function getAnswers({ question, size, filters = {} }) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(`${url}/answer`, {
    method: "POST",
    body: JSON.stringify({
      question,
      size,
      filters,
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  });
  return response.json();
}

export async function getFeedback({
  question,
  passage,
  positive = false,
  negative = false,
}) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(`${url}/feedback`, {
    method: "POST",
    body: JSON.stringify({
      question,
      passage,
      positive,
      negative,
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  });
  return response.json();
}
