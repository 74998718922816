import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
// import sentimentData from '../../data/top_sentimiento.json';

const SentimentChart2 = ({ sentimentData }) => {
  // Themes begin
  am4core.useTheme(am4themes_animated);
  // Themes end

  /* Create chart instance */
  const chart = am4core.create('chartdiv2', am4charts.RadarChart);

  /* Create axes */
  const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
  xAxis.renderer.maxLabelPosition = 0;
  xAxis.renderer.labels.template.opacity = 0;

  const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
  yAxis.renderer.labels.template.verticalCenter = 'bottom';
  yAxis.renderer.labels.template.horizontalCenter = 'right';
  yAxis.renderer.labels.template.opacity = 0;

  /* Create and configure series */
  const series = chart.series.push(new am4charts.RadarSeries());
  series.bullets.push(new am4charts.CircleBullet());
  series.strokeOpacity = 0;
  series.dataFields.valueX = 'x';
  series.dataFields.valueY = 'y';
  series.dataFields.value = 'value';
  series.dataFields.color = 'color';
  series.name = series.dataFields.title = 'title';
  series.sequencedInterpolation = true;
  series.tooltip.pointerOrientation = 'vertical';

  // Get Data
  const data2 = [];

  const colors = [
    '#3464AA',
    '#A84188',
    '#E79C71',
    '#AAC869',
    '#D95F56',
    '#FFCE00',
    '#D96231',
    '#D43A71',
    '#70589C',
    '#24A7E0',
  ];

  function getColor(y) {
    if (y > 55) {
      return '#001C5A';
    } if (y <= 55 && y > 53) {
      return '#052A7A';
    } if (y <= 53 && y > 50) {
      return '#0B3384';
    } if (y <= 50 && y > 48) {
      return '#123B8C';
    } if (y <= 48 && y > 45) {
      return '#183D8F';
    } if (y <= 45 && y > 43) {
      return '#21489E';
    } if (y <= 43 && y > 40) {
      return '#';
    } if (y <= 40 && y > 38) {
      return '#1F4B9D';
    } if (y <= 38 && y > 35) {
      return '#2553A5';
    } if (y <= 35 && y > 33) {
      return '#21489E';
    } if (y <= 33 && y > 30) {
      return '#2C5BAD';
    } if (y <= 30 && y > 28) {
      return '#3263B5';
    } if (y <= 28 && y > 25) {
      return '#396BBE';
    } if (y <= 25 && y > 23) {
      return '#4073C6';
    } if (y <= 23 && y > 20) {
      return '#467BCE';
    } if (y <= 20 && y > 18) {
      return '#538BDE';
    } if (y <= 18 && y > 15) {
      return '#5D9BDC';
    } if (y <= 15 && y > 13) {
      return '#598FC8';
    } if (y <= 13 && y > 10) {
      return '#6EA1D6';
    }if (y <= 10 && y > 8) {
      return '#81B1E4';
    }if (y <= 8 && y > 5) {
      return '#8CB9E9';
    } else {
      return '#98C2F0';
    }
  }

  // sentimentData.map((concept, index) => {
  //   if(index < 10){
  //     data2.push({
  //       "title": concept.term,
  //       "x": (index + 1)*10,
  //       "y": (100 - concept.score*(-100)),
  //       // "y": (100- concept.score),
  //       "value": 30,
  //       "color": getColor((100 - concept.score*(-100)))
  //     })
  //     chart.data = data2;
  //     //console.log("Dentro del if"+ data2)
  //   }
  // })
  sentimentData.labels.map((concept, index) => {
    if (index < 10) {
      data2.push({
        title: concept,
        x: (index + 1) * 10,

        y: sentimentData.data[index],
        value: 30,
        color: getColor(sentimentData.data[index]),
      });
    }
  });

  console.log(data2);
  chart.data = data2;
  // console.log("Datos finales "+ data2)

  chart.scrollbarX = new am4core.Scrollbar();
  // chart.scrollbarX.minHeight = 5;
  chart.scrollbarX.width = 100;
  chart.scrollbarX.parent = chart.bottomAxesContainer;
  chart.scrollbarY = new am4core.Scrollbar();
  chart.scrollbarY.height = 100;
  chart.scrollbarY.parent = chart.rightAxesContainer;

  const bullet = series.bullets.push(new am4core.Circle());
  bullet.fill = am4core.color('color');
  bullet.propertyFields.fill = 'color';
  bullet.strokeOpacity = 0;
  bullet.strokeWidth = 0;
  bullet.fillOpacity = 1;
  bullet.stroke = am4core.color('color');
  bullet.hiddenState.properties.opacity = 0;
  bullet.tooltipText = '[bold]{title}:[/] {value.value}';
  bullet.title = '[bold]{title}:[/] {value.value}';

  const labelBullet = series.bullets.push(new am4charts.LabelBullet());
  labelBullet.label.text = '{title}';
  labelBullet.label.dy = 0;

  const hoverState = bullet.states.create('hover');
  hoverState.properties.fillOpacity = 1;
  hoverState.properties.strokeOpacity = 1;

  series.heatRules.push({
    target: bullet,
    min: 10,
    max: 30,
    property: 'radius',
    logarithmic: true,
  });

  return <div id="chartdiv2" style={{ width: '100%', height: '500px' }} />;
};

export default SentimentChart2;
