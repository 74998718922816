import React, { useState, useEffect } from "react";
// import topData from "../../data/top_10_terminos.json";
import { Row, Col, Carousel } from "react-bootstrap";
import parse from "html-react-parser";
import BarCharEmotion1 from "../../views/graphic/BarCharEmotion1";
import BarCharEmotion2 from "../../views/graphic/BarCharEmotion2";
import { getRelation } from "../../utils/dashboardCalls/overviewCalls";
import style from "components/Context/styles.module.scss";

import Highlighter from "react-highlight-words";

import styles from "styles/modules/newDashboard.module.scss";

const TopTermsSentiment = ({ topTermsSentiment, searchTerm }) => {
  const [labelsPositive, setLabelsPositive] = useState();
  const [labelsNegative, setLabelsNegative] = useState();
  const [scoresPositive, setScoresPositive] = useState();
  const [scoresNegative, setScoresNegative] = useState();

  const accessToken = localStorage.getItem("accessToken");
  const [showContext, setShowContext] = useState(false);
  const [context, setContext] = useState();
  const [extraInfo, setExtraInfo] = useState();
  const [otherTerm, setOtherTerm] = useState();

  useEffect(() => {
    const labelsPos = [];
    const labelsNeg = [];
    const scoresPos = [];
    const scoresNeg = [];
    topTermsSentiment.positive.map((top) => {
      labelsPos.push(top.term);
      scoresPos.push(top.score);
    });
    setLabelsPositive(labelsPos);
    setScoresPositive(scoresPos);
    topTermsSentiment.negative.map((top) => {
      labelsNeg.push(top.term);
      scoresNeg.push(top.score);
    });
    setLabelsNegative(labelsNeg);
    setScoresNegative(scoresNeg);
  }, []);

  const handleContext = (e) => {
    setShowContext(true);

    const id = e.target.id.split("-");
    if (id[0] === "positive") {
      console.log("current context:");
      console.log(id);
      console.log(topTermsSentiment);
      console.log(topTermsSentiment.positive[id[1]].context);
      setContext(topTermsSentiment.positive[id[1]].context.text);
      setExtraInfo(topTermsSentiment.positive[id[1]]);

      setOtherTerm(topTermsSentiment.positive[id[1].term]);
    } else {
      setContext(topTermsSentiment.negative[id[1]].context.text);
      setExtraInfo(topTermsSentiment.negative[id[1]]);
      setOtherTerm(topTermsSentiment.negative[id[1].term]);
    }
  };

  function renderComparative(sentiment) {
    const comparative_first = [];
    const comparative_second = [];
    var classIndex = "p0 pt10 contComparative borderB11";
    if (sentiment === "positive") {
      var classIndex = "p0 pt10 contComparative borderB11";
      topTermsSentiment.positive.map((top, index) => {
        if (index < 3) {
          comparative_first.push(
            <div className="d-flex pt10">
              <Col md={1} sm={1} className="p0">
                <p className="fnt20 txt_bold">{index + 1}.</p>
              </Col>
              <Col md={11} sm={11} className={classIndex}>
                <Col className="ftn11 pl10 pr10">{top.term}</Col>
                <Col className="ftn11 pl0 txt-end">
                  Size: {top.score.toFixed(2)}
                </Col>
                <Col className="ftn11 p0">
                  <button
                    type="button"
                    id={"positive-" + index.toString()}
                    className="btn-white context-button-small ftn11 text-center w100p p0"
                    onClick={handleContext}
                  >
                    Ver contexto
                  </button>
                </Col>
              </Col>
            </div>
          );
        } else {
          comparative_second.push(
            <div className="d-flex pt10">
              <Col md={1} sm={1} className="p0">
                <p className="fnt20 txt_bold">{index + 1}.</p>
              </Col>
              <Col md={11} sm={11} className={classIndex}>
                <Col className="ftn11 pl10 pr10">{top.term}</Col>
                <Col className="ftn11 pl0 txt-end">
                  Size: {top.score.toFixed(2)}
                </Col>
                <Col className="ftn11 p0">
                  <button
                    type="button"
                    id={"positive-" + index.toString()}
                    className="btn-white context-button-small ftn11 text-center w100p p0"
                    onClick={handleContext}
                  >
                    Ver contexto
                  </button>
                </Col>
              </Col>
            </div>
          );
        }
      });
    } else {
      var classIndex = "p0 pt10 contComparative borderB1";
      topTermsSentiment.negative.map((top, index) => {
        if (index < 3) {
          comparative_first.push(
            <div className="d-flex pt10">
              <Col md={1} sm={1} className="p0">
                <p className="fnt20 txt_bold">{index + 1}.</p>
              </Col>
              <Col md={11} sm={11} className={classIndex}>
                <Col className="ftn11 pl10 pr10">{top.term}</Col>
                <Col className="ftn11 pl0 txt-end">
                  Size: {top.score.toFixed(2)}
                </Col>
                <Col className="ftn11 p0">
                  <button
                    type="button"
                    id={"negative-" + index.toString()}
                    className="btn-white context-button-small ftn11 text-center w100p p0"
                    onClick={handleContext}
                  >
                    Ver contexto
                  </button>
                </Col>
              </Col>
            </div>
          );
        } else {
          comparative_second.push(
            <div className="d-flex pt10">
              <Col md={1} sm={1} className="p0">
                <p className="fnt20 txt_bold">{index + 1}.</p>
              </Col>
              <Col md={11} sm={11} className={classIndex}>
                <Col className="ftn11 pl10 pr10">{top.term}</Col>
                <Col className="ftn11 pl0 txt-end">
                  Size: {top.score.toFixed(2)}
                </Col>
                <Col className="ftn11 p0">
                  <button
                    type="button"
                    id={"negative-" + index.toString()}
                    className="btn-white context-button-small ftn11 text-center w100p p0"
                    onClick={handleContext}
                  >
                    Ver contexto
                  </button>
                </Col>
              </Col>
            </div>
          );
        }
      });
    }

    return {
      first: comparative_first,
      second: comparative_second,
    };
  }
  return (
    <>
      <Col>
        <p className="ftn13 pb10 txt_bold">
          Top 5 términos relacionados con "{searchTerm}" - Sentimiento positivo
        </p>
        <Row>
          <div>
            <BarCharEmotion1
              labelsPositive={labelsPositive}
              scoresPositive={scoresPositive}
            />
          </div>
          <Col md={5} sm={12}>
            {renderComparative("positive").first}
            {renderComparative("positive").second}
          </Col>
        </Row>
        <p className="ftn13 pb10 txt_bold pt25">
          Top 5 términos relacionados con "{searchTerm}" - Sentimiento negativo
        </p>
        <Row>
          <div>
            <BarCharEmotion2
              labelsNegative={labelsNegative}
              scoresNegative={scoresNegative}
            />
          </div>
          <Col md={5} sm={12}>
            {renderComparative("negative").first}
            {renderComparative("negative").second}
          </Col>
        </Row>
      </Col>
      <Row>
        {showContext ? (
          <Col className="back-gray borderR-15 mt20 p20">
            <div>
              <p className="ftn13 pb10">
                {" "}
                <b>Ciudad: </b>{" "}
                {extraInfo.context_info.ciudad
                  ? extraInfo.context_info.ciudad
                  : ""}{" "}
              </p>
              <p className="ftn13 pb10">
                {" "}
                <b>Estado: </b>{" "}
                {extraInfo.context_info.estado
                  ? extraInfo.context_info.estado
                  : ""}{" "}
              </p>
              <p className="ftn13 pb10">
                {" "}
                <b>NSE: </b>
                {extraInfo.context_info.nse
                  ? extraInfo.context_info.nse
                  : ""}{" "}
              </p>
              <p className="ftn13 pb10">
                {" "}
                <b>Proyecto: </b>{" "}
                {extraInfo.context_info.proyecto
                  ? extraInfo.context_info.proyecto
                  : ""}{" "}
              </p>
              <p className="ftn13 pb10">
                {" "}
                <b>Texto</b>{" "}
              </p>
              <p className="ftn13 pb10"> {extraInfo.context[0]} </p>
            </div>
          </Col>
        ) : null}
      </Row>

      {/* <Col md={4} sm={12}>>
                <BarCharEmotion1 labelsPositive={labelsPositive} scoresPositive={scoresPositive}/>
            </Col>
            {renderComparative("positive").first}
            {renderComparative("positive").second}
            <div><p className="ftn13 pb10 txt_bold">Top 5 términos relacionados con "{searchTerm}" - Sentimiento negativo</p></div> */}

      {/* <Col md={4} sm={12}>
                <div><p className="ftn13 pb10 txt_bold">Top 5 términos relacionados con "{searchTerm}" - Sentimiento positivo</p></div>
                <div><BarCharEmotion1 labelsPositive={labelsPositive} scoresPositive={scoresPositive}/></div>
                <div>
                    <Row>
                        <Col md={6} sm={12}>
                            {renderComparative("positive").first}
                        </Col>
                        <Col md={6} sm={12}>
                            {renderComparative("positive").second}
                        </Col>                                          
                    </Row>
                </div>
            </Col>
            <Col md={4} sm={12}>
                <div><p className="ftn13 pb10 txt_bold">Top 5 términos relacionados con "{searchTerm}" - Sentimiento negativo</p></div>
                <div><BarCharEmotion2 labelsNegative={labelsNegative} scoresNegative={scoresNegative}/></div>
                <div>
                    <Row>
                        <Col md={6} sm={12}>
                            {renderComparative("negative").first}
                        </Col>
                        <Col md={6} sm={12}>
                            {renderComparative("negative").second}
                        </Col>                                          
                    </Row>
                </div>
            </Col>  */}
    </>
  );
};

export default TopTermsSentiment;
