import React, { useState, useEffect } from 'react';
// import topData from "../../data/top_10_terminos.json";
import { Row, Col, Carousel, Table } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';

import clsx from 'clsx';

import style from 'styles/modules/newDashboard.module.scss';
import styles from './styles.module.scss';

const termsRelation = ({ relation, searchTerm, searchTerm2, setContextOutside }) => {
  const [showContextTerms, setShowContextTerms] = useState(false);

  const handleContext = (e) => {
    setShowContextTerms(true);
    console.log('handling context');
    setContextOutside((
      <Col md={12} sm={12} className="pt25">
        <Carousel className="contextFlechas">{renderContext()}</Carousel>
      </Col>
    ))
  };

  function renderContext() {
    const context = [];
    relation.topic.paragraphs.map((par, index) => {
      if (index > 1 && !(index % 2) && index < 12) {
        context.push(
          <Carousel.Item className="p40">
            <Row>
              <Col md={6} sm={12} className="pb10">
                <div
                  className="contSlider h200"
                  style={{
                    border: `2px solid ${getColor(relation.topic.text)}`,
                  }}
                >
                  {/* <p> {relation.topic.paragraphs[index]} </p> */}
                  <Highlighter
                    className={styles.highlighter}
                    highlightClassName={style.foundWord}
                    searchWords={[searchTerm, searchTerm2]}
                    textToHighlight={relation.topic.paragraphs[index]}
                  />
                </div>
              </Col>
              <Col md={6} sm={12} className="pb10">
                <div
                  className="contSlider h200"
                  style={{
                    border: `2px solid ${getColor(relation.topic.text)}`,
                  }}
                >
                  {/* <p>{relation.topic.paragraphs[index + 1]}</p> */}
                  <Highlighter
                    className={styles.highlighter}
                    highlightClassName={style.foundWord}
                    searchWords={[searchTerm, searchTerm2]}
                    textToHighlight={relation.topic.paragraphs[index + 1]}
                  />
                </div>
              </Col>
            </Row>
            <Carousel.Caption />
          </Carousel.Item>
        );
      }
    });

    return context;
  }

  function getColor(token) {
    console.log('token', token);
    if (token === 'Limpieza') {
      return '#3464AA';
    }
    if (token === 'Recreación - Entretenimiento') {
      return '#A84188';
    }
    if (token === 'Medios De Comunicación') {
      return '#E79C71';
    }
    if (token === 'Finanzas') {
      return '#AAC869';
    }
    if (token === 'Bebida') {
      return '#D95F56';
    }
    if (token === 'Laboral' || token === 'Trabajo') {
      return '#916085';
    }
    if (token === 'Comercio') {
      return '#74A15F';
    }
    if (token === 'Religión') {
      return '#DDB73E';
    }
    if (token === 'Alimento') {
      return '#CC5950';
    }
    if (token === 'Consumo') {
      return '#6BA0A5';
    }
    if (token === 'Valores Y Cultura') {
      return '#B2A882';
    }
    if (token === 'Seguridad') {
      return '#888FAA';
    }
    if (token === 'Política Y Gobierno' || token === 'Política y Gobierno') {
      return '#B78A8E';
    }
    if (token === 'Transporte') {
      return '#8CAFA3';
    }
    if (token === 'Vivienda') {
      return '#FFCE00';
    }
    if (token === 'Relaciones Sociales') {
      return '#D96231';
    }
    if (token === 'Tecnología') {
      return '#24A7E0';
    }
    if (token === 'Electrodoméstico') {
      return '#D3E1BE';
    }
    if (token === 'Familia') {
      return '#D43A71';
    }
    if (token === 'Educación') {
      return '#A9D4E4';
    }
    if (token === 'Deporte') {
      return '#70589C';
    }
    if (token === 'Vestimenta') {
      return '#F7E491';
    }
    if (token === 'Materiales') {
      return '#A7AFC1';
    }
    if (token === 'Salud Y Cuidado' || token === 'Salud y cuidado') {
      return '#F7E491';
    }
    return 'otro color';
  }

  return (
    <>
      <Row>
        <div
          className="borderT"
          style={{ border: `2px solid ${getColor(relation.topic.text)}` }}
        >
          <p className="ftn13 p20 txt_bold">
            Relación entre los términos "{searchTerm}" y "{searchTerm2}"
          </p>
          <p className="ftn13 pl20 pr30">
            Tienen una mayor asociación con la temática:{' '}
            <span
              className="ftn13 txt_bold"
              style={{
                backgroundColor: getColor(relation.topic.text),
                color: '#FFFF',
                border: `5px solid ${getColor(relation.topic.text)}`,
              }}
            >
              {' '}
              {relation.topic.text}{' '}
            </span>
          </p>
          <p className="ftn13 pl20 pr30 pt10">
            En esta temática aparecen juntos{' '}
            <span className="ftn13 txt_bold">
              {' '}
              {relation.topic.appearances}{' '}
            </span>{' '}
            veces.
          </p>
          <p className="ftn13 pl20 pr30 pt10">
            Algunos párrafos en donde aparecen juntos:
          </p>

          <p className="ftn13 pl20 pr30 pt10">
            <Highlighter
              className={styles.highlighter}
              highlightClassName={style.foundWord}
              searchWords={[searchTerm, searchTerm2]}
              textToHighlight={relation.topic.paragraphs[0]}
            />
          </p>
          <p className="ftn13 pl20 pr30 pt10">
          <Highlighter
              className={styles.highlighter}
              highlightClassName={style.foundWord}
              searchWords={[searchTerm, searchTerm2]}
              textToHighlight={relation.topic.paragraphs[1]}
            />
          </p>
          <p className="ftn13 pr30 pt10 pb10">
            <button
              type="button"
              id="verMas"
              className="btn-gray2 context-button-small ftn13 text_left"
              onClick={handleContext}
            >
              Ver más
            </button>
          </p>
        </div>
      </Row>
      {/* {showContextTerms ? (
        <Col md={12} sm={12} className="pt25">
          <Carousel className="contextFlechas">{renderContext()}</Carousel>
        </Col>
      ) : null} */}
    </>
  );
};

export default termsRelation;
