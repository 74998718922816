/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';

import Highlighter from 'react-highlight-words';

import Skeleton from '@material-ui/lab/Skeleton';

import styles from './styles.module.scss';

export default function Context({ data, loading, color, searchTopic }) {
  const wordsArray = data?.words.split(', ') || [];
  const foundWords = wordsArray.filter((word) =>
    data.description.includes(word)
  );

  return (
    <div
      className={clsx('back-gray borderR-15 mt20 p20', styles.card)}
      style={{ borderColor: color }}
    >
      <p>
        <b>Temática: </b>
        {loading ? <Skeleton variant="text" width="15%" /> : data.topic}
      </p>
      <p>
        <b>Contexto: </b>
        {loading ? <Skeleton variant="text" width="35%" /> : data?.words}
      </p>
      {loading ? (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </>
      ) : data?.words ? (
        <div className={styles.text}>
          <Highlighter
            className={styles.highlighter}
            highlightClassName={styles.foundWord}
            searchWords={wordsArray}
            textToHighlight={data.description}
          />
        </div>
      ) : (
        <p>{data.description}</p>
      )}
      {data?.words &&
        (loading ? (
          <Skeleton variant="text" width="45%" className={styles.loader} />
        ) : (
          <div className={styles.wordWrapper}>
            {!!foundWords.length && (
              <>
                <span>{`Palabra${foundWords.length > 1 ? 's' : ''} encontrada${
                  foundWords.length > 1 ? 's' : ''
                }:`}</span>
                {foundWords.map((word) => (
                  <button
                    type="button"
                    className={styles.word}
                    style={{ backgroundColor: color }}
                    onClick={() => searchTopic(word)}
                    key={word}
                  >
                    {word}
                  </button>
                ))}
              </>
            )}
          </div>
        ))}
    </div>
  );
}
