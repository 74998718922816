/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import times from 'lodash/times';

import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles.module.scss';

const Error = ({ onReload }) => (
  <div className={styles.error}>
    <p className={styles.errorDescription}>
      Ups. Esto está tardando más de lo normal 😵.
    </p>
    <p>
      <button type="button" onClick={onReload}>
        Haz click aquí
      </button>
      para volver a cargar el contenido.
    </p>
  </div>
);

const CardSkeleton = ({ type, loading, error, onReload }) => {
  if (type === 'summary') {
    if (loading) {
      return (
        <>
          <div className={styles.group}>
            {times(5, () => (
              <Skeleton height={14} />
            ))}
          </div>
          <div className={styles.group}>
            {times(5, () => (
              <Skeleton height={14} />
            ))}
          </div>
          <Skeleton height={14} />
        </>
      );
    }

    if (error) {
      return <Error onReload={onReload} />;
    }
  }

  if (type === 'emotionMeter') {
    if (loading) {
      return (
        <div className={styles.loader}>
          <CircularProgress
            size={180}
            thickness={2.5}
            className={styles.progressBar}
          />
        </div>
      );
    }

    if (error) {
      return <Error onReload={onReload} />;
    }
  }

  if (type === 'passages') {
    if (loading) {
      return (
        <Grid container spacing={3}>
          {times(3, (i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Paper className={styles.paper}>
                <div className={styles.group}>
                  {times(6, () => (
                    <Skeleton height={14} />
                  ))}
                </div>
                <div className={styles.group}>
                  {times(6, () => (
                    <Skeleton height={14} />
                  ))}
                </div>
                <div className={styles.group}>
                  {times(6, () => (
                    <Skeleton height={14} />
                  ))}
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      );
    }

    if (error) {
      return <Error onReload={onReload} />;
    }
  }

  return null;
};

export default CardSkeleton;
