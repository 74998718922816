import React from 'react';
import AuthService from './services/AuthService';

const Callback = () => {
  const authNew = new AuthService();
  const token = JSON.parse(localStorage.getItem('auth0'));
  const user = JSON.parse(localStorage.getItem('User'));
  const accessToken = localStorage.getItem('accessToken');

  if (token && user) {
    console.log('hay user');
    console.log('user', user);
    // async function asyncCall() {
    if (user.answered_typeform === true) {
      console.log('Ya llenó el typeform');
      window.location.href = '/welcome';
    } else {
      console.log('No ha llenado el typeform');
      // window.location.href = '/welcome';
      window.location.href = '/registro';
    }

    // switch(user.usertype){
    //     case 'lider_proyecto':
    //         console.log('lider');
    //         window.location.href = '/busqueda';
    //     break;
    //     case 'antropologo':
    //         console.log('antro');
    //         window.location.href = 'https://deepdivedatascienceapi.typeform.com/to/xz0geIbK';
    //     break;
    //     default:
    //         window.location.href = '/';
    //     break;
    // }
  }
  // else{
  // console.log('no hay user, redirect');
  // window.location.href = '/'
  // }

  return <main />;
};

export default Callback;
