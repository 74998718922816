import React, { useState, useEffect, createRef } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Accordion,
  useAccordionToggle,
  Modal,
  Table,
} from 'react-bootstrap';
import parse from 'html-react-parser';
import Dropzone from 'react-dropzone';
import MainNav from '../components/ui/navbar';
import {
  getValidationImages,
  validateImage,
  stopImageValidation,
} from '../utils/validationImagesCalls';
import ImageInfo from '../components/ImageInfo';
import '../styles/carga.css';
import '../styles/generic.css';
import '../styles/validation.css';

const Images = () => {
  const access_token = JSON.parse(localStorage.getItem('auth0'));
  const size = 6;
  const accessToken = localStorage.getItem('accessToken');

  const [imgData, setImgData] = useState([]);
  const [numValidated, setNumValidated] = useState(0);

  const [validatedIds, setValidatedIds] = useState([]);

  const [showSpinner, setShowSpinner] = useState(false);

  if (!access_token) {
    window.location.href = '/';
  }

  useEffect(() => {
    // Fetch Image Data
    // Buscamos en el localStorage (por si hubo refresh)
    const imagesData = JSON.parse(localStorage.getItem('imagesData'));
    if (imagesData) {
      console.log('Using saved images');
      console.log(imagesData);
      setImgData(imagesData);
    } else {
      getValidationImages(size, accessToken).then((data) => {
        console.log('data ===>', data);
        setImgData(data);
        // y lo guardamos en el localStorage
        localStorage.setItem('imagesData', JSON.stringify(data));
        console.log('Local Storage', localStorage.getItem('imagesData'));
      });
    }
  }, []);

  // Si ya se validó todo -> cargar nuevas entities
  useEffect(() => {
    if (numValidated === size) {
      console.log('validated all images!!!');
      // Obtener nuevas imgs para validar:
      getValidationImages(size, accessToken).then((data) => {
        console.log('data ===>', data);
        setImgData(data);
        // Update localStorage
        localStorage.setItem('imagesData', JSON.stringify(data));
        console.log('Local Storage', localStorage.getItem('imagesData'));
        setNumValidated(0);
      });
    }
  }, [numValidated]);

  // Cada vez que se hace una validación correctamente:
  // (esta función se llama desde el componente)
  function imageValidated(imageId) {
    console.log('Image validated');
    // Guardar id validado
    setValidatedIds((validatedIds) => [...validatedIds, imageId]);
    setNumValidated(numValidated + 1);

    document.getElementById(imageId).classList.add('is-validated');
  }

  // On click "Finalizar validación": end Review
  function endReview() {
    console.log(validatedIds);

    stopImageValidation(validatedIds, accessToken).then((response) => {
      setShowSpinner(true);
      localStorage.removeItem('imagesData');
      window.location.href = '/welcome';
    });
  }

  function renderImages() {
    console.log('Rendering images');
    const images = [];
    imgData.forEach((img) => {
      images.push(
        <ImageInfo key={img.id} imgInfo={img} imageValidated={imageValidated} />
      );
    });

    return images;
  }

  return (
    <Container fluid>
      <MainNav />
      <Row>
        <Container fluid>
          <Col className="">
            <Col className="pb50">
              <h4 className="section-title font-medium txt_random txt_left pb25">
                Validación de Imágenes
              </h4>
              <Col md={12} sm={12} className="p0">
                <Form inline>{renderImages()}</Form>
              </Col>
            </Col>
            <Form.Group className="validation-form">
              {showSpinner ? (
                <div className="spinner-border text-success" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <button
                  id="endValidation"
                  className="btn-blue validation-button font-medium text-center"
                  onClick={(e) => endReview()}
                >
                  Finalizar validación
                </button>
              )}
            </Form.Group>
          </Col>
        </Container>
      </Row>
    </Container>
  );
};

export default Images;
