import React, { createRef } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import MainNav from '../components/ui/navbar';
import '../styles/carga.css';

const Carga = () => {
  const dropzoneRef = createRef();
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  const onDrop = (acceptedFiles) => {
    // Do something with the files
    alert('drop');
  };
  /* setTimeout(function(){
		var LoaderExist = document.getElementById('LoaderContainer');
		console.log(LoaderExist);
		if (LoaderExist){
			LoaderExist.style.display = 'none';
		}
	}, 1000); */

  return (
    <Container fluid>
      <Row>
        <MainNav />
        <Container>
          <Col md={12} sm={12}>
            <h4 className="busqueda-title font-medium">
              Selecciona el proyecto al que pertenece tu archivo
            </h4>
          </Col>
          <Col md={12} sm={12}>
            <Form inline>
              <Col md={3} sm={12}>
                <Form.Control as="select" className="my-1 mr-sm-2" custom>
                  <option value="0">Lácteos Lala</option>
                </Form.Control>
              </Col>
            </Form>
          </Col>
          <Row>
            <Dropzone onDrop={onDrop} ref={dropzoneRef} noClick noKeyboard>
              {({ getRootProps, getInputProps, acceptedFiles }) => {
                return (
                  <Col md={12} sm={12} className="DropZoneContainer">
                    <div
                      {...getRootProps({
                        className: 'dropzone ItemsDropZoneContainer',
                      })}
                    >
                      <input {...getInputProps()} />
                      <div className="imgIcoUpload">
                        <img
                          src="/img/download_blue.svg"
                          className="img-icon"
                        />
                      </div>
                      <div className="busqueda-title font-medium text-center">
                        Arrastra y suelta tu archivo aquí o…
                      </div>
                      <aside>
                        <h4 className="busqueda-title font-medium text-center">
                          Archivo
                        </h4>
                        <ul>
                          {acceptedFiles.map((file) => (
                            <li key={file.path}>
                              {file.path} - {file.size} bytes
                            </li>
                          ))}
                        </ul>
                      </aside>
                      <div className="SelectFileContainer">
                        <button
                          className="SelectFile"
                          type="button"
                          onClick={openDialog}
                        >
                          Selecciona archivos
                        </button>
                      </div>
                    </div>
                  </Col>
                );
              }}
            </Dropzone>
          </Row>
          <Col md={12} sm={12} className="SelectFileContainer">
            <button className="BtnCargar">Cargar</button>
          </Col>
        </Container>
      </Row>
    </Container>
  );
};

export default Carga;
