import React, { useState, createRef } from 'react';
import { Container, Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import MainNav from '../components/ui/navbar';
import '../styles/entregable.css';
import '../styles/generic.css';

const RegistroEntregable = () => {
  console.log('test view correcta');

  const Carga = () => {
    const dropzoneRef = createRef();
    const openDialog = () => {
      // Note that the ref is set async,
      // so it might be null at some point
      if (dropzoneRef.current) {
        dropzoneRef.current.open();
      }
    };
    const onDrop = (acceptedFiles) => {
      // Do something with the files
      alert('drop');
    };
    /* setTimeout(function(){
            var LoaderExist = document.getElementById('LoaderContainer');
            console.log(LoaderExist);
            if (LoaderExist){
                LoaderExist.style.display = 'none';
            }
        }, 1000); */
    return (
      <Container fluid className="p0">
        <Row>
          <Dropzone onDrop={onDrop} ref={dropzoneRef} noClick noKeyboard>
            {({ getRootProps, getInputProps, acceptedFiles }) => {
              return (
                <Col md={12} sm={12} className="DropZoneContainer">
                  <div
                    {...getRootProps({
                      className: 'dropzone ItemsDropZoneContainer minor',
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className="imgIcoUpload">
                      <img
                        src="/img/icon_dowload.svg"
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <div className="ftn13 font-medium text-center pt15">
                      Arrastra y suelta tu archivo aquí o…
                    </div>
                    <aside>
                      <ul>
                        {acceptedFiles.map((file) => (
                          <li key={file.path}>
                            {file.path} - {file.size} bytes
                          </li>
                        ))}
                      </ul>
                    </aside>
                    <div className="SelectFileContainer">
                      <button
                        className="SelectFile"
                        type="button"
                        onClick={openDialog}
                      >
                        Selecciona archivos
                      </button>
                    </div>
                  </div>
                </Col>
              );
            }}
          </Dropzone>
        </Row>
        <Col md={12} sm={12} className="SelectFileContainer">
          <button className="BtnCargar">Cargar</button>
        </Col>
      </Container>
    );
  };

  return (
    <Container fluid>
      {/* <div className="entregableMainContainer">
				<h3 className="entregableTitle font-medium text-center">Hola, bienvenido al registro de documentos.</h3>
				<div className="textDescription text-center font-regular">
					A continuacion te pediremos datos de identificación relacionados al archivo que
					registrarás con el objetivo de catalogar mejor la información para
					lograr busquedas mas eficientes.
				</div>
				<div className="text-center">
					<button className="font-medium BtnComenzar">Cargar</button>
				</div>
			</div> */}
      <MainNav />
      <Container>
        <Row>
          <Col md={5} sm={12}>
            <h5 className="ftn13 font-medium">
              Selecciona el proyecto al que pertenece tu archivo
            </h5>
            <Form.Group className="FilterContainer pr0">
              <Form.Control
                id="categoria"
                name="categoria"
                as="select"
                className="my-1 mr-sm-2 selectProj pr0"
                custom
              >
                <option value="A">Proyecto A</option>
                <option value="B">Proyecto B</option>
                <option value="C">Proyecto C</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={7} sm={12}>
            <h5 className="ftn13 font-medium">
              Selecciona el tipo de entregable que vas a registrar
            </h5>
            <Form inline>
              <Form.Group className="FilterContainer pr0">
                <Form.Group
                  className="FilterContainer pr0"
                  name="tipoEntregable"
                >
                  <Form.Check
                    inline
                    label="Documento de texto"
                    value="Texto"
                    type="radio"
                    name="tipoEntregable"
                  />
                  <Form.Check
                    inline
                    label="Documento de texto con imágenes"
                    value="TextoImg"
                    type="radio"
                    name="tipoEntregable"
                  />
                  <Form.Check
                    inline
                    label="Solo imágenes"
                    value="Img"
                    type="radio"
                    name="tipoEntregable"
                  />
                </Form.Group>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className="pt10">
          <Col md={12} sm={12}>
            <h5 className="ftn13 font-medium">
              Entidades a registrar pre-seleccionadas
            </h5>
          </Col>
          <Col md={4} sm={12} className="d-flex spaceBetween p10 pr30">
            <p className="ftn13">Hogar general</p>
            <button type="button" className="SearchButton ftn11" name="hogar">
              Registrar
            </button>
          </Col>
          <Col md={4} sm={12} className="d-flex spaceBetween p10 pr30">
            <p className="ftn13">Hogar general</p>
            <button type="button" className="SearchButton ftn11" name="hogar">
              Registrar
            </button>
          </Col>
          <Col md={4} sm={12} className="d-flex spaceBetween p10 pr30">
            <p className="ftn13">Hogar general</p>
            <button type="button" className="SearchButton ftn11" name="hogar">
              Registrar
            </button>
          </Col>
          <Col md={4} sm={12} className="d-flex spaceBetween p10 pr30">
            <p className="ftn13">Hogar general</p>
            <button type="button" className="SearchButton ftn11" name="hogar">
              Registrar
            </button>
          </Col>
          <Col md={4} sm={12} className="d-flex spaceBetween p10 pr30">
            <p className="ftn13">Hogar general</p>
            <button type="button" className="SearchButton ftn11" name="hogar">
              Registrar
            </button>
          </Col>
          <Col md={4} sm={12} className="d-flex spaceBetween p10 pr30">
            <p className="ftn13">Hogar general</p>
            <button type="button" className="SearchButton ftn11" name="hogar">
              Registrar
            </button>
          </Col>
          <Col md={4} sm={12} className="d-flex spaceBetween p10 pr30">
            <p className="ftn13">Hogar general</p>
            <button type="button" className="SearchButton ftn11" name="hogar">
              Registrar
            </button>
          </Col>
          <Col md={4} sm={12} className="d-flex spaceBetween p10 pr30">
            <p className="ftn13">Hogar general</p>
            <button type="button" className="SearchButton ftn11" name="hogar">
              Registrar
            </button>
          </Col>
          <Col md={4} sm={12} className="d-flex spaceBetween p10 pr30">
            <p className="ftn13">Hogar general</p>
            <button type="button" className="SearchButton ftn11" name="hogar">
              Registrar
            </button>
          </Col>
          <Col md={12} sm={12} className="pt25">
            <h5 className="ftn13 font-medium">
              Agregar más entidades a registrar
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="ftn13">
              Carga de documento de texto o documento de texto con imágenes
            </h5>
          </Col>
          <Col md={12} sm={12}>
            <Carga />
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <h5 className="ftn13 font-medium">Carga de imágenes</h5>
          </Col>
          <Col md={4} sm={12}>
            <p className="ftn13">
              Ingresa un párrafo descriptivo de esta imagen o grupo de imágenes.
            </p>
            <Form.Control as="textarea" style={{ height: '200px' }} />
          </Col>
          <Col md={4} sm={12}>
            <p className="ftn13">
              Ingresa los conceptos que describen a esta imagen en un nivel
              analítico. Max 2.
            </p>
            <Row>
              <Col md={6} sm={12} className="pt5">
                <p className="d-flex ftn13">
                  Intimidad{' '}
                  <Form.Check aria-label="option 1" className="ml10" />
                </p>
              </Col>
              <Col md={6} sm={12}>
                <Form.Control
                  as="select"
                  className="my-1 mr-sm-2 selectProj pr0"
                  custom
                >
                  <option value="A">Proyecto A</option>
                  <option value="B">Proyecto B</option>
                  <option value="C">Proyecto C</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} className="pt5">
                <p className="d-flex ftn13">
                  Seguridad{' '}
                  <Form.Check aria-label="option 1" className="ml10" />
                </p>
              </Col>
              <Col md={6} sm={12}>
                <Form.Control
                  as="select"
                  className="my-1 mr-sm-2 selectProj pr0"
                  custom
                >
                  <option value="A">Proyecto A</option>
                  <option value="B">Proyecto B</option>
                  <option value="C">Proyecto C</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} className="pt5">
                <p className="d-flex ftn13">
                  Violencia{' '}
                  <Form.Check aria-label="option 1" className="ml10" />
                </p>
              </Col>
              <Col md={6} sm={12}>
                <Form.Control
                  as="select"
                  className="my-1 mr-sm-2 selectProj pr0"
                  custom
                >
                  <option value="A">Proyecto A</option>
                  <option value="B">Proyecto B</option>
                  <option value="C">Proyecto C</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} className="pt5">
                <p className="d-flex ftn13">
                  Patrimonio{' '}
                  <Form.Check aria-label="option 1" className="ml10" />
                </p>
              </Col>
              <Col md={6} sm={12}>
                <Form.Control
                  as="select"
                  className="my-1 mr-sm-2 selectProj pr0"
                  custom
                >
                  <option value="A">Proyecto A</option>
                  <option value="B">Proyecto B</option>
                  <option value="C">Proyecto C</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} className="pt5">
                <p className="d-flex ftn13">
                  Otro <Form.Check aria-label="option 1" className="ml10" />
                </p>
              </Col>
              <Col md={6} sm={12}>
                <Form.Control
                  as="select"
                  className="my-1 mr-sm-2 selectProj pr0"
                  custom
                >
                  <option value="A">Proyecto A</option>
                  <option value="B">Proyecto B</option>
                  <option value="C">Proyecto C</option>
                </Form.Control>
              </Col>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Carga />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default RegistroEntregable;
