import React from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_sunburst from "@amcharts/amcharts4/plugins/sunburst";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import nseData from '../../data/nse_comparison.json';

const SunburstChart = ({ nseData }) => {
  // Get Data
  function getNseData() {
    const nse = [];
    nseData.map((concept) => {
      nse.push({
        name: concept.term,
        /* color: [
          am4core.color("#000"),
          am4core.color("#fff"),
          am4core.color("#000"),
          am4core.color("#000"),
          am4core.color("#000"),
          am4core.color("#000"),
          am4core.color("#000"),
        ], */
        children: [
          { name: "Alta", value: concept.scores.alta, color: "#f7e491" },
          {
            name: "Media Alta",
            value: concept.scores["media_alta"],
            color: "#A7AFC1",
          },
          {
            name: "Media Típica",
            value: concept.scores["media_típica"],
            color: "#A6C0DA",
          },
          {
            name: "Media Baja",
            value: concept.scores["media_baja"],
            color: "#A9D4E4",
          },
          { name: "Baja", value: concept.scores.baja, color: "#D3E1BE" },
        ],
      });
    });
    return nse;
  }

  am4core.options.autoDispose = true;
  am4core.useTheme(am4themes_animated);
  // Themes end

  // create chart
  const chart = am4core.create("sunchartdiv", am4plugins_sunburst.Sunburst);
  chart.padding(0, 0, 0, 0);
  chart.radius = am4core.percent(98);

  chart.data = getNseData();

  chart.colors.step = 2;
  chart.fontSize = 11;
  chart.innerRadius = am4core.percent(10);

  // define data fields
  chart.dataFields.value = "value";
  chart.dataFields.name = "name";
  chart.dataFields.children = "children";
  chart.dataFields.color = "color";

  const level0SeriesTemplate = new am4plugins_sunburst.SunburstSeries();
  level0SeriesTemplate.hiddenInLegend = false;
  chart.seriesTemplates.setKey("0", level0SeriesTemplate);

  // this makes labels to be hidden if they don't fit
  level0SeriesTemplate.labels.template.truncate = true;
  level0SeriesTemplate.labels.template.hideOversized = true;

  level0SeriesTemplate.labels.template.adapter.add(
    "rotation",
    function (rotation, target) {
      target.maxWidth =
        target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
      target.maxHeight = Math.abs(
        ((target.dataItem.slice.arc *
          (target.dataItem.slice.innerRadius + target.dataItem.slice.radius)) /
          2) *
          am4core.math.RADIANS
      );

      return rotation;
    }
  );

  const level1SeriesTemplate = level0SeriesTemplate.clone();
  chart.seriesTemplates.setKey("1", level1SeriesTemplate);
  level1SeriesTemplate.fillOpacity = 0.75;
  level1SeriesTemplate.hiddenInLegend = true;

  const level2SeriesTemplate = level0SeriesTemplate.clone();
  chart.seriesTemplates.setKey("2", level2SeriesTemplate);
  level2SeriesTemplate.fillOpacity = 0.5;
  level2SeriesTemplate.hiddenInLegend = true;

  chart.legend = new am4charts.Legend();
  chart.legend.position = "right";

  return (
    <div
      id="sunchartdiv"
      style={{ width: "100%", height: "500px" }}
      className="p40"
    />
  );
};

export default SunburstChart;
