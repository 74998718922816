import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Accordion,
  useAccordionToggle,
  Alert,
} from 'react-bootstrap';
import parse from 'html-react-parser';
import { validateImage } from '../utils/validationImagesCalls';
import '../styles/carga.css';
import '../styles/generic.css';
import '../styles/validation.css';

const ImageInfo = ({ imgInfo, imageValidated }) => {
  const accessToken = localStorage.getItem('accessToken');

  // State variables
  const [tag, setTag] = useState(imgInfo.tag);

  const [tagShown, setTagShown] = useState(imgInfo.tag);
  const [newTag, setNewTag] = useState({
    newTag: '',
  });

  const [warning, setWarning] = useState(false);

  const [buttonRedClass, setButtonRedClass] = useState({
    tag: 'round-button btn-red',
  });
  const [buttonGreenClass, setButtonGreenClass] = useState({
    tag: 'round-button btn-green',
  });
  const [labelClass, setLabelClass] = useState({
    tag: 'obj-info',
  });

  const [validateButton, setValidateButton] = useState(
    'btn-blue validation-button-small ftn11 text-center'
  );

  const handleNewTag = (e) => {
    setNewTag({
      ...newTag,
      [e.target.name]: e.target.value,
    });
  };

  const handleOriginal = (e) => {
    setLabelClass({
      ...labelClass,
      [e.target.name]: 'validated-box',
    });
    setButtonGreenClass({
      ...buttonGreenClass,
      [e.target.name]: 'round-button btn-gray',
    });
    setButtonRedClass({
      ...buttonRedClass,
      [e.target.name]: 'round-button btn-gray',
    });
    if (e.target.id === 'notOriginal') {
      console.log(newTag);
      if (newTag.newTag === 'No sé') {
        setTag('unknown');
      } else {
        setTag(newTag.newTag);
      }
      setTagShown(newTag.newTag);
      setTimeout(() => {
        document.getElementById(`${imgInfo.id}-button-red`).click();
      }, 10);
    }
  };

  function CustomToggle({ type_key, children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      //
    });

    return (
      <button
        id={`${imgInfo.id}-button-red`}
        type="button"
        className={buttonRedClass[type_key]}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const handleValidation = (e) => {
    console.log(tag);
    if (tag === null) {
      setWarning(true);
    } else {
      setWarning(false);
      setValidateButton('btn-gray validation-button-small ftn11 text-center');
      const validateImg = async () => {
        const response = await validateImage(imgInfo.id, tag, accessToken);
        if (response === 'OK') {
          console.log('Validated!');
          imageValidated(imgInfo.id);
        }
      };
      validateImg();
    }
  };

  const xmax = imgInfo.tag_location_percentage.xmax * 100;
  const xmin = imgInfo.tag_location_percentage.xmin * 100;
  const ymax = imgInfo.tag_location_percentage.ymax * 100;
  const ymin = imgInfo.tag_location_percentage.ymin * 100;

  const widthBox = xmax - xmin;
  const heightBox = ymax - ymin;
  // console.log(widthBox, heightBox, xmax, xmin, ymax, ymin);

  return (
    <Col id={imgInfo.id} md={12} sm={12} className="inline-label p0 pb35">
      <Form inline>
        <Col md={4} sm={12} className="p0 d-flex">
          <div>
            <div
              className="boxRed"
              style={{
                width: `${widthBox}%`,
                height: `${heightBox}%`,
                top: `${ymin}%`,
                left: `${xmin}%`,
              }}
            >
              {/* <p>Alto definido {altoDefinido}</p>
                            <p>Alto original {altoOriginal}</p>
                            <p>Ancho definido {anchoDefinido}</p>
                            <p> ancho real {anchoOriginal}</p> */}
            </div>
            <img id="imgP" src={imgInfo.image_url} className="image" />
          </div>
        </Col>

        <Col md={6} sm={12}>
          <p className="my-1 mr-2 inline-label ftn11">
            A continuación marca si el elemento detectado es correcto o
            incorrecto:
          </p>
          <Accordion className="pb10">
            <Card>
              <Col md={12} sm={12} inline>
                <Form inline>
                  <Col md={6} sm={12}>
                    <Card.Header className="p0">
                      <p className="font-medium txt_left pb10 ftn11">
                        Elemento detectado
                      </p>
                      <Row>
                        <Form.Group className="FilterContainer">
                          <div
                            id={`${imgInfo.id}-inst-og`}
                            className={labelClass.tag}
                          >
                            {tagShown}
                          </div>
                          {/* <Form.Control id="SearchQuery" className="inputTerms"  placeholder="Comercial" /> */}
                        </Form.Group>
                        <Form.Group className="inline-label pl10 moreButton pb10">
                          <button
                            name="tag"
                            type="button"
                            className={buttonGreenClass.tag}
                            onClick={handleOriginal}
                          >
                            <img
                              name="tag"
                              src="/img/icon-checkmark.svg"
                              className=""
                              width="100%"
                            />
                          </button>
                          <CustomToggle
                            type_key="tag"
                            className={buttonRedClass.tag}
                            eventKey="0"
                          >
                            <img
                              src="/img/icon-cross.svg"
                              className=""
                              width="100%"
                            />
                          </CustomToggle>
                        </Form.Group>
                      </Row>
                    </Card.Header>
                  </Col>

                  <Col md={10} sm={12}>
                    <Accordion.Collapse eventKey="0" className="mb10">
                      <Card.Body className="p0">
                        <p className="font-medium txt_left pb10 ftn11">
                          Ingresa la respuesta correcta
                        </p>
                        <Form inline>
                          <Form.Group className="FilterContainer pr0">
                            <Form.Group name="tag" onChange={handleNewTag}>
                              <input
                                placeholder="Escribe elemento aquí"
                                name="newTag"
                                className="obj-info"
                                onChange={handleNewTag}
                              />
                              <Form.Check
                                id="tag"
                                inline
                                label="No sé"
                                value="No sé"
                                type="radio"
                                name="newTag"
                                className="unknown"
                              />
                              <Form.Check
                                id="tag"
                                inline
                                label="No aplica"
                                value="No aplica"
                                type="radio"
                                name="newTag"
                              />
                            </Form.Group>
                          </Form.Group>
                        </Form>
                        <button
                          type="button"
                          id="notOriginal"
                          className="SearchButton ftn11"
                          onClick={handleOriginal}
                          name="tag"
                        >
                          Enviar respuesta
                        </button>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Col>
                </Form>
              </Col>
            </Card>
          </Accordion>
          <Accordion className="pb10 ml10">
            <Col>
              {warning ? (
                <Alert variant="warning" className={alert}>
                  Por favor valida todos los campos antes de enviar
                </Alert>
              ) : null}
              <button
                type="button"
                id="btnRegisterForm"
                className={validateButton}
                onClick={handleValidation}
                name="subcategoria"
              >
                Validar entidad
              </button>
            </Col>
          </Accordion>
        </Col>
      </Form>
    </Col>
  );
};

export default ImageInfo;
