// const url =
//   "https://dl6t5ecf86.execute-api.us-west-2.amazonaws.com/api/v3/ground_truth/organizations";

const url = `${process.env.REACT_APP_BACKEND_API_HOST}/api/v3/ground_truth/organizations`;

export async function getValidationOrgs(size, accessToken) {
  const response = await fetch(`${url}?size=${size}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}

export async function validateOrgs(
  textId,
  orgType,
  category,
  subcategory,
  accessToken
) {
  const response = await fetch(
    `${url}?text_id=${textId}&org_type=${orgType}&category=${category}&subcategory=${subcategory}`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    }
  );

  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    if (response.status === 422) {
      console.log("Error 422");
    }
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}

export async function stopOrgsValidation(entityIds, accessToken) {
  const response = await fetch(`${url}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
    body: JSON.stringify(entityIds),
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}
