import React from "react";
import { Navbar, Nav } from "react-bootstrap";

import "../../styles/navbar.css";
import AuthService from "../../services/AuthService";

const MainNav = () => {
  const Logout = (e) => {
    const auth = new AuthService();
    auth.lock.logout({
      returnTo: window.location.origin,
    });
  };

  const renderNav = () => {
    const views_info = {
      busqueda: {
        name: "Búsqueda",
        href: "/busqueda",
        blank: false,
      },
      insights: {
        name: "Insights",
        // 'href': 'https://viz.deepbitacora.com',
        href: "/newDashboard",
        blank: false,
      },
      networks: {
        name: "Networks",
        // 'href': 'https://viz.deepbitacora.com',
        href: "/network",
        blank: false,
      },
      validacion_documentos: {
        name: "Validación Documentos",
        href: "/validationProject",
        blank: false,
      },
      validacion_entidades: {
        name: "Validación Entidades",
        href: "/entities",
        blank: false,
      },
      validacion_imagenes: {
        name: "Validación Imágenes",
        href: "/validationImage",
        blank: false,
      },
    };
    const user = JSON.parse(localStorage.getItem("User"));

    // switch (user.usertype){
    // 	case 'antropologo':
    // 		return(
    // 			<Nav className="mr-auto">
    // 				<Nav.Link className="navbar-item font-medium text-center selected" href="#home">Proyectos</Nav.Link>
    // 			</Nav>
    // 		)
    // 	break;
    // 	case 'lider_proyecto':
    // 		return(
    // 			<Nav className="mr-auto">
    // 				<Nav.Link className="navbar-item font-medium text-center selected" href="#link">Buscar</Nav.Link>
    // 				{/*<Nav.Link className="navbar-item font-medium text-center" href="#link">Explorar</Nav.Link>*/}
    // 				<Nav.Link className="navbar-item font-medium text-center" href="https://viz.deepbitacora.com">Insights</Nav.Link>
    // 				<Nav.Link className="btn-nav font-medium text-center" href="https://deepdivedatascienceapi.typeform.com/to/xz0geIbK">REGISTRAR NUEVO PROYECTO</Nav.Link>
    // 				<Nav.Link className="navbar-item font-medium text-center" href="/validationEnty">Validación entidades</Nav.Link>
    // 			</Nav>
    // 		)
    // 	break;
    // }

    const links = [];
    user.views.forEach((view) => {
      const info = views_info[view.name];
      if (info.blank) {
        links.push(
          <Nav.Link
            key={view.name}
            className="navbar-item font-medium text-center"
            target="_blank"
            href={info.href}
          >
            {" "}
            {info.name}{" "}
          </Nav.Link>
        );
      } else {
        links.push(
          <Nav.Link
            key={view.name}
            className="navbar-item font-medium text-center"
            href={info.href}
          >
            {" "}
            {info.name}{" "}
          </Nav.Link>
        );
      }
    });
    return <Nav className="mr-auto">{links}</Nav>;
  };

  return (
    <Navbar expand="lg" className="nav-bitacora">
      <Navbar.Brand href="/welcome">
        <img src="/img/deep_bitacora-logo@2x.png" className="main-logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {renderNav()}
        <Nav.Link
          key="logout"
          className="navbar-item font-medium text-center"
          onClick={Logout}
        >
          Cerrar sesión
        </Nav.Link>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MainNav;
