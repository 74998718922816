/* eslint-disable import/prefer-default-export */
// const url = 'https://1astdjd11g.execute-api.us-west-2.amazonaws.com/api/v1/qa/';

const url = `${process.env.REACT_APP_BACKEND_VIZ_API_HOST}/api/v1/qa/`;

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept",
};

export async function getQa(term, question) {
  console.log("getting QA data!");
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(
    `${url}top_ten_qa?term_to_lookup=${term}&question=${question}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }
  );
  // if(response.status >= 400 && response.status < 600){
  //     console.log("Capture Error Token")
  //     window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
  //     window.location.href = '/';
  // }
  // else{
  //     return await response.json();
  // }
  return response.json();
}

export async function getQaLong(question) {
  const accessToken = localStorage.getItem("accessToken");
  const count = 0;
  const response = await fetch(`${url}top_ten_qa_beta?question=${question}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  });
  if (response.status !== 200 && count === 0) {
    setTimeout(() => {
      //Esperar antes de hacer fetch otra vez
      const retry = fetch(`${url}top_ten_qa_beta?question=${question}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ...headers,
        },
      });
      return retry.json();
    }, 2000);
  }
  return response.json();
}
