// const url =
//   'https://dl6t5ecf86.execute-api.us-west-2.amazonaws.com/api/v3/image_validation/images';

const url = `${process.env.REACT_APP_BACKEND_API_HOST}/api/v3/image_validation/images`;

export async function getValidationImages(size, accessToken) {
  const response = await fetch(`${url}?size=${size}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}

export async function validateImage(imageId, tag, accessToken) {
  const response = await fetch(`${url}?image_id=${imageId}&image_type=${tag}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}

export async function stopImageValidation(imageIds, accessToken) {
  const response = await fetch(`${url}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
    body: JSON.stringify(imageIds),
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}
