import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarCharEmotion1 = ({ labelsPositive, scoresPositive }) => {
  return (
    <div>
      <Bar
        data={{
          labels: labelsPositive,
          datasets: [
            {
              label: 'Comparativa de índice ',
              data: scoresPositive,
              backgroundColor: [
                '#57A3FF',
                '#57A3FF',
                '#57A3FF',
                '#57A3FF',
                '#57A3FF',
              ],
              borderWidth: 1,
            },
          ],
        }}
        height={300}
        width={400}
        options={{
          maintainAspectRatio: false,
        }}
      />
    </div>
  );
};
export default BarCharEmotion1;
