import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import ComponentWithState from 'components/ComponentWithState';
import TopTermsSentiment from 'components/dashboard/topSentimentTerms';
import TopEmotion from 'components/dashboard/topEmotion';

import SentimentChart from '../graphic/SentimentChart';
import Emotionmeter from '../graphic/Emotionmeter';

const emotions = ['felicidad', 'miedo', 'enojo', 'disgusto', 'tristeza'];
const colors = {
  felicidad: '#63B447',
  miedo: '#D77843',
  enojo: '#CB4641',
  disgusto: '#453477',
  tristeza: '#4282B4',
};

function EmotionalProfile({
  searchTerm,
  sentimentData,
  getSentimentData,
  topTermsSentiment,
  getTopTermsEmotionsData,
  emotionData,
  getEmotionMeterData,
  topTermsSpecific,
  getTermsSpecificEmotionData,
  emotionGraph,
  getEmotionGraphData,
}) {
  const [selectedEmotion, setSelectedEmotion] = useState(emotions[0]);

  useEffect(() => {
    getTermsSpecificEmotionData(searchTerm, selectedEmotion);
  }, [selectedEmotion]);

  return (
    <Col md={12} sm={12} className="contEmotion">
      <Row>
        <Col md={12} sm={12} className="pt25">
          <Row>
            <Col md={5} sm={12}>
              <div>
                <ComponentWithState
                  state={sentimentData}
                  reload={() => getSentimentData(searchTerm)}
                >
                  <div>
                    <p className="ftn13 pb10 txt_bold">
                      Top 10 términos en todo el universo de búsqueda relacionado al
                      término “{searchTerm}” comparando por sentimiento.
                      <Tooltip
                        arrow
                        title="Los diez términos más frecuentes dentro de la lista de párrafos en
                        que aparece el término buscado y que corresponden a un sentimiento
                        predominante. Mientras más cerca se encuentren del centro, los
                        términos se relacionan con el término de búsqueda de manera más
                        positiva."
                        placement="top"
                      >
                        <InfoIcon
                          style={{ width: 12, color: '#242424', marginLeft: 5 }}
                        />
                      </Tooltip>
                    </p>
                  </div>
                  <div className="d-flex alignCenter mt20">
                    <SentimentChart sentimentData2={sentimentData.data} />
                  </div>
                </ComponentWithState>
              </div>
            </Col>
            <ComponentWithState
              state={topTermsSentiment}
              reload={() => getTopTermsEmotionsData(searchTerm)}
            >
              <TopTermsSentiment
               topTermsSentiment={topTermsSentiment.data}
               searchTerm={searchTerm} />
            </ComponentWithState>
          </Row>
        </Col>

        <Col md={12} sm={12} className="pt25">
          <div>
            <p className="ftn13 pb10 txt_bold">Emotionmeter</p>
          </div>
          <Row>
            <Col md={12} sm={12}>
              <Row>
                <ComponentWithState
                  state={emotionData}
                  reload={() => getEmotionMeterData(searchTerm)}
                >
                  <Emotionmeter emotionData={emotionData.data} />
                </ComponentWithState>
              </Row>
            </Col>
            {/* <Col md={3} sm={12}>
                <p className="ftn15 pb10 txt_bold">El término “"Pago"” se asocia predominantemente con la emoción felicidad. 70% de las asociaciones de palabras están en un contexto donde se manifiesta esta emoción.</p>
            </Col> */}
          </Row>
        </Col>
        <TopEmotion
          topTermsSpecific={topTermsSpecific}
          searchTerm={searchTerm}
          selectedEmotion={selectedEmotion}
          setSelectedEmotion={setSelectedEmotion}
          emotions={emotions}
          getTermsSpecificEmotionData={getTermsSpecificEmotionData}
          color={colors[selectedEmotion]}
          emotionGraph={emotionGraph}
          getEmotionGraphData={getEmotionGraphData}
        />
      </Row>
    </Col>
  );
}

export default EmotionalProfile;
