// const url =
//   "https://dl6t5ecf86.execute-api.us-west-2.amazonaws.com/api/v3/validation";
// const url = "http://0.0.0.0:8000/api/v3/validation"
const url = `${process.env.REACT_APP_BACKEND_API_HOST}/api/v3/validation`;

export async function getValidationPaths(size, accessToken) {
  const response = await fetch(`${url}/paths?size=${size}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}

export async function getProjectsList(client, accessToken) {
  const full_url =
    client == null
      ? `${url}/list_projects`
      : `${url}/list_projects?client=${client}`;
  const response = await fetch(full_url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}

export async function getClientsList(project, accessToken) {
  const full_url =
    project == null
      ? `${url}/list_clients`
      : `${url}/list_clients?project=${project}`;
  console.log(full_url);
  const response = await fetch(full_url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}

export async function validatePath(pathId, pathInfo, accessToken) {
  const response = await fetch(`${url}/paths?path_id=${pathId}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
    body: JSON.stringify(pathInfo),
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}

export async function stopValidation(pathIds, accessToken) {
  const response = await fetch(`${url}/paths`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
    body: JSON.stringify(pathIds),
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}
