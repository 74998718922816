import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import styles from "styles/modules/newDashboard.module.scss";

const SpecificIntertopic = ({ intertopicData }) => {
  am4core.options.autoDispose = true;
  console.log("Intertopic Data!!", intertopicData);
  const categories = [];

  function getSize(i) {
    if (i === 0) {
      // Primer valor es topics relevantes por semántica
      // Segundo valor es size
      return [10, 800];
    }
    if (i === 1) {
      return [6, 600];
    }
    return [3, 300];
  }

  function getColor(token) {
    console.log("token", token);
    if (token === "Limpieza") {
      return "#3464AA";
    }
    if (token === "Recreación - Entretenimiento") {
      return "#A84188";
    }
    if (token === "Medios De Comunicación") {
      return "#E79C71";
    }
    if (token === "Finanzas") {
      return "#AAC869";
    }
    if (token === "Bebida") {
      return "#D95F56";
    }
    if (token === "Laboral" || token === "Trabajo") {
      return "#916085";
    }
    if (token === "Comercio") {
      return "#74A15F";
    }
    if (token === "Religión") {
      return "#DDB73E";
    }
    if (token === "Alimento") {
      return "#CC5950";
    }
    if (token === "Consumo") {
      return "#6BA0A5";
    }
    if (token === "Valores Y Cultura") {
      return "#B2A882";
    }
    if (token === "Seguridad") {
      return "#888FAA";
    }
    if (token === "Política Y Gobierno" || token === "Política y Gobierno") {
      return "#B78A8E";
    }
    if (token === "Transporte") {
      return "#8CAFA3";
    }
    if (token === "Vivienda") {
      return "#FFCE00";
    }
    if (token === "Relaciones Sociales") {
      return "#D96231";
    }
    if (token === "Tecnología") {
      return "#24A7E0";
    }
    if (token === "Electrodoméstico") {
      return "#D3E1BE";
    }
    if (token === "Familia") {
      return "#D43A71";
    }
    if (token === "Educación") {
      return "#A9D4E4";
    }
    if (token === "Deporte") {
      return "#70589C";
    }
    if (token === "Vestimenta") {
      return "#F7E491";
    }
    if (token === "Materiales") {
      return "#A7AFC1";
    }
    if (token === "Salud Y Cuidado" || token === "Salud y cuidado") {
      return "#F7E491";
    }
    return "otro color";
  }

  const data = [];
  intertopicData.map((semantic, index) => {
    if (index < 3) {
      categories.push(semantic.topic.trim());
      const size = getSize(index);
      semantic.tokens.map((token, i) => {
        if (i < size[0]) {
          data.push({
            name: token,
            value: size[1],
            color: getColor(semantic.topic.trim()),
          });
        }
      });
    }
  });

  useEffect(() => {
    console.log("Categories", categories);
  }, [categories]);

  // Create chart
  const amchart = am4core.create(
    "intertopic-chartdiv",
    am4plugins_forceDirected.ForceDirectedTree
  );
  // Create series
  const series = amchart.series.push(
    new am4plugins_forceDirected.ForceDirectedSeries()
  );
  // Set data

  series.data = data;

  // Set up data fields
  series.dataFields.value = "value";
  series.dataFields.name = "name";
  series.dataFields.children = "children";
  series.dataFields.color = "color";

  // Add labels
  series.nodes.template.label.text = "{name}";
  series.nodes.template.label.fill = "#000000";
  series.nodes.template.label.hideOversized = true;
  series.nodes.template.label.hoverOnFocus = true;
  series.nodes.template.showTooltipOn = "hover";

  series.nodes.template.tooltipText = "{name}";

  series.dataFields.fixed = "fixed";

  series.nodes.template.propertyFields.x = "600px";
  series.nodes.template.propertyFields.y = "150px";
  series.showOnInit = false;

  setTimeout(() => {
    series.nodes.template.propertyFields.x = "80%";
    series.nodes.template.propertyFields.y = "80%";
  }, 3000);

  useEffect(() => {
    series.nodes.template.propertyFields.x = "80%";
    series.nodes.template.propertyFields.y = "80%";
  }, [series.states]);

  series.fontSize = 10;
  series.minRadius = 15;
  series.maxRadius = 40;

  series.nodes.template.adapter.add("y", function (y, target) {
    const radius = target.circle.pixelRadius;
    if (y <= radius) {
      return radius;
    } else if (y >= amchart.pixelHeight - radius) {
      return amchart.pixelHeight - radius;
    }
    return y;
  });

  series.nodes.template.adapter.add("x", function (x, target) {
    const radius = target.circle.pixelRadius;
    if (x <= radius) {
      return radius;
    } else if (x >= amchart.pixelWidth - radius) {
      return amchart.pixelWidth - radius;
    }
    return x;
  });

  series.hiddenState.interpolationDuration = 0;
  series.hiddenState.animationDuration = 0;
  am4core.options.onlyShowOnViewport = true;

  return (
    <div style={{ width: "100%", height: "330px" }}>
      <div
        id="intertopic-chartdiv"
        style={{ width: "100%", height: "290px" }}
      />
      <Row>
        <Col xs={12} md={12} className={styles.topics}>
          <div className={styles.list}>
            {categories.map((category) => (
              <span className="pl10">
                <div className={styles.category} key={category}>
                  <span
                    style={{ backgroundColor: getColor(category) }}
                    className={styles.color}
                  />
                  <span className={styles.category}>{category}</span>
                </div>
              </span>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SpecificIntertopic;
