const apiUrl = `${process.env.REACT_APP_BACKEND_API_HOST}/api/v2/network/`;

export async function getDocsContexts(docs, accessToken) {
  let context;
  await fetch(`${apiUrl}get_docs_contexts`, {
    method: "POST",
    body: JSON.stringify({ docs_ids: docs }),
    headers: {
      Authorization: `Bearer ${accessToken.id_token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      context = data;
    });

  return context;
}

export async function getAllContexts(fullData, accessToken) {
  const allContexts = [];
  fullData.map((data) => {
    getDocsContexts(data.docs, accessToken).then((context) => {
      allContexts.push(context);
    });
    return "";
  });
  return allContexts;
}
