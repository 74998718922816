import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Searcher from "views/Searcher";
import Busqueda from "views/busqueda";
import newProyect from "views/newProyect";
import ValidProyect from "views/validProyect";
import validProyectUnitari from "views/validProyectUnitari";
import ValidationEntity from "views/validationEnty";
import Entities from "views/entities";
import Images from "views/images";
import Carga from "views/carga";
import Entregable from "views/entregable";
import Proyecto from "views/proyecto";
import ProyectoTabla from "views/protabla";
import Antropologo from "views/antropologo";
import Welcome from "views/welcome";
import Registro from "views/registro";
import NewDashboard from "views/newDashboard";
import Network from "views/network";

import Callback from "./callback";
import Login from "./components/Login";
import { UserProvider } from "./auth/authContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/app.css";

require("dotenv").config();

function App() {
  // if(localStorage.length === 0){
  //   window.location.href = history.push(`${window.location.origin}/login`);
  // }
  // if(window.location.origin.href !== `${window.location.origin}/`){
  //   window.location.href =`${window.location.origin}/`
  // }

  console.log("This is the ENV variable");
  console.log(process.env.REACT_APP_BACKEND_API_HOST);
  console.log(process.env.REACT_APP_BACKEND_VIZ_API_HOST);
  return (
    <div id="app" className="d-flex flex-column h-100">
      <UserProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/network" component={Network} />
            <Route path="/busqueda" component={Busqueda} />
            <Route path="/newProyect" component={newProyect} />
            <Route path="/validationProject" component={ValidProyect} />
            <Route
              path="/validProyectUnitari"
              component={validProyectUnitari}
            />
            <Route path="/validationEntity" component={ValidationEntity} />
            <Route path="/entities" component={Entities} />
            <Route path="/validationImage" component={Images} />
            <Route path="/carga" component={Carga} />
            <Route path="/entregable" component={Entregable} />
            <Route path="/proyecto" component={Proyecto} />
            <Route path="/proyectotabla" component={ProyectoTabla} />
            <Route path="/antropologo" component={Antropologo} />
            <Route path="/callback" component={Callback} />
            <Route path="/welcome" component={Welcome} />
            <Route path="/registro" component={Registro} />
            <Route path="/newDashboard" component={NewDashboard} />
            <Route path="/search" component={Searcher} />
          </Switch>
        </Router>
      </UserProvider>
      {/* <div id="LoaderContainer">
        <div id="Loader"><div className="lds-ripple"><div></div><div></div></div></div>
      </div> */}
    </div>
  );
}

export default App;
