import React from "react";
import { Form } from "react-bootstrap";

export default function GeneralSelect({ options, setSelected }) {
  return (
    <div>
      <Form.Label
        className="my-1 mr-2 inline-label"
        htmlFor="inlineFormCustomSelectPref"
      >
        Proyecto
      </Form.Label>
      <Form.Control
        id="ProjectName"
        as="select"
        className="my-1 mr-sm-2 selectProj"
        custom
        onChange={(e) => setSelected(e.target.value)}
      >
        {options.map((project) => (
          <option key={project} value={project}>
            {project}
          </option>
        ))}
      </Form.Control>
    </div>
  );
}
