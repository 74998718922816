import React from 'react';

import { Loading } from 'react-loading-dot';
import Button from '@material-ui/core/Button';

import styles from './styles.module.scss';

function ComponentWithState({ state, reload, children }) {
  if (state.loading) {
    return (
      <div className={styles.loader}>
        <p>Estamos buscando tus resultados 🔎</p>
        <Loading size="5px" background="black" margin="2px" />
      </div>
    );
  }

  if (state.error) {
    return (
      <div className={styles.error}>
        <p>Ups. Esto está tardando más de lo normal 😵.</p>
        <p>
          <Button color="primary" onClick={reload}>
            Haz click aquí
          </Button>
          para volver a cargar esta gráfica
        </p>
      </div>
    );
  }

  if (state.loaded) {
    return children;
  }

  return null;
}

export default ComponentWithState;
