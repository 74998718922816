// const url =
//   "https://dl6t5ecf86.execute-api.us-west-2.amazonaws.com/api/v3/ground_truth/products";

const url = `${process.env.REACT_APP_BACKEND_API_HOST}/api/v3/ground_truth/products`;

export async function getValidationProd(size, accessToken) {
  const response = await fetch(`${url}?size=${size}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}

export async function validateProd(textId, prodType, category, accessToken) {
  const response = await fetch(
    `${url}?text_id=${textId}&product_type=${prodType}&category=${category}`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    }
  );
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}

export async function stopProdValidation(productIds, accessToken) {
  const response = await fetch(`${url}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
    body: JSON.stringify(productIds),
  });
  if (response.status >= 400 && response.status < 600) {
    console.log("Capture Error Token");
    window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
    window.location.href = "/";
  } else {
    return await response.json();
  }
}
