import React, { useState, useEffect } from 'react';
// import topData from "../../data/top_10_terminos.json";
import { Row, Col, Carousel, Table } from 'react-bootstrap';

import clsx from 'clsx';

import styles from 'styles/modules/newDashboard.module.scss';
import 'styles/generic.css';

const ComponentQa = ({ qaLong, searchTerm, question }) => {
  function renderTexts() {
    const texts = [];
    qaLong.map((par, index) => {
      if (index < 6) {
        texts.push(
          <Col md={12} sm={12} className="back-gray borderR-15 mt20 p20">
          <div>
            <p className="ftn13 pl20 pr30 pt10">{par.text}</p>
          </div>
          </Col>
        )}
    });

    return texts;
  }

  return (
    <>
      <Row>
        <div>
          <p className="ftn13 p20 txt_bold">
            Algunos textos para responder la pregunta "{question}"
          </p>
        </div>
        {renderTexts()}
      </Row>

    </>
  );
};

export default ComponentQa;
