import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';

import { Row, Col } from 'react-bootstrap';
import LeftIcon from '@material-ui/icons/ArrowBackIosRounded';
import RightIcon from '@material-ui/icons/ArrowForwardIosRounded';
import IconButton from '@material-ui/core/IconButton';

import Context from 'components/Context';

import {
  getTopicBubbles,
  getTopicContexts,
} from 'utils/dashboardCalls/summaryCalls';

import styles from 'styles/modules/newDashboard.module.scss';
import Chart from './Chart';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  cursor: 'pointer',
};

export default function Intertopic({ searchTopic }) {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [bubbles, setBubbles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [contexts, setContexts] = useState([]);
  const [limits, setLimits] = useState({});
  const [sizes, setSizes] = useState({});
  const [loading, setLoading] = useState(false);

  const words = selectedTopic?.topic_id?.split(', ') || [];

  useEffect(() => {
    const getTopics = async () => {
      const response = await getTopicBubbles();
      if (response.bubbles) {
        setBubbles(response.bubbles);
        setCategories([
          ...response.bubbles.reduce(
            (acc, bubble) => acc.add(bubble.classification),
            new Set()
          ),
        ]);
        setColors([
          ...response.bubbles.reduce(
            (acc, bubble) => acc.add(`#${bubble.color}`),
            new Set()
          ),
        ]);
        setLimits({
          min: minBy(response.bubbles, 'position').position,
          max: maxBy(response.bubbles, 'position').position,
        });
        setSizes({
          min: minBy(response.bubbles, 'size').size,
          max: maxBy(response.bubbles, 'size').size,
        });
      }
    };

    getTopics();
  }, []);


  const onClickTopic = async ({ data, ...rest }) => {
    setLoading(true);
    setSelectedTopic({ ...data, color: rest.color });
    const response = await getTopicContexts(data.topic_id);
    setContexts(
      response.contexts.reduce(
        (rows, key, index) =>
          (index % 2 === 0
            ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows,
        []
      )
    );
    setLoading(false);
  };

  return (
    <Row>
      <Col xs={12} md={12} className={styles.dashboard}>
        {!!bubbles?.length && (
          <Chart
            bubbles={bubbles}
            categories={categories}
            onClickTopic={onClickTopic}
            limits={limits}
            sizes={sizes}
            colors={colors}
          />
        )}
      </Col>
      <Col xs={12} md={12} className={styles.topics}>
        <b>Semánticas generales:</b>
        <div className={styles.list}>
          {categories.map((description, index) => (
            <div className={styles.category} key={description}>
              <span
                style={{ backgroundColor: colors[index] }}
                className={styles.color}
              />
              <span className={styles.description}>{description}</span>
            </div>
          ))}
        </div>
      </Col>
      {!!words.length && (
        <div className={styles.selectedTopic}>
          <b>Palabras de la temática seleccionada:</b>
          <div>
            {words.map((word) => (
              <button
                type="button"
                className={styles.word}
                style={{ backgroundColor: selectedTopic.color }}
                onClick={() => searchTopic(word)}
                key={word}
              >
                {word}
              </button>
            ))}
          </div>
        </div>
      )}
      {loading && (
        <>
          <Col xs={12} md={6}>
            <Context loading={loading} color={selectedTopic.color} />
          </Col>
          <Col xs={12} md={6}>
            <Context loading={loading} color={selectedTopic.color} />
          </Col>
        </>
      )}
      {!!contexts.length && !loading && (
        <Carousel
          className={styles.carousel}
          renderIndicator={() => null}
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          emulateTouch
          renderArrowPrev={(onClickHandler, label) => (
            <IconButton
              onClick={onClickHandler}
              aria-label={label}
              style={{ ...arrowStyles, left: 0 }}
            >
              <LeftIcon />
            </IconButton>
          )}
          renderArrowNext={(onClickHandler, label) => (
            <IconButton
              onClick={onClickHandler}
              aria-label={label}
              style={{ ...arrowStyles, right: 0 }}
            >
              <RightIcon />
            </IconButton>
          )}
        >
          {contexts.map((context) => (
            <Row className={styles.row}>
              {context.map((item) => (
                <Col xs={12} md={6} key={item.topic_id}>
                  <Context
                    searchTopic={searchTopic}
                    data={{
                      topic: selectedTopic.classification,
                      description: item,
                      words: selectedTopic.topic_id,
                    }}
                    color={selectedTopic.color}
                  />
                </Col>
              ))}
            </Row>
          ))}
        </Carousel>
      )}
    </Row>
  );
}
