import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Accordion,
  useAccordionToggle,
  Alert,
} from 'react-bootstrap';
import parse from 'html-react-parser';

import '../styles/carga.css';
import '../styles/generic.css';
import '../styles/validation.css';
import { validateProd } from '../utils/validationProd';

const ProdInfo = ({ prodInfo, productValidated }) => {
  const accessToken = localStorage.getItem('accessToken');

  // State variables
  const [category, setCategory] = useState(prodInfo.category);
  const [showSubcategory, setShowSubcategory] = useState(false);
  const [subcategory, setSubcategory] = useState(null);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);

  const [categoryOpen, setCategoryOpen] = useState(false);
  const [subcategoryOpen, setSubcategoryOpen] = useState(false);

  const [warning, setWarning] = useState(false);

  const [buttonRedClass, setButtonRedClass] = useState({
    categoria: 'round-button btn-red',
    subcategoria: 'round-button btn-red',
  });
  const [buttonGreenClass, setButtonGreenClass] = useState({
    categoria: 'round-button btn-green',
    subcategoria: 'round-button btn-green',
  });
  const [labelClass, setLabelClass] = useState({
    categoria: 'obj-info-large',
    subcategoria: 'obj-info-large',
  });

  const handleOriginal = (e) => {
    setLabelClass({
      ...labelClass,
      [e.target.name]: 'validated-box',
    });
    setButtonGreenClass({
      ...buttonGreenClass,
      [e.target.name]: 'round-button btn-gray',
    });
    setButtonRedClass({
      ...buttonRedClass,
      [e.target.name]: 'round-button btn-gray',
    });
    if (e.target.id === 'notOriginal') {
      // Sin el timeout no cambia el color de los botones
      setTimeout(() => {
        document
          .getElementById(`${prodInfo.id}-${e.target.name}-button-red`)
          .click();
      }, 10);
    }
  };
  useEffect(() => {
    setCategoryOptions(Object.keys(prodInfo.options).sort());
    if (prodInfo.category == 'Alimentos') {
      setShowSubcategory(true);
      setSubcategoryOptions(prodInfo.options.Alimentos);
      setSubcategory(prodInfo.options.Alimentos[0]);
    } else {
      setSubcategory('No aplica');
    }
  }, []);

  useEffect(() => {
    if (category == 'Alimentos') {
      setShowSubcategory(true);
      setSubcategoryOptions(prodInfo.options.Alimentos);
      setSubcategory(prodInfo.options.Alimentos[0]);
    } else {
      setSubcategory('No aplica');
      setShowSubcategory(false);
    }
  }, [category]);

  function CustomToggle({ type_key, children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      if (eventKey === '0') {
        if (categoryOpen === false) {
          setCategoryOpen(true);
          setCategory(categoryOptions[0]);
        }
        console.log(categoryOpen);
        console.log('options', categoryOptions);
        // setCategory(categoryOptions[0])
        // setCategory("Accesorios Personales")
      }
      // else if (eventKey === "1"){
      //     setSubcategoryOpen(true);
      //     console.log(subcategoryOpen)
      // }
    });

    return (
      <button
        id={`${prodInfo.id}-${type_key}-button-red`}
        type="button"
        className={buttonRedClass[type_key]}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  function renderCategories() {
    const prodCat = [];
    categoryOptions.map((category) => {
      prodCat.push(
        <option value={category} key={category}>
          {category}
        </option>
      );
    });

    return prodCat;
  }

  function renderSubCategories() {
    const prodSubCat = [];
    subcategoryOptions.map((subcategory) => {
      prodSubCat.push(
        <option value={subcategory} key={subcategory}>
          {subcategory}
        </option>
      );
    });

    return prodSubCat;
  }

  const handleValidation = (e) => {
    console.log(category, subcategory);
    if (category === null || subcategory === null) {
      setWarning(true);
    } else {
      setWarning(false);
      const validateProduct = async () => {
        const response = await validateProd(
          prodInfo.id,
          category,
          subcategory,
          accessToken
        );
        if (response === 'OK') {
          console.log('Validated!');
          productValidated(prodInfo.id);
        }
      };
      validateProduct();
    }
  };

  // Poner en negritas texto del extracto o de la definición
  function renderExtract() {
    return <p className="ftn13">{parse(prodInfo.extract)}</p>;
  }
  function renderDefinition() {
    return (
      <p className="ftn13">
        <span className="font-medium">Definición: </span>
        {parse(prodInfo.description)}{' '}
      </p>
    );
  }

  return (
    <Col id={prodInfo.id} md={12} sm={12} className="inline-label p0 pb35">
      <Form inline>
        <Col md={6} sm={12} className="p0">
          <Col className="paragraph-text back-gray p20 borderR-15">
            <div>
              <p className="ftn13 font-medium">Extracto</p>
              {renderExtract()}
            </div>
            <div className="pt10">
              <p className="ftn13 font-medium">Producto: {prodInfo.text}</p>
              {renderDefinition()}
            </div>
          </Col>
        </Col>

        <Col md={6} sm={12}>
          <p className="my-1 mr-2 inline-label ftn11">
            A continuación marca si la categoría es correcta o incorrecta:
          </p>
          <Accordion className="pb10">
            <Card>
              <Col md={12} sm={12} inline>
                <Form inline>
                  <Col md={6} sm={12}>
                    <Card.Header className="p0">
                      <p className="font-medium txt_left pb10 ftn11">
                        Tipo de Producto
                      </p>
                      <Row>
                        <Form.Group className="FilterContainer">
                          <div
                            id={`${prodInfo.id}-inst-og`}
                            className={labelClass.categoria}
                          >
                            {category}
                          </div>
                          {/* <Form.Control id="SearchQuery" className="inputTerms"  placeholder="Comercial" /> */}
                        </Form.Group>
                        <Form.Group className="inline-label pl10 moreButton pb10">
                          <button
                            name="categoria"
                            type="button"
                            className={buttonGreenClass.categoria}
                            onClick={handleOriginal}
                          >
                            <img
                              name="categoria"
                              src="/img/icon-checkmark.svg"
                              className=""
                              width="100%"
                            />
                          </button>
                          <CustomToggle
                            type_key="categoria"
                            className={buttonRedClass.categoria}
                            eventKey="0"
                          >
                            <img
                              src="/img/icon-cross.svg"
                              className=""
                              width="100%"
                            />
                          </CustomToggle>
                        </Form.Group>
                      </Row>
                    </Card.Header>
                  </Col>

                  <Col md={6} sm={12} className="pt10">
                    <Accordion.Collapse eventKey="0" className="answer">
                      <Card.Body className="p0">
                        <p className="font-medium txt_left pb10 ftn11">
                          Selecciona la opción correcta
                        </p>
                        <Form inline>
                          <Form.Group className="FilterContainer pr0">
                            <Form.Group className="FilterContainer pr0">
                              <Form.Control
                                id="categoria"
                                name="categoria"
                                as="select"
                                className="my-1 mr-sm-2 selectProj pr0"
                                custom
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                {renderCategories()}
                              </Form.Control>
                            </Form.Group>
                          </Form.Group>
                        </Form>
                        <button
                          type="button"
                          id="notOriginal"
                          className="SearchButton ftn11"
                          onClick={handleOriginal}
                          name="categoria"
                        >
                          Enviar respuesta
                        </button>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Col>
                </Form>
              </Col>
            </Card>
          </Accordion>
          {showSubcategory ? (
            <Accordion className="pb10">
              <Card>
                <Col md={12} sm={12} inline>
                  <Form inline>
                    <Col md={6} sm={12}>
                      <Card.Header className="p0">
                        <p className="font-medium txt_left pb10 ftn11">
                          Subcategoria
                        </p>
                        <Row>
                          <Form.Group className="FilterContainer">
                            <div
                              id={`${prodInfo.id}-inst-og`}
                              className={labelClass.subcategoria}
                            >
                              {subcategory}
                            </div>
                            {/* <Form.Control id="SearchQuery" className="inputTerms"  placeholder="Servicios" /> */}
                          </Form.Group>
                          <Form.Group className="inline-label pl10 moreButton pb10">
                            <button
                              name="subcategoria"
                              type="button"
                              className={buttonGreenClass.subcategoria}
                              onClick={handleOriginal}
                            >
                              <img
                                name="subcategoria"
                                src="/img/icon-checkmark.svg"
                                className=""
                                width="100%"
                              />
                            </button>
                            <CustomToggle
                              type_key="subcategoria"
                              className="btn-red"
                              eventKey="1"
                            >
                              <img
                                src="/img/icon-cross.svg"
                                className=""
                                width="100%"
                              />
                            </CustomToggle>
                          </Form.Group>
                        </Row>
                      </Card.Header>
                    </Col>

                    <Col md={6} sm={12} className="pt10">
                      <Accordion.Collapse eventKey="1" className="answer">
                        <Card.Body className="p0">
                          <p className="font-medium txt_left pb10 ftn11">
                            Selecciona la opción correcta
                          </p>
                          <Form inline>
                            <Form.Group className="FilterContainer pr0">
                              <Form.Control
                                id="subcategoria"
                                name="subcategoria"
                                as="select"
                                className="my-1 mr-sm-2 selectProj pr0"
                                custom
                                onChange={(e) => setSubcategory(e.target.value)}
                              >
                                {renderSubCategories()}
                              </Form.Control>
                            </Form.Group>
                          </Form>
                          <button
                            type="button"
                            id="notOriginal"
                            className="SearchButton ftn11"
                            onClick={handleOriginal}
                            name="subcategoria"
                          >
                            Enviar respuesta
                          </button>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Col>
                  </Form>
                </Col>
              </Card>
            </Accordion>
          ) : null}

          <Accordion className="pb10">
            <Col>
              {warning ? (
                <Alert variant="warning" className={alert}>
                  Por favor valida todos los campos antes de enviar
                </Alert>
              ) : null}
              <button
                type="button"
                id="btnRegisterForm"
                className="btn-blue validation-button-small ftn11 text-center"
                onClick={handleValidation}
                name="subcategoria"
              >
                Validar entidad
              </button>
            </Col>
          </Accordion>
        </Col>
      </Form>
    </Col>
  );
};

export default ProdInfo;
