import React from 'react';
import clsx from 'clsx';
import {
  Col,
  Form,
  Card,
  Accordion,
  useAccordionToggle,
} from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';

import image from 'img/insights.png';

import styles from 'styles/modules/newDashboard.module.scss';
import 'styles/carga.css';
import 'styles/generic.css';
import 'styles/validation.css';

const SearchBar = ({ getSummary, register, watch }) => {
  const search = () => {
    const { searchQuery } = watch();
    getSummary(searchQuery);
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
      console.log('totally custom!')
    );

    return (
      <button
        type="button"
        className="filterButton d-flex"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  function DateAndTimePickers() {
    return (
      <TextField
        id="date"
        type="date"
        defaultValue="2021-04-05"
        className="TextField"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  return (
    <Col md={12} sm={12} className={clsx('p0', styles.wrapper)}>
      <img src={image} alt="Imagen busqueda" width="100%" />
      <Accordion className={styles.search}>
        <Card>
          <Card.Header className={clsx('form-inline', styles.header)}>
            <Col md={12} sm={12}>
              <h4
                className="busqueda-title font-medium txt_random txt_left"
                id="searchTitle"
              >
                O inicia tu búsqueda con un término
              </h4>
            </Col>
            <Col md={4} sm={12} className="searchTerm d-flex">
              <Form.Control
                onKeyDown={({ key }) => key === 'Enter' && search()}
                className="inputTerms"
                placeholder="Término de búsqueda"
                {...register('searchQuery')}
              />
            </Col>

            <Col md={1} sm={12} className="form-inline justCont-flexE">
              <button
                type="button"
                id="SearchButton"
                className="SearchButton"
                onClick={search}
              >
                Buscar
              </button>
            </Col>
          </Card.Header>
        </Card>
      </Accordion>
    </Col>
  );
};

export default SearchBar;
