import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import max from 'lodash/max';
import { Row, Col } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import TopTerms from 'components/dashboard/topTerms';
import RelatedOverview from 'components/dashboard/relatedOverview';
import RelatedTerms from 'components/dashboard/RelatedTerms';
import ComponentWithState from 'components/ComponentWithState';
import styles from 'styles/modules/newDashboard.module.scss';
import SpecificIntertopic from '../graphic/intertopic';
import DoughnutChar from '../graphic/DoughnutChar';

function Overview({
  searchTerm,
  intertopic,
  getIntertopicData,
  semantics,
  getSemanticsData,
  topTenTerms,
  getTopTermsData,
  relation,
  getRelationData,
  wordCloud,
  getWordCloudData,
  register,
  watch,
}) {

  const [contextOutside, setContextOutside] = useState(null)
  

  return (
    <Col md={12} sm={12} className="contSummary">
      <Row>
        <Col md={6} sm={12} className={clsx(styles.section, 'pt25')}>
          <p className="ftn13 pb10 txt_bold">
            Temáticas con las que se relaciona “{searchTerm}”
            <Tooltip arrow title="Esta visualización nos muestra las temáticas acotando los resultados a solo aquellas donde nuestro término fue encontrado. Las temáticas son agrupaciones de palabras que fueron usadas en el mismo contexto por lo cuál se les designó como un grupo de palabras que son usadas en circunstancias similares."  placement="top">
              <InfoIcon
                style={{ width: 12, color: '#242424', marginLeft: 5 }}
              />
            </Tooltip>
          </p>
          <ComponentWithState
            state={intertopic}
            reload={() => getIntertopicData(searchTerm)}
          >
            <SpecificIntertopic intertopicData={intertopic.data}/>
          </ComponentWithState>
        </Col>
        <Col md={6} sm={12} className={clsx(styles.section, 'pt25')}>
          <p className="ftn13 pb10 txt_bold">
            Semánticas generales con las que se relaciona “{searchTerm}”
            <Tooltip
              arrow
              title="Esta gráfica muestra las clasificaciones semánticas más frecuentes
              para los párrafos en donde se encuentra el término buscado. El
              porcentaje mostrado es la proporción de textos en que dicha
              clasificación semántica aparece con respecto al total de parráfos en
              donde se encuentra el término buscado."
              placement="top"
            >
              <InfoIcon
                style={{ width: 12, color: '#242424', marginLeft: 5 }}
              />
            </Tooltip>
          </p>
          <ComponentWithState
            state={semantics}
            reload={() => getSemanticsData(searchTerm)}
          >
            <DoughnutChar semanticData={semantics.data?.result} />
          </ComponentWithState>
        </Col>
        <TopTerms
          topData={topTenTerms}
          searchTerm={searchTerm}
          reload={() => getTopTermsData(searchTerm)}
        />
        <Col md={6} sm={12} className={clsx(styles.section, 'pt25')}>
          <div>
            <p className="ftn13 pb10 txt_bold">
              Wordcloud - universo de términos relacionados
              <Tooltip arrow title="En esta visualización buscamos identificar el universo de palabras relacionadas y poder determinar la semántica a la que pertenece esta palabra así como la relevancia que tiene en relación a nuestro término buscado, a mayor tamaño, más veces fueron empleados en conjunto." placement="top">
                <InfoIcon
                  style={{ width: 12, color: '#242424', marginLeft: 5 }}
                />
              </Tooltip>
            </p>
          </div>
          <RelatedTerms
            wordCloud={wordCloud}
            getWordCloudData={getWordCloudData}
            term={searchTerm}
          />
        </Col>
        <Col className={clsx(styles.section, 'pt25')}>
          <div>
            <p className="ftn13 pb10 txt_bold">
              Related terms - Búsqueda de término relacionado 
              <Tooltip arrow title="Un sub-buscador donde se puede ingresar un término específico a comparar. Esta ficha de información nos permite comparar los términos de interés directamente, dándonos volumen de asociaciones, temáticas en común y contextos que ejemplifican su uso en conjunto." placement="top">
                <InfoIcon
                  style={{ width: 12, color: '#242424', marginLeft: 5 }}
                />
              </Tooltip>
            </p>
          </div>
          <RelatedOverview
            relation={relation}
            searchTerm={searchTerm}
            getRelationData={getRelationData}
            register={register}
            watch={watch}
            setContextOutside={setContextOutside}
          />
        </Col>
        <Col md={12} sm={12} >
          {contextOutside}
        </Col>
      </Row>
    </Col>

    // <Col md={12} sm={12} className="pt25">
    //   <Row>
    //     <Col md={6} sm={12}>
    //       <div>
    //         <p className="ftn13 pb10 txt_bold pb40">
    //           Top 5 términos relacionados con "Pago" - Por demográfico
    //         </p>
    //       </div>
    //       <div>
    //         <Row className="p10">
    //           <Col md={4} sm={12}>
    //             <p className="ftn11 pb10">
    //               El género femenino es con el que mas se encuentra esta
    //               relación.
    //             </p>
    //           </Col>
    //           <Col md={8} sm={12}>
    //             <div className="contTermsLine d-flex">
    //               <div
    //                 className="backColor1 txt-center ftn11 txt_white"
    //                 style={{ width: '90%', height: '100%' }}
    //               >
    //                 90%
    //               </div>
    //             </div>
    //           </Col>

    //           <Col md={4} sm={12}>
    //             <p className="ftn11 pb10">
    //               El grupo de edad en el que más se encuentra esta relación es
    //               el de 47 a 53 años
    //             </p>
    //           </Col>
    //           <Col md={8} sm={12}>
    //             <div className="contTermsLine d-flex">
    //               <div
    //                 className="backColor2 txt-center ftn11 txt_white"
    //                 style={{ width: '60%', height: '100%' }}
    //               >
    //                 60%
    //               </div>
    //               <div
    //                 className="backColor3 txt-center ftn11 txt_white"
    //                 style={{ width: '20%', height: '100%' }}
    //               >
    //                 20%
    //               </div>
    //               <div
    //                 className="backColor4 txt-center ftn11 txt_white"
    //                 style={{ width: '10%', height: '100%' }}
    //               >
    //                 10%
    //               </div>
    //               <div
    //                 className="backColor5 txt-center ftn11 txt_white"
    //                 style={{ width: '5%', height: '100%' }}
    //               >
    //                 5%
    //               </div>
    //               <div
    //                 className="backColor6 txt-center ftn11 txt_white"
    //                 style={{ width: '5%', height: '100%' }}
    //               >
    //                 5%
    //               </div>
    //             </div>
    //           </Col>

    //           <Col md={4} sm={12}>
    //             <p className="ftn11 pb10">
    //               Este término tiene mayor incidencia en el NSE y C: Media
    //               típica.
    //             </p>
    //           </Col>
    //           <Col md={8} sm={12}>
    //             <div className="contTermsLine d-flex">
    //               <div
    //                 className="backColor7 txt-center ftn11 txt_white"
    //                 style={{ width: '70%', height: '100%' }}
    //               >
    //                 70%
    //               </div>
    //               <div
    //                 className="backColor8 txt-center ftn11 txt_white"
    //                 style={{ width: '20%', height: '100%' }}
    //               >
    //                 20%
    //               </div>
    //               <div
    //                 className="backColor9 txt-center ftn11 txt_white"
    //                 style={{ width: '10%', height: '100%' }}
    //               >
    //                 10%
    //               </div>
    //             </div>
    //           </Col>

    //           <Col md={4} sm={12}>
    //             <p className="ftn11 pb10">
    //               Este término tiene una asociación de sentimiento: Neutral
    //             </p>
    //           </Col>
    //           <Col md={8} sm={12}>
    //             <div className="contTermsLine d-flex">
    //               <div
    //                 className="backColor4 txt-center ftn11 txt_white"
    //                 style={{ width: '30%', height: '100%' }}
    //               >
    //                 30%
    //               </div>
    //               <div
    //                 className="backColor10 txt-center ftn11 txt_white"
    //                 style={{ width: '60%', height: '100%' }}
    //               >
    //                 60%
    //               </div>
    //               <div
    //                 className="backColor2 txt-center ftn11 txt_white"
    //                 style={{ width: '10%', height: '100%' }}
    //               >
    //                 10%
    //               </div>
    //             </div>
    //           </Col>

    //           <Col md={4} sm={12}>
    //             <p className="ftn11 pb10">
    //               Este término tiene una asociación con la emoción: felicidad..
    //             </p>
    //           </Col>
    //           <Col md={8} sm={12}>
    //             <div className="contTermsLine d-flex">
    //               <div
    //                 className="backColor11 txt-center ftn11 txt_white"
    //                 style={{ width: '50%', height: '100%' }}
    //               >
    //                 50%
    //               </div>
    //               <div
    //                 className="backColor12 txt-center ftn11 txt_white"
    //                 style={{ width: '10%', height: '100%' }}
    //               >
    //                 10%
    //               </div>
    //               <div
    //                 className="backColor10 txt-center ftn11 txt_white"
    //                 style={{ width: '40%', height: '100%' }}
    //               >
    //                 40%
    //               </div>
    //             </div>
    //           </Col>

    //           <Col md={4} sm={12}>
    //             <p className="ftn11 pb10">
    //               Temática con la que más se relaciona: Comunicación - medios de
    //               comunicación.
    //             </p>
    //           </Col>
    //           <Col md={8} sm={12}>
    //             <div className="contTermsTematic d-flex ftn11">
    //               <Row className="p10">
    //                 <Col md={12} sm={12}>
    //                   <p className="">
    //                     Revista, Tv Notas, estética, chisme, noticias.
    //                   </p>
    //                 </Col>
    //                 <Col md={6} sm={12}>
    //                   <p>Size: 239.</p>
    //                 </Col>
    //                 <Col md={6} sm={12} className="txt-end">
    //                   <a className="linkT linkContext" href="">
    //                     Ver contexto
    //                   </a>
    //                 </Col>
    //               </Row>
    //             </div>
    //           </Col>
    //         </Row>
    //       </div>
    //     </Col>
    //     <Col md={6} sm={12} className="contNewDashboard">
    //       <div>
    //         <p className="ftn13 pb10 txt_bold">Contexto de: Revista</p>
    //       </div>
    //       <Row>
    //         <Col md={6} sm={12}>
    //           <Carousel>
    //             <Carousel.Item className="p40">
    //               <div className="contSlider h300">
    //                 <p>01/01/2001</p>
    //                 <p>Temática: Comunicación</p>
    //                 <p>NSE: Medio típico</p>
    //                 <p>
    //                   “Todos los días me gusta darle una hojeada a mis revistas
    //                   favoritas, ya sé que es muy de señora vieja pero mi "Pago"
    //                   siempre lo hacía así que se me quedó la costumbre”. Marisa
    //                   hojeaba su TV Novelas mientras sonreía. “Ya sé que son
    //                   puros chismes y luego hasta se los inventan pero uno se
    //                   entera de cosas ya sabes”.
    //                 </p>
    //               </div>
    //               <Carousel.Caption />
    //             </Carousel.Item>
    //             <Carousel.Item className="p40">
    //               <div className="contSlider h300">
    //                 <p>01/01/2001</p>
    //                 <p>Temática: Comunicación</p>
    //                 <p>NSE: Medio típico</p>
    //                 <p>
    //                   “Todos los días me gusta darle una hojeada a mis revistas
    //                   favoritas, ya sé que es muy de señora vieja pero mi "Pago"
    //                   siempre lo hacía así que se me quedó la costumbre”. Marisa
    //                   hojeaba su TV Novelas mientras sonreía. “Ya sé que son
    //                   puros chismes y luego hasta se los inventan pero uno se
    //                   entera de cosas ya sabes”.
    //                 </p>
    //               </div>
    //               <Carousel.Caption />
    //             </Carousel.Item>
    //             <Carousel.Item className="p40">
    //               <div className="contSlider h300">
    //                 <p>01/01/2001</p>
    //                 <p>Temática: Comunicación</p>
    //                 <p>NSE: Medio típico</p>
    //                 <p>
    //                   “Todos los días me gusta darle una hojeada a mis revistas
    //                   favoritas, ya sé que es muy de señora vieja pero mi "Pago"
    //                   siempre lo hacía así que se me quedó la costumbre”. Marisa
    //                   hojeaba su TV Novelas mientras sonreía. “Ya sé que son
    //                   puros chismes y luego hasta se los inventan pero uno se
    //                   entera de cosas ya sabes”.
    //                 </p>
    //               </div>
    //               <Carousel.Caption />
    //             </Carousel.Item>
    //           </Carousel>
    //         </Col>
    //         <Col md={6} sm={12}>
    //           <Carousel>
    //             <Carousel.Item className="p40">
    //               <div className="contSlider h300">
    //                 <img src="./img/revista.jpg" className="p10" width="100%" />
    //               </div>
    //               <Carousel.Caption />
    //             </Carousel.Item>
    //             <Carousel.Item className="p40">
    //               <div className="contSlider p10 h300">
    //                 <img src="./img/revista.jpg" className="p10" width="100%" />
    //               </div>
    //               <Carousel.Caption />
    //             </Carousel.Item>
    //             <Carousel.Item className="p40">
    //               <div className="contSlider p10 h300">
    //                 <img src="./img/revista.jpg" className="p10" width="100%" />
    //               </div>
    //               <Carousel.Caption />
    //             </Carousel.Item>
    //           </Carousel>
    //         </Col>
    //       </Row>
    //     </Col>
    //   </Row>
    // </Col>
  );
}

export default Overview;
