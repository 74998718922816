import React, { useState, createRef } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Accordion,
  useAccordionToggle,
  Modal,
} from 'react-bootstrap';
import parse from 'html-react-parser';
import Dropzone from 'react-dropzone';
import MainNav from '../components/ui/navbar';
import '../styles/busqueda.css';
import '../styles/generic.css';

const validProyectUnitari = () => {
  const access_token = JSON.parse(localStorage.getItem('auth0'));
  if (!access_token) {
    window.location.href = '/';
  }

  // Limpiar el localstorage de busquedas pasadads
  /*
    localStorage.removeItem('num_pages_terms');
    localStorage.removeItem('current_page_terms');
    localStorage.removeItem('search_after_terms');
    localStorage.removeItem('total_results_terms');
    localStorage.removeItem('last_index_terms');
    localStorage.removeItem('num_pages_documents');
    localStorage.removeItem('current_page_documents');
    localStorage.removeItem('search_after_documents');
    localStorage.removeItem('total_results_documents');
    */

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
      console.log('totally custom!')
    );

    return (
      <button
        type="button"
        className="filterButton btn-red"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  return (
    <Container fluid>
      <MainNav />
      <Row>
        <Container fluid>
          <Col className="">
            <Col className="pb50">
              <h4 className="section-title font-medium txt_random txt_left pb25">
                Validación de Documentos
              </h4>
              <Form inline>
                <Col md={4} sm={12}>
                  <p className="my-1 mr-2 inline-label font-medium ftn15">
                    Ejemplo - Portada de documento dentro de la carpeta
                  </p>
                  <div className="img-doc" sm={12}>
                    <img src="/img/img-verific.png" className="img-fluid" />
                  </div>
                </Col>
                <Col md={8} sm={12}>
                  <Form inline>
                    <Col md={4} sm={12} className="pb10">
                      <p className="my-1 mr-2 inline-label font-medium ftn15">
                        Fecha de creación
                      </p>
                      <div id="DateCreation" className="inputValid text-center">
                        19/04/2021
                      </div>
                    </Col>
                    <Col md={8} sm={12} className="pb10">
                      <p className="my-1 mr-2 inline-label font-medium ftn15">
                        Ubicación de la Carpeta
                      </p>
                      <div id="DateCreation" className="inputValid">
                        2021 > Consumo > Lácteos Lala > Joaquín
                      </div>
                    </Col>
                    <Col md={12} sm={12} className="inline-label">
                      <Accordion className="pt25 pb10">
                        <Card>
                          <p className="my-1 mr-2 inline-label ftn11">
                            A continuación marca si el NSEyC detectado es
                            correcto o incorrecto:
                          </p>
                          <Row>
                            <Col md={6} sm={12}>
                              <Card.Header className="p0">
                                <Row>
                                  <Form.Group className="FilterContainer">
                                    <Form.Control
                                      id="SearchQuery"
                                      className="inputTerms"
                                      placeholder="Organización educativa"
                                    />
                                  </Form.Group>
                                  <Form.Group className="inline-label pl10 moreButton pb10">
                                    <button
                                      type="button"
                                      className="pl10 pr10 filterButton btn-green"
                                    >
                                      <img
                                        src="/img/icon-checkmark.svg"
                                        className=""
                                        width="100%"
                                      />
                                    </button>
                                    <CustomToggle
                                      className="btn-red"
                                      eventKey="0"
                                    >
                                      <img
                                        src="/img/icon-cross.svg"
                                        className=""
                                        width="100%"
                                      />
                                    </CustomToggle>
                                  </Form.Group>
                                </Row>
                              </Card.Header>
                            </Col>

                            <Col md={6} sm={12} className="pt10">
                              <Accordion.Collapse eventKey="0">
                                <Card.Body className="p0">
                                  <p className="font-medium txt_left pb10 ftn11">
                                    Ingresa la respuesta correcta
                                  </p>
                                  <Form inline>
                                    <Form.Group className="FilterContainer">
                                      <Form.Group className="FilterContainer">
                                        <Form.Control
                                          id="ProjectName"
                                          as="select"
                                          className="my-1 mr-sm-2 selectProj"
                                          custom
                                        >
                                          <option value="">Todos</option>
                                          <option value="">
                                            Campo Nescaf&eacute;
                                          </option>
                                          <option value="">
                                            Proyecto econom&iacute;a
                                          </option>
                                        </Form.Control>
                                      </Form.Group>
                                    </Form.Group>
                                    <Form.Group className="inline-label float-r">
                                      <button
                                        id="btnRegisterForm"
                                        className="SearchButton ftn11"
                                        href=""
                                      >
                                        Enviar respuesta
                                      </button>
                                    </Form.Group>
                                  </Form>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Col>
                          </Row>
                        </Card>
                      </Accordion>
                    </Col>
                    <Col md={12} sm={12} className="inline-label">
                      <Accordion className="pt25 pb10">
                        <Card>
                          <p className="my-1 mr-2 inline-label ftn11">
                            A continuación marca si el estado detectado es
                            correcto:
                          </p>
                          <Row>
                            <Col md={6} sm={12}>
                              <Card.Header className="p0">
                                <Row>
                                  <Form.Group className="FilterContainer">
                                    <Form.Control
                                      id="SearchQuery"
                                      className="inputTerms"
                                      placeholder="Organización educativa"
                                    />
                                  </Form.Group>
                                  <Form.Group className="inline-label pl10 moreButton pb10">
                                    <button
                                      type="button"
                                      className="pl10 pr10 filterButton btn-green"
                                    >
                                      <img
                                        src="/img/icon-checkmark.svg"
                                        className=""
                                        width="100%"
                                      />
                                    </button>
                                    <CustomToggle
                                      className="btn-red"
                                      eventKey="0"
                                    >
                                      <img
                                        src="/img/icon-cross.svg"
                                        className=""
                                        width="100%"
                                      />
                                    </CustomToggle>
                                  </Form.Group>
                                </Row>
                              </Card.Header>
                            </Col>

                            <Col md={6} sm={12} className="pt10">
                              <Accordion.Collapse eventKey="0">
                                <Card.Body className="p0">
                                  <p className="font-medium txt_left pb10 ftn11">
                                    Ingresa la respuesta correcta
                                  </p>
                                  <Form inline>
                                    <Form.Group className="FilterContainer">
                                      <Form.Group className="FilterContainer">
                                        <Form.Control
                                          id="ProjectName"
                                          as="select"
                                          className="my-1 mr-sm-2 selectProj"
                                          custom
                                        >
                                          <option value="">Todos</option>
                                          <option value="">
                                            Campo Nescaf&eacute;
                                          </option>
                                          <option value="">
                                            Proyecto econom&iacute;a
                                          </option>
                                        </Form.Control>
                                      </Form.Group>
                                    </Form.Group>
                                    <Form.Group className="inline-label float-r">
                                      <button
                                        id="btnRegisterForm"
                                        className="SearchButton ftn11"
                                        href=""
                                      >
                                        Enviar respuesta
                                      </button>
                                    </Form.Group>
                                  </Form>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Col>
                          </Row>
                        </Card>
                      </Accordion>
                    </Col>
                    <Col md={12} sm={12} className="inline-label">
                      <Accordion className="pt25 pb10">
                        <Card>
                          <p className="my-1 mr-2 inline-label ftn11">
                            A continuación marca si la ciudad detectada es
                            correcta:
                          </p>
                          <Row>
                            <Col md={6} sm={12}>
                              <Card.Header className="p0">
                                <Row>
                                  <Form.Group className="FilterContainer">
                                    <Form.Control
                                      id="SearchQuery"
                                      className="inputTerms"
                                      placeholder="Organización educativa"
                                    />
                                  </Form.Group>
                                  <Form.Group className="inline-label pl10 moreButton pb10">
                                    <button
                                      type="button"
                                      className="pl10 pr10 filterButton btn-green"
                                    >
                                      <img
                                        src="/img/icon-checkmark.svg"
                                        className=""
                                        width="100%"
                                      />
                                    </button>
                                    <CustomToggle
                                      className="btn-red"
                                      eventKey="0"
                                    >
                                      <img
                                        src="/img/icon-cross.svg"
                                        className=""
                                        width="100%"
                                      />
                                    </CustomToggle>
                                  </Form.Group>
                                </Row>
                              </Card.Header>
                            </Col>

                            <Col md={6} sm={12} className="pt10">
                              <Accordion.Collapse eventKey="0">
                                <Card.Body className="p0">
                                  <p className="font-medium txt_left pb10 ftn11">
                                    Ingresa la respuesta correcta
                                  </p>
                                  <Form inline>
                                    <Form.Group className="FilterContainer">
                                      <Form.Group className="FilterContainer">
                                        <Form.Control
                                          id="ProjectName"
                                          as="select"
                                          className="my-1 mr-sm-2 selectProj"
                                          custom
                                        >
                                          <option value="">Todos</option>
                                          <option value="">
                                            Campo Nescaf&eacute;
                                          </option>
                                          <option value="">
                                            Proyecto econom&iacute;a
                                          </option>
                                        </Form.Control>
                                      </Form.Group>
                                    </Form.Group>
                                    <Form.Group className="inline-label float-r">
                                      <button
                                        id="btnRegisterForm"
                                        className="SearchButton ftn11"
                                        href=""
                                      >
                                        Enviar respuesta
                                      </button>
                                    </Form.Group>
                                  </Form>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Col>
                          </Row>
                        </Card>
                      </Accordion>
                    </Col>
                    <Col md={12} sm={12} className="inline-label">
                      <Accordion className="pt25 pb10">
                        <Card>
                          <p className="my-1 mr-2 inline-label ftn11">
                            A continuación marca si el proyecto detectado es
                            correcto:
                          </p>
                          <Row>
                            <Col md={6} sm={12}>
                              <Card.Header className="p0">
                                <Row>
                                  <Form.Group className="FilterContainer">
                                    <Form.Control
                                      id="SearchQuery"
                                      className="inputTerms"
                                      placeholder="Organización educativa"
                                    />
                                  </Form.Group>
                                  <Form.Group className="inline-label pl10 moreButton pb10">
                                    <button
                                      type="button"
                                      className="pl10 pr10 filterButton btn-green"
                                    >
                                      <img
                                        src="/img/icon-checkmark.svg"
                                        className=""
                                        width="100%"
                                      />
                                    </button>
                                    <CustomToggle
                                      className="btn-red"
                                      eventKey="0"
                                    >
                                      <img
                                        src="/img/icon-cross.svg"
                                        className=""
                                        width="100%"
                                      />
                                    </CustomToggle>
                                  </Form.Group>
                                </Row>
                              </Card.Header>
                            </Col>

                            <Col md={6} sm={12} className="pt10">
                              <Accordion.Collapse eventKey="0">
                                <Card.Body className="p0">
                                  <p className="font-medium txt_left pb10 ftn11">
                                    Ingresa la respuesta correcta
                                  </p>
                                  <Form inline>
                                    <Form.Group className="FilterContainer">
                                      <Form.Group className="FilterContainer">
                                        <Form.Control
                                          id="ProjectName"
                                          as="select"
                                          className="my-1 mr-sm-2 selectProj"
                                          custom
                                        >
                                          <option value="">Todos</option>
                                          <option value="">
                                            Campo Nescaf&eacute;
                                          </option>
                                          <option value="">
                                            Proyecto econom&iacute;a
                                          </option>
                                        </Form.Control>
                                      </Form.Group>
                                    </Form.Group>
                                    <Form.Group className="inline-label float-r">
                                      <button
                                        id="btnRegisterForm"
                                        className="SearchButton ftn11"
                                        href=""
                                      >
                                        Enviar respuesta
                                      </button>
                                    </Form.Group>
                                  </Form>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Col>
                          </Row>
                        </Card>
                      </Accordion>
                    </Col>
                  </Form>
                </Col>
              </Form>
            </Col>
          </Col>
        </Container>
      </Row>
    </Container>
  );
};

export default validProyectUnitari;
