import React from 'react';
import { Container } from 'react-bootstrap';
import MainNav from '../components/ui/navbar';
import '../styles/welcome.css';

const Welcome = () => {
  const access_token = JSON.parse(localStorage.getItem('auth0'));
  if (!access_token) {
    window.location.href = '/';
  }

  return (
    <Container fluid>
      <div className="full-width">
        <MainNav />
      </div>
      <div id="bg-principal" className="p0 bg-welcome full-width">
        <div id="bg-shadow" className="bg-shadow pb230 pt230" />
      </div>
    </Container>
  );
};

export default Welcome;
