// const url = 'https://1astdjd11g.execute-api.us-west-2.amazonaws.com/api/v1';

const url = `${process.env.REACT_APP_BACKEND_VIZ_API_HOST}/api/v1`;

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept",
};

export async function getIntertopic(term) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(
    `${url}/intertopic/intertopic?term_to_lookup=${term}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }
  );
  // if(response.status >= 400 && response.status < 600){
  //     console.log("Capture Error Token")
  //     window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
  //     window.location.href = '/';
  // }
  // else{
  //     return await response.json();
  // }
  return response.json();
}

export async function getTopTerms(term) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(
    `${url}/overview/top_ten_terms?term_to_lookup=${term}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }
  );
  // if(response.status >= 400 && response.status < 600){
  //     console.log("Capture Error Token")
  //     window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
  //     window.location.href = '/';
  // }
  // else{
  //     return await response.json();
  // }
  return response.json();
}

export async function getRelation(termOne, termTwo) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(
    `${url}/overview/relation_overview?term_one=${termOne}&term_two=${termTwo}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }
  );
  // if(response.status >= 400 && response.status < 600){
  //     console.log("Capture Error Token")
  //     window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
  //     window.location.href = '/';
  // }
  // else{
  //     return await response.json();
  // }
  return response.json();
}
