import Auth0Lock from "auth0-lock";
import logo from "../img/deep_bitacora-logo@2x.png";

let token = "";

export default class AuthService {
  constructor() {
    this.lock = new Auth0Lock(
      "1ZjWa3EEvn5jsdM1ha8Lo6aorLfo5LrH",
      "dev-47j1zc3vrisylpo7.us.auth0.com",
      {
        auth: {
          audience: "https://dev-47j1zc3vrisylpo7.us.auth0.com/api/v2/",
          responseType: "token id_token",
          redirectUrl: `${window.location.origin}/callback`,
          autoParseHash: true,
          params: {
            scope: "openid profile email",
          },
        },
        // Conections for the future use ['google-oauth2', facebook]
        allowedConnections: ["Username-Password-Authentication"],
        allowShowPassword: true,
        initialScreen: "login",
        theme: {
          logo,
          primaryColor: "#2893C0",
        },
        language: "es",
        languageDictionary: {
          title: "Bienvenido",
        },
        closable: false,
        autoclose: true,
      }
    );
    this.lock.on("authenticated", this.doAuthentication);
    this.lock.on("authorization_error", this.doSomethingElse);
  }

  doSomethingElse = (error) => {
    console.log("HERE", error);
  };

  doAuthentication = async (authResult) => {
    console.log("entra");
    console.log(authResult);

    if (authResult !== undefined) {
      token = authResult;
      const auth = {
        access_token: token.accessToken,
        id_token: token.idToken,
      };
      let user = {};
      const auth0 = JSON.stringify(auth);
      localStorage.setItem("auth0", auth0);
      localStorage.setItem("accessToken", token.accessToken);

      console.log("ALL AUTH RESULT ==>", token);
      console.log("AUTH RESULT ==>", token.accessToken);

      async function GetUser() {
        console.log("getting user");
        console.log(process.env.REACT_APP_BACKEND_API_HOST);
        // await fetch('https://8onn4y7yhd.execute-api.us-west-2.amazonaws.com/api/v3/user', {
        await fetch(`${process.env.REACT_APP_BACKEND_API_HOST}/api/v3/user`, {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        })
          .then((response) => {
            console.log("endpoint response");
            return response.json();
          })
          .then((data) => {
            console.log("data ===>", data);
            user = {
              id: data.id,
              username: data.username,
              email: data.email,
              usertype: data.usertype,
              answered_typeform: data.answered_typeform,
              views: data.views,
            };
            console.log(user);
            const saveUser = JSON.stringify(user);
            console.log(saveUser);
            localStorage.setItem("User", saveUser);
            if (
              window.location.origin.href !==
              `${window.location.origin}/callback`
            ) {
              window.location.href = `${window.location.origin}/callback`;
            }
          });
      }
      GetUser();
    }
  };

  getProfile = () => JSON.parse(localStorage.getItem("profile"));

  login = (language) => {
    this.lock.show({ language });
  };

  loggedIn = () => !!this.getToken();

  setToken = (idToken) => {
    localStorage.setItem("id_token", idToken);
  };

  getToken = () => localStorage.getItem("id_token");

  logout = () => {
    localStorage.removeItem("id_token");
  };
}
