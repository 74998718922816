import React from 'react';
import { Container, Row, Col, Table, Form } from 'react-bootstrap';
import MainNav from '../components/ui/navbar';
import '../styles/proyecto.css';

const Antropologo = () => {
  console.log('test');

  return (
    <Container fluid>
      <Row>
        <MainNav />
        <Container>
          <Col md={12} sm={12}>
            <h4 className="lider-title font-medium">
              Busca archivos por proyecto
            </h4>
          </Col>
          <Row>
            <Col md={12} sm={12} className="form-container">
              <Form inline>
                <Col md={3} sm={12}>
                  <Form.Control placeholder="Todos" />
                </Col>
                <Col md={2} sm={12}>
                  <Form.Label
                    className="my-1 mr-2 inline-label"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Cliente
                  </Form.Label>
                  <Form.Control as="select" className="my-1 mr-sm-2" custom>
                    <option value="0">Todos</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Control>
                </Col>
                <Col md={2} sm={12}>
                  <Form.Label
                    className="my-1 mr-2 inline-label"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Tipo
                  </Form.Label>
                  <Form.Control as="select" className="my-1 mr-sm-2" custom>
                    <option value="0">Todos</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Control>
                </Col>
                <Col md={2} sm={12}>
                  <Form.Label
                    className="my-1 mr-2 inline-label"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Estatus
                  </Form.Label>
                  <Form.Control as="select" className="my-1 mr-sm-2" custom>
                    <option value="0">Todos</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Control>
                </Col>
                <Col md={3} sm={12}>
                  <Form.Group className="form-inline">
                    <Form.Label
                      className="my-1 mr-2 inline-label"
                      htmlFor="inlineFormCustomSelectPref"
                    >
                      Fecha
                    </Form.Label>
                    <Form.Control placeholder="dd/mm/aaaa" />
                  </Form.Group>
                </Col>
              </Form>
            </Col>
          </Row>
          <Row className="table-container">
            <Table
              bordered
              hover
              responsive
              className="table-base busqueda-table"
            >
              <thead>
                <tr>
                  <th className="font-medium text-left table-head-item" />
                  <th className="font-medium text-left table-head-item" />
                  <th className="font-medium text-left table-head-item" />
                  <th className="font-medium text-left table-head-item">
                    Estatus
                  </th>
                  <th className="font-medium text-left table-head-item">
                    Nombre de doc
                  </th>
                  <th className="font-medium text-left table-head-item">
                    Tipo de doc
                  </th>
                  <th className="font-medium text-left table-head-item">
                    Proyecto
                  </th>
                  <th className="font-medium text-left table-head-item">
                    Cliente
                  </th>
                  <th className="font-medium text-left table-head-item">
                    Fecha
                  </th>
                  <th className="font-medium text-left table-head-item">
                    Hora
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="table-row-item">
                  <td className="cell-item text-center">
                    <Form.Check aria-label="option 1" />
                  </td>
                  <td className="cell-item">
                    <a>
                      <img src="/img/download_blue.svg" className="img-icon" />
                    </a>
                  </td>
                  <td className="cell-item">
                    <a>
                      <img src="/img/question.svg" className="img-icon" />
                    </a>
                  </td>
                  <td className="cell-item">En correccion</td>
                  <td className="cell-item">Juanita Medio T</td>
                  <td className="cell-item">Etnografía</td>
                  <td className="cell-item">Transporte urbano</td>
                  <td className="cell-item">Italika</td>
                  <td className="cell-item">01/01/2021</td>
                  <td className="cell-item">14:47</td>
                </tr>
                <tr className="table-row-item">
                  <td className="cell-item text-center">
                    <Form.Check aria-label="option 1" />
                  </td>
                  <td className="cell-item">
                    <a>
                      <img src="/img/download_blue.svg" className="img-icon" />
                    </a>
                  </td>
                  <td className="cell-item">
                    <a>
                      <img src="/img/question.svg" className="img-icon" />
                    </a>
                  </td>
                  <td className="cell-item">En revision</td>
                  <td className="cell-item">Fernando Ramirez</td>
                  <td className="cell-item">Etnografía</td>
                  <td className="cell-item">Transporte urbano</td>
                  <td className="cell-item">Italika</td>
                  <td className="cell-item">01/01/2021</td>
                  <td className="cell-item">14:47</td>
                </tr>
                <tr className="table-row-item">
                  <td className="cell-item text-center">
                    <Form.Check aria-label="option 1" />
                  </td>
                  <td className="cell-item">
                    <a>
                      <img src="/img/download_blue.svg" className="img-icon" />
                    </a>
                  </td>
                  <td className="cell-item">
                    <a>
                      <img src="/img/question.svg" className="img-icon" />
                    </a>
                  </td>
                  <td className="cell-item">Entregado</td>
                  <td className="cell-item">Juanita Medio T</td>
                  <td className="cell-item">Etnografía</td>
                  <td className="cell-item">Transporte urbano</td>
                  <td className="cell-item">Italika</td>
                  <td className="cell-item">01/01/2021</td>
                  <td className="cell-item">14:47</td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Antropologo;
