import React, { useState, createRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Accordion,
  useAccordionToggle,
  Modal,
  Table,
} from "react-bootstrap";
import parse from "html-react-parser";
import Dropzone from "react-dropzone";
import MainNav from "../components/ui/navbar";
import "../styles/carga.css";
import "../styles/generic.css";
import "../styles/validation.css";

const ValidationEntity = () => {
  const access_token = JSON.parse(localStorage.getItem("auth0"));
  const size = 15;
  const [textData, setTextData] = useState([]);
  const [numValidated, setNumValidated] = useState(0);

  if (!access_token) {
    window.location.href = "/";
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
      console.log("totally custom!")
    );

    return (
      <button
        type="button"
        className="round-button btn-red"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  async function getTextEntities() {
    console.log("fetching text entities!!!!");
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API_HOST}/api/v3/ground_truth/text?size=${size}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token.access_token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      }
    );
    const data = await response.json();
    setTextData(data);
  }

  async function validateTextEntity(entity_id, opt) {
    console.log("Validating: ", entity_id);
    document.getElementById(entity_id).classList.add("is-validated");
    await fetch(
      `${process.env.REACT_APP_BACKEND_API_HOST}/api/v3/ground_truth/text?text_id=${entity_id}&classification=${opt}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${access_token.access_token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      }
    );
    setNumValidated(numValidated + 1);
  }

  function validateTextFromDropDown(entity_id) {
    const dd_id = `dd-${entity_id}`;
    const opt = document.getElementById(dd_id).value;
    validateTextEntity(entity_id, opt);
  }

  function renderTextEntities() {
    if (numValidated == size) {
      console.log("validated total!!");
      setNumValidated(0);
      setTextData([]);
    } else if (textData.length == 0) {
      getTextEntities();
    }

    const entities = [];
    textData.forEach((entity) => {
      const options = [];
      entity.options.forEach((op) => {
        options.push(<option value={op}>{op}</option>);
      });

      entities.push(
        <Row className="mt20" id={entity.id}>
          <Col md={8} sm={12}>
            <Col className="paragraph-text back-gray p20 borderR-15">
              <p className="ftn13">{parse(entity.description)}</p>
            </Col>
          </Col>
          <Col md={4} sm={12} className="validationText_filter">
            <p className="txt_left pb10 ftn11">
              A continuación marca si la categoría es correcta o incorrecta
            </p>
            <Accordion>
              <Card>
                <Card.Header className="p0">
                  <Form inline>
                    <Form.Group className="FilterContainer">
                      <div className="obj-info">{entity.classification}</div>
                    </Form.Group>
                    <Form.Group className="inline-label">
                      <button
                        type="button"
                        className="round-button btn-green"
                        onClick={(e) =>
                          validateTextEntity(entity.id, entity.classification)
                        }
                      >
                        <img
                          src="/img/icon-checkmark.svg"
                          className=""
                          width="100%"
                        />
                      </button>
                      <CustomToggle
                        className="btn-red round-button"
                        eventKey="0"
                      >
                        <img
                          src="/img/icon-cross.svg"
                          className=""
                          width="100%"
                        />
                      </CustomToggle>
                    </Form.Group>
                  </Form>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="p0">
                    <p className="font-medium txt_left pb10 ftn11">
                      Selecciona la opción correcta
                    </p>
                    <Form inline>
                      <Form.Group className="FilterContainer">
                        <Form.Control
                          id={`dd-${entity.id}`}
                          as="select"
                          className="my-1 mr-sm-2 selectProj"
                          custom
                        >
                          {options}
                        </Form.Control>
                      </Form.Group>
                      <button
                        type="button"
                        className="pl10 pr10 send-button btn-green"
                        onClick={(e) => validateTextFromDropDown(entity.id)}
                      >
                        Enviar respuesta
                      </button>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      );
    });

    return entities;
  }

  return (
    <Container fluid>
      <MainNav />
      <Row>
        <Container fluid>
          <Col md={12} sm={12} className="validationText pb50">
            <Container fluid>
              <h4 className="section-title font-medium txt_random txt_left pb10">
                Validación de Texto
              </h4>
              <Row>
                <Col md={8} sm={12}>
                  <p className="font-medium txt_random txt_left pb10">
                    Extracto
                  </p>
                </Col>
                <Col md={4} sm={12} className="validationText_filter">
                  <p className="font-medium txt_random txt_left pb10">
                    Categoría
                  </p>
                </Col>
              </Row>
              {renderTextEntities()}
            </Container>
          </Col>

          {/* <Col md={12} sm={12} className="validationImage">
                        <Container fluid>
                            <h4 className="section-title font-medium txt_random txt_left pb10">Validación de Imagen</h4>
                            <Row>
                                <Col md={4} sm={12}>
                                    <p className="font-medium txt_random txt_left pb10">Imagen</p>
                                </Col>
                                <Col md={8} sm={12} className="pl5p">
                                    <p className="font-medium txt_random txt_left">Detección de elemento</p>
                                    <p className="txt_random txt_left pb10 ftn11">A continuación marca si la detección del elemento es correcta o incorrecta.</p>
                                </Col>
                            </Row>
                            <Row className="pb10">
                                <Col md={4} sm={12}>
                                    <img id="filterBtn" src="/img/img-comprob.png" className="" width="100%"/>
                                </Col>
                                <Col md={8} sm={12} className="pl5p">
                                    <Accordion className="pt10p pb5p">
                                        <Card>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                    <Card.Header className="p0">
                                                        <Form inline>
                                                            <Form.Group className="FilterContainer">
                                                                <Form.Control id="SearchQuery" className="inputTerms"  placeholder="Organización educativa" />
                                                            </Form.Group>
                                                            <Form.Group className="inline-label pl10 moreButton pb10">
                                                                <button type="button" className="pl10 pr10 filterButton btn-green ml10" >
                                                                    <img src="/img/icon-checkmark.svg" className="" width="100%"/>
                                                                </button>
                                                                <CustomToggle className="btn-red" eventKey="0"><img src="/img/icon-cross.svg" className="" width="100%"/></CustomToggle>
                                                            </Form.Group>
                                                        </Form>
                                                    </Card.Header>
                                                </Col>
                                                <Col  md={6} sm={12}>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body className="p0">
                                                            <p className="font-medium txt_left pb10 ftn11">Ingresa la respuesta correcta</p>
                                                            <Form inline>
                                                                <Form.Group className="FilterContainer">
                                                                    <Form.Group className="FilterContainer">
                                                                        <Form.Control id="correctOption" className="inputTerms"  placeholder="" />
                                                                    </Form.Group>    
                                                                </Form.Group>
                                                                <Form.Group className="inline-label float-r">
                                                                    <button id="btnRegisterForm" className="SearchButton ftn11" href="">Enviar respuesta</button>
                                                                </Form.Group>
                                                            </Form>

                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Col>                                               
                                            </Row>
                                        </Card>
                                    </Accordion>
                              
                                </Col>
                            </Row>
                            <Row className="pb10">
                                <Col md={4} sm={12}>
                                    <img id="filterBtn" src="/img/img-comprob.png" className="" width="100%"/>
                                </Col>
                                <Col md={8} sm={12} className="pl5p">
                                    <Accordion className="pt10p pb5p">
                                        <Card>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                    <Card.Header className="p0">
                                                        <Form inline>
                                                            <Form.Group className="FilterContainer">
                                                                <Form.Control id="SearchQuery" className="inputTerms"  placeholder="Organización educativa" />
                                                            </Form.Group>
                                                            <Form.Group className="inline-label pl10 moreButton pb10">
                                                                <button type="button" className="pl10 pr10 filterButton btn-green ml10" >
                                                                    <img src="/img/icon-checkmark.svg" className="" width="100%"/>
                                                                </button>
                                                                <CustomToggle className="btn-red" eventKey="0"><img src="/img/icon-cross.svg" className="" width="100%"/></CustomToggle>
                                                            </Form.Group>
                                                        </Form>
                                                    </Card.Header>
                                                </Col>
                                                <Col  md={6} sm={12}>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body className="p0">
                                                            <p className="font-medium txt_left pb10 ftn11">Selecciona la opción correcta</p>
                                                            <Form inline>
                                                                <Form.Group className="FilterContainer">
                                                                    <Form.Group className="FilterContainer">
                                                                        <Form.Control id="correctOption" className="inputTerms"  placeholder="" />
                                                                    </Form.Group>    
                                                                </Form.Group>
                                                                <Form.Group className="inline-label float-r">
                                                                    <button id="btnRegisterForm" className="SearchButton ftn11" href="">Enviar respuesta</button>
                                                                </Form.Group>
                                                            </Form>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Col>                                               
                                            </Row>
                                               
                                        </Card>
                                    </Accordion>
                              
                                </Col>
                            </Row>
                            <Row className="pb10">
                                <Col md={4} sm={12}>
                                    <img id="filterBtn" src="/img/img-comprob.png" className="" width="100%"/>
                                </Col>
                                <Col md={8} sm={12} className="pl5p">
                                    <Accordion className="pt10p pb5p">
                                        <Card>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                    <Card.Header className="p0">
                                                        <Form inline>
                                                            <Form.Group className="FilterContainer">
                                                                <Form.Control id="SearchQuery" className="inputTerms"  placeholder="Organización educativa" />
                                                            </Form.Group>
                                                            <Form.Group className="inline-label pl10 moreButton pb10">
                                                                <button type="button" className="pl10 pr10 filterButton btn-green ml10" >
                                                                    <img src="/img/icon-checkmark.svg" className="" width="100%"/>
                                                                </button>
                                                                <CustomToggle className="btn-red" eventKey="0"><img src="/img/icon-cross.svg" className="" width="100%"/></CustomToggle>
                                                            </Form.Group>
                                                        </Form>
                                                    </Card.Header>
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body className="p0">
                                                            <p className="font-medium txt_left pb10 ftn11">Selecciona la opción correcta</p>
                                                            <Form inline>
                                                                <Form.Group className="FilterContainer">
                                                                    <Form.Group className="FilterContainer">
                                                                        <Form.Control id="correctOption" className="inputTerms"  placeholder="" />
                                                                    </Form.Group>    
                                                                </Form.Group>
                                                                <Form.Group className="inline-label float-r">
                                                                    <button id="btnRegisterForm" className="SearchButton ftn11" href="">Enviar respuesta</button>
                                                                </Form.Group>
                                                            </Form>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Col>                                               
                                            </Row>
                                        </Card>
                                    </Accordion>
                                </Col>
                            </Row>
                                   
                        </Container>
                    </Col> */}
        </Container>
      </Row>
    </Container>
  );
};

export default ValidationEntity;
