import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';

import {
  getValidationPaths,
  getProjectsList,
  getClientsList,
  stopValidation,
} from '../utils/validationCalls';
import DocumentInfo from '../components/DocumentInfo';
import MainNav from '../components/ui/navbar';
import '../styles/generic.css';
import '../styles/validation.css';

const ValidProyect = () => {
  const size = 2;
  const accessToken = localStorage.getItem('accessToken');
  const access_token = JSON.parse(localStorage.getItem('auth0'));

  const [pathsData, setPathsData] = useState([]);
  const [fullProjects, setFullProjects] = useState([]);
  const [fullClients, setFullClients] = useState([]);
  const [numValidated, setNumValidated] = useState(0);

  const [showSpinner, setShowSpinner] = useState(false);

  if (!access_token) {
    window.location.href = '/';
  }

  useEffect(() => {
    const fetchData = async () => {
      console.log('fetching projects');
      let storageData = JSON.parse(localStorage.getItem('projectsData'));
      if (storageData) {
        setFullProjects(storageData);
      } else {
        const projects = await getProjectsList(null, accessToken);
        setFullProjects(projects);
        localStorage.setItem('projectsData', JSON.stringify(projects));
      }

      console.log('fetching clients');
      storageData = JSON.parse(localStorage.getItem('clientsData'));
      if (storageData) {
        setFullClients(storageData);
      } else {
        const clients = await getClientsList(null, accessToken);
        setFullClients(clients);
        localStorage.setItem('clientsData', JSON.stringify(clients));
      }

      console.log('fetching data');
      storageData = JSON.parse(localStorage.getItem('pathsData'));
      if (storageData) {
        setPathsData(storageData);
      } else {
        const paths = await getValidationPaths(size, accessToken);
        setPathsData(paths);
        localStorage.setItem('pathsData', JSON.stringify(paths));
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const loadPaths = async () => {
      const paths = await getValidationPaths(size, accessToken);
      setPathsData(paths);
      localStorage.setItem('pathsData', JSON.stringify(paths));
    };

    if (numValidated == size) {
      console.log('validated all the documents!!!');
      loadPaths();
    }
  }, [numValidated]);

  function pathValidated(pathId) {
    console.log('Path validated!!');
    document.getElementById(pathId).classList.add('is-validated');
    setNumValidated(numValidated + 1);
  }

  function endReview() {
    const pathIds = [];
    pathsData.forEach((path) => {
      pathIds.push(path.id);
    });

    stopValidation(pathIds, accessToken).then((response) => {
      setShowSpinner(true);
      localStorage.removeItem('pathsData');
      window.location.href = '/welcome';
    });
  }

  function renderPaths() {
    console.log('Render paths');
    const paths = [];

    pathsData.forEach((path) => {
      paths.push(
        <DocumentInfo
          key={path.id}
          pathInfo={path}
          fullProjects={fullProjects}
          fullClients={fullClients}
          pathValidated={pathValidated}
        />
      );
    });

    return paths;
  }

  return (
    <Container fluid>
      <MainNav />
      <Row>
        <Container fluid>
          <Col className="">
            <Col>
              <h4 className="section-title font-medium txt_random txt_left pb25">
                Validación de Documentos
              </h4>
            </Col>
            {renderPaths()}
          </Col>
          <Form.Group className="validation-form">
            {showSpinner ? (
              <div className="spinner-border text-success" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <button
                id="endValidation"
                className="btn-blue validation-button font-medium text-center"
                onClick={(e) => endReview()}
              >
                Finalizar validación
              </button>
            )}
          </Form.Group>
        </Container>
      </Row>
    </Container>
  );
};

export default ValidProyect;
