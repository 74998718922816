import React, { useState, useEffect } from "react";
// import topData from "../../data/top_10_terminos.json";
import { Row, Col, Carousel } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Highlighter from "react-highlight-words";

import parse from "html-react-parser";
import clsx from "clsx";

import ComponentWithState from "components/ComponentWithState";
import styles from "styles/modules/newDashboard.module.scss";
//import style from './styles.module.scss';
import style from "components/Context/styles.module.scss";
import BarChar from "../../views/graphic/BarChar";

const TopTerms = ({ topData, searchTerm, reload }) => {
  const [labels, setLabels] = useState();
  const [scores, setScores] = useState();
  const [showContext, setShowContext] = useState(false);
  const [contextIndex, setContextIndex] = useState([]);
  const [otherTerm, setOtherTerm] = useState();

  useEffect(() => {
    if (topData.loaded) {
      const labelsData = [];
      const scoresData = [];
      topData.data.map((top) => {
        labelsData.push(top.term);
        scoresData.push(top.score);
      });
      setLabels(labelsData);
      setScores(scoresData);
    }
  }, [topData.loaded]);

  const colors = [
    "#3464AA",
    "#A84188",
    "#E79C71",
    "#AAC869",
    "#D95F56",
    "#FFCE00",
    "#D96231",
    "#D43A71",
    "#70589C",
    "#24A7E0",
  ];

  const handleContext = (e) => {
    setShowContext(true);
    setContextIndex(topData.data[e.target.id].context);
    setOtherTerm(topData.data[e.target.id].term);
  };

  function renderComparative() {
    const comparative_first = [];
    const comparative_second = [];

    if (topData.loaded) {
      topData.data.map((top, index) => {
        const classIndex = `col-11 p0 pt10 contComparative borderB${(
          index + 1
        ).toString()}`;
        if (index < 5) {
          comparative_first.push(
            <div className="d-flex">
              <Col md={1} sm={1} className="p0 col-1">
                <p className="fnt20 txt_bold pt10"> {index + 1}.</p>
              </Col>
              <Col md={11} sm={11} className={classIndex}>
                <Col md={3} className="ftn11">
                  {" "}
                  {top.term}{" "}
                </Col>
                <Col md={4} className="ftn11">
                  Score: {top.score.toFixed(2)}{" "}
                </Col>
                <Col md={6} className="ftn11 p0">
                  <button
                    type="button"
                    id={index}
                    className="btn-white context-button-small ftn11 text-center w100p p0"
                    onClick={handleContext}
                  >
                    Ver contexto
                  </button>
                </Col>
              </Col>
            </div>
          );
        } else {
          comparative_second.push(
            <div className="d-flex">
              <Col md={1} sm={1} className="p0">
                <p className="fnt20 txt_bold pt10"> {index + 1}.</p>
              </Col>
              <Col md={11} sm={11} className={classIndex}>
                <Col md={4} className="ftn11">
                  {" "}
                  {top.term}{" "}
                </Col>
                <Col md={4} className="ftn11">
                  Score: {top.score.toFixed(2)}{" "}
                </Col>
                <Col md={4} className="ftn11 p0">
                  <button
                    type="button"
                    id={index}
                    className="btn-white context-button-small ftn11 text-center w100p p0"
                    onClick={handleContext}
                  >
                    Ver contexto
                  </button>
                </Col>
              </Col>
            </div>
          );
        }
      });
    }

    return {
      first: comparative_first,
      second: comparative_second,
    };
  }

  function renderContext() {
    const context = [];
    console.log("length of context: ");
    console.log(contextIndex.length);
    contextIndex.map((currentContext, index) => {
      if (index < 11) {
        context.push(
          <Carousel.Item className="p40">
            <Row>
              <Col md={12} sm={12} className="pb10">
                <div className="contSlider h200">
                  <p>
                    <b>Ciudad: </b>
                    {contextIndex[index].info.ciudad
                      ? contextIndex[index].info.ciudad
                      : ""}
                  </p>
                  <p>
                    <b>Estado: </b>
                    {contextIndex[index].info.estado
                      ? contextIndex[index].info.estado
                      : ""}
                  </p>
                  <p>
                    <b>NSE: </b>
                    {contextIndex[index].info.nse
                      ? contextIndex[index].info.nse
                      : ""}
                  </p>
                  <p>
                    <b>Proyecto: </b>
                    {contextIndex[index].info.proyecto
                      ? contextIndex[index].info.proyecto
                      : ""}
                  </p>
                  <p>
                    <b>Documento: </b>
                    {contextIndex[index].info.proyecto
                      ? contextIndex[index].info.proyecto
                      : ""}
                  </p>
                  <p>
                    <b>Texto: </b>
                  </p>

                  <Highlighter
                    className={style.highlighter}
                    highlightClassName={styles.foundWord}
                    searchWords={[searchTerm, otherTerm]}
                    textToHighlight={contextIndex[index].text}
                  />
                </div>
              </Col>
              {/* <Col md={6} sm={12} className="pb10">
                <div className="contSlider h200">
                  <p>
                    <b>Ciudad: </b>
                    {contextIndex[index + 1].info.ciudad}
                  </p>
                  <p>
                    <b>Estado: </b>
                    {contextIndex[index + 1].info.estado}
                  </p>
                  <p>
                    <b>NSE: </b>
                    {contextIndex[index + 1].info.nse}
                  </p>
                  <p>
                    <b>Proyecto: </b>
                    {contextIndex[index + 1].info.proyecto}
                  </p>
                  <p>
                    <b>Documento: </b>
                    {contextIndex[index + 1].info.proyecto}
                  </p>
                  <p>
                    <b>Texto: </b>
                  </p>
                  <Highlighter
                    className={style.highlighter}
                    highlightClassName={styles.foundWord}
                    searchWords={[searchTerm, otherTerm]}
                    textToHighlight={contextIndex[index + 1].text}
                  />
                </div>
              </Col> */}
            </Row>
            <Carousel.Caption />
          </Carousel.Item>
        );
      }
    });
    return context;
  }

  return (
    <>
      <Col md={6} sm={12} className={clsx(styles.section, "pt25")}>
        <div>
          <p className="ftn13 pb10 txt_bold">
            Top 10 términos en todo el universo de búsqueda relacionado al
            término: “{searchTerm}”
            <Tooltip
              arrow
              title="Lista de los diez términos más frecuentes dentro de la lista de
              párrafos en que aparece el término buscado. El índice representa la
              proporción de instancias en que aparecen ambos términos en un mismo
              contexto del total de párrafos en que aparece el término buscado."
              placement="top"
            >
              <InfoIcon
                style={{ width: 12, color: "#242424", marginLeft: 5 }}
              />
            </Tooltip>
          </p>
        </div>
        <ComponentWithState state={topData} reload={reload}>
          <BarChar labels={labels} scores={scores} backgroundColors={colors} />
        </ComponentWithState>
      </Col>
      <Col md={6} sm={12} className={clsx(styles.section, "pt25")}>
        <div>
          <p className="ftn13 pb10 txt_bold">
            Comparativa de índice de similitud con el término “{searchTerm}”
          </p>
        </div>
        <ComponentWithState state={topData} reload={reload}>
          <div style={{ padding: "7% 0" }}>
            <Row>
              <Col md={6} sm={12}>
                {renderComparative().first}
              </Col>
              <Col md={6} sm={12}>
                {renderComparative().second}
              </Col>
            </Row>
          </div>
        </ComponentWithState>
      </Col>
      {showContext ? (
        <Col md={12} sm={12} className="pt25">
          <Carousel className="contextFlechas">{renderContext()}</Carousel>
        </Col>
      ) : null}
    </>
  );
};

export default TopTerms;
