import React from 'react';
import * as userData from '../services/userData';

const User = React.createContext({
  username: '',
  can_answer_survey: false,
  can_view_dashboard: false,
});
const { Provider, Consumer } = User;

class UserProvider extends React.Component {
  state = {
    data: null,
  };

  constructor(props) {
    super(props);
    this.getUserData = this.getUserData.bind(this);
    console.log('USER DATA ? ==> ', userData, this.props);
  }

  getUserData() {
    console.log('GETTING USER DATA ?', this.state.data);
    // if(this.state.data){
    //     return;
    // }
    if (localStorage.length > 0) {
      const data = userData.getUserData();
      console.log('YES!!!!', data);

      this.setState({ data });
    }
  }

  render() {
    const { children } = this.props;

    return (
      <Provider
        value={{
          user: this.state.data,
          getUserData: this.getUserData,
        }}
      >
        {children}
      </Provider>
    );
  }
}

export default User;
export { UserProvider, Consumer as UserConsumer };
