import React, { useState, useEffect, createRef } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Accordion,
  useAccordionToggle,
  Modal,
  Table,
} from 'react-bootstrap';
import parse from 'html-react-parser';
import Dropzone from 'react-dropzone';
import MainNav from '../components/ui/navbar';
import {
  getValidationOrgs,
  validateOrgs,
  stopOrgsValidation,
} from '../utils/validationEntitiesCalls';
import {
  getValidationProd,
  validateProd,
  stopProdValidation,
} from '../utils/validationProd';
import EntitiesInfo from '../components/OrgsInfo';
import ProdInfo from '../components/ProdsInfo';
import '../styles/carga.css';
import '../styles/generic.css';
import '../styles/validation.css';

const Entities = () => {
  const access_token = JSON.parse(localStorage.getItem('auth0'));
  const sizeOrgs = 3;
  const sizeProd = 3;
  const size = sizeOrgs + sizeProd;
  const accessToken = localStorage.getItem('accessToken');

  const [orgsData, setOrgsData] = useState([]);
  const [numValidated, setNumValidated] = useState(0);

  const [validatedIds, setValidatedIds] = useState([]);

  const [productsData, setProductsData] = useState([]);
  const [validatedProdIds, setValidatedProdIds] = useState([]);

  const [showSpinner, setShowSpinner] = useState(false);

  if (!access_token) {
    window.location.href = '/';
  }

  useEffect(() => {
    // Fetch Orgs Data
    // Buscamos en el localStorage (por si hubo refresh)
    const entitiesData = JSON.parse(localStorage.getItem('entitiesData'));
    const prodData = JSON.parse(localStorage.getItem('prodData'));
    console.log('Size', size);

    if (entitiesData) {
      console.log('Using saved entities');
      console.log(entitiesData);
      setOrgsData(entitiesData);
    } else {
      getValidationOrgs(sizeOrgs, accessToken).then((data) => {
        console.log('data ===>', data);
        setOrgsData(data);
        // y lo guardamos en el localStorage
        localStorage.setItem('entitiesData', JSON.stringify(data));
        console.log('Local Storage', localStorage.getItem('entitiesData'));
      });
    }
    if (prodData) {
      console.log('Using saved products', prodData);
      setProductsData(prodData);
    } else {
      getValidationProd(sizeProd, accessToken).then((data) => {
        setProductsData(data);
        localStorage.setItem('prodData', JSON.stringify(data));
        console.log('Local Storage', localStorage.getItem('prodData'));
      });
    }
  }, []);

  // Si ya se validó todo -> cargar nuevas entities
  useEffect(() => {
    if (numValidated === size) {
      console.log('validated all entities!!!');
      // Obtener nuevas orgs para validar:
      getValidationOrgs(sizeOrgs, accessToken).then((data) => {
        console.log('data ===>', data);
        setOrgsData(data);
        // Update localStorage
        localStorage.setItem('entitiesData', JSON.stringify(data));
        console.log('Local Storage', localStorage.getItem('entitiesData'));
        setNumValidated(0);
      });
      getValidationProd(sizeProd, accessToken).then((data) => {
        console.log('data ===>', data);
        setProductsData(data);
        // Update localStorage
        localStorage.setItem('prodData', JSON.stringify(data));
        console.log('Local Storage', localStorage.getItem('prodData'));
      });
    }
  }, [numValidated]);

  // Cada vez que se hace una validación correctamente:
  // (esta función se llama desde el componente)
  function entityValidated(entityId) {
    console.log('Entity validated');
    // Guardar id validado
    setValidatedIds((validatedIds) => [...validatedIds, entityId]);
    setNumValidated(numValidated + 1);

    document.getElementById(entityId).classList.add('is-validated');
  }

  function productValidated(prodId) {
    console.log('Product validated');
    setValidatedProdIds((validatedProdIds) => [...validatedProdIds, prodId]);
    setNumValidated(numValidated + 1);

    document.getElementById(prodId).classList.add('is-validated');
  }

  // On click "Finalizar validación": end Review
  function endReview() {
    console.log(validatedIds);

    stopOrgsValidation(validatedIds, accessToken).then((response) => {
      setShowSpinner(true);
      localStorage.removeItem('entitiesData');
      stopProdValidation(validatedProdIds, accessToken).then((answer) => {
        localStorage.removeItem('prodData');
        window.location.href = '/welcome';
      });
    });
  }

  function renderEntities() {
    console.log('Rendering entities');
    const entities = [];
    orgsData.forEach((org) => {
      entities.push(
        <EntitiesInfo
          key={org.id}
          orgInfo={org}
          entityValidated={entityValidated}
        />
      );
    });

    return entities;
  }

  function renderProducts() {
    const products = [];
    productsData.forEach((prod) => {
      products.push(
        <ProdInfo
          key={prod.id}
          prodInfo={prod}
          productValidated={productValidated}
        />
      );
    });

    return products;
  }

  return (
    <Container fluid>
      <MainNav />
      <Row>
        <Container fluid>
          <Col className="">
            <Col className="pb50">
              <h4 className="section-title font-medium txt_random txt_left pb25">
                Validación de Organizaciones
              </h4>
              <Col md={12} sm={12} className="p0">
                <Form inline>{renderEntities()}</Form>
              </Col>
            </Col>
            <Col className="pb50">
              <h4 className="section-title font-medium txt_random txt_left pb25">
                Validación de Productos
              </h4>
              <Col md={12} sm={12} className="p0">
                <Form inline>{renderProducts()}</Form>
              </Col>
            </Col>
            <Form.Group className="validation-form">
              {showSpinner ? (
                <div className="spinner-border text-success" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <button
                  id="endValidation"
                  className="btn-blue validation-button font-medium text-center"
                  onClick={(e) => endReview()}
                >
                  Finalizar validación
                </button>
              )}
            </Form.Group>
          </Col>
        </Container>
      </Row>
    </Container>
  );
};

export default Entities;
