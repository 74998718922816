import React, { useState, createRef } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Accordion,
  useAccordionToggle,
  Modal,
} from 'react-bootstrap';
import parse from 'html-react-parser';
import Dropzone from 'react-dropzone';
import MainNav from '../components/ui/navbar';
import '../styles/carga.css';
import '../styles/busqueda.css';
import '../styles/generic.css';

const newProyect = () => {
  const access_token = JSON.parse(localStorage.getItem('auth0'));
  if (!access_token) {
    window.location.href = '/';
  }

  // Limpiar el localstorage de busquedas pasadads
  /*
    localStorage.removeItem('num_pages_terms');
    localStorage.removeItem('current_page_terms');
    localStorage.removeItem('search_after_terms');
    localStorage.removeItem('total_results_terms');
    localStorage.removeItem('last_index_terms');
    localStorage.removeItem('num_pages_documents');
    localStorage.removeItem('current_page_documents');
    localStorage.removeItem('search_after_documents');
    localStorage.removeItem('total_results_documents');
    */

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
      console.log('totally custom!')
    );

    return (
      <button type="button" className="filterButton" onClick={decoratedOnClick}>
        {children}
      </button>
    );
  }

  function Example() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <>
        <button
          type="button"
          className="SearchButtonDesactive"
          onClick={handleShow}
        >
          Registrar
        </button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <button variant="secondary" onClick={handleClose}>
              Close
            </button>
            <button variant="primary" onClick={handleClose}>
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const Carga = () => {
    const dropzoneRef = createRef();
    const openDialog = () => {
      // Note that the ref is set async,
      // so it might be null at some point
      if (dropzoneRef.current) {
        dropzoneRef.current.open();
      }
    };
    const onDrop = (acceptedFiles) => {
      // Do something with the files
      alert('drop');
    };
    /* setTimeout(function(){
            var LoaderExist = document.getElementById('LoaderContainer');
            console.log(LoaderExist);
            if (LoaderExist){
                LoaderExist.style.display = 'none';
            }
        }, 1000); */
    return (
      <Container fluid className="p0">
        <Row>
          <Dropzone onDrop={onDrop} ref={dropzoneRef} noClick noKeyboard>
            {({ getRootProps, getInputProps, acceptedFiles }) => {
              return (
                <Col md={12} sm={12} className="DropZoneContainer">
                  <div
                    {...getRootProps({
                      className: 'dropzone ItemsDropZoneContainer',
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className="imgIcoUpload">
                      <img src="/img/icon_dowload.svg" className="img-icon" />
                    </div>
                    <div className="busqueda-title font-medium text-center">
                      Arrastra y suelta tu archivo aquí o…
                    </div>
                    <aside>
                      <h4 className="busqueda-title font-medium text-center">
                        Archivo
                      </h4>
                      <ul>
                        {acceptedFiles.map((file) => (
                          <li key={file.path}>
                            {file.path} - {file.size} bytes
                          </li>
                        ))}
                      </ul>
                    </aside>
                    <div className="SelectFileContainer">
                      <button
                        className="SelectFile"
                        type="button"
                        onClick={openDialog}
                      >
                        Selecciona archivos
                      </button>
                    </div>
                  </div>
                </Col>
              );
            }}
          </Dropzone>
        </Row>
        <Col md={12} sm={12} className="SelectFileContainer">
          <button className="BtnCargar">Cargar</button>
        </Col>
      </Container>
    );
  };

  return (
    <Container fluid>
      <MainNav />
      <Row>
        <Container fluid>
          <Col className="registerProyect">
            <Col md={6} sm={12} className="pb50">
              <h4 className="section-title font-medium txt_random txt_left pb25">
                Selecciona el proyecto al que pertenece tu archivo
              </h4>
              <Form inline>
                <Form.Control id="" as="select" className="my-1 mr-sm-2" custom>
                  <option value="">Todos</option>
                  <option value="">Campo Nescaf&eacute;</option>
                  <option value="">
                    Proyecto econom&iacute;a familiar demanda
                  </option>
                  <option value="">Sazonadores Nestl&eacute;</option>
                  <option value="">Familias progresistas</option>
                  <option value="">Desayunos</option>
                  <option value="">Campo yogurt</option>
                  <option value="">Econom&iacute;a Familiar</option>
                  <option value="">Seguimiento changarros</option>
                  <option value="">Oxxo comer fuera de casa</option>
                  <option value="">
                    Lo que entra en casa de las familias de clase media
                    mexicanas
                  </option>
                  <option value="">Estilo de vida NIVELES</option>
                  <option value="">Campo limpio Mabe M&eacute;xico</option>
                  <option value="">Campo leche</option>
                  <option value="">Campo vivienda</option>
                  <option value="">ADO</option>
                </Form.Control>
              </Form>
            </Col>
            <Col md={12} sm={12} className="pb50">
              <Col md={12} sm={12} className="p0">
                <h4 className="busqueda-title font-medium txt_random txt_left pb25">
                  Entidades a registrar pre-seleccionadas
                </h4>
              </Col>

              <Form inline>
                <Col md={4} sm={12} className="pb10 pl0 pr30">
                  <Form.Label
                    className="my-1 mr-2 inline-label font-medium ftn15"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Hogar general
                  </Form.Label>
                  <Form.Group className="inline-label float-r">
                    <button id="btnRegisterForm" className="btnRegister">
                      <a href="https://deepdivedatascienceapi.typeform.com/to/OiPdcoYe#a=xxxxx">
                        Registrado
                      </a>
                    </button>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12} className="pb10 pl0 pr30">
                  <Form.Label
                    className="my-1 mr-2 inline-label font-medium ftn15"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Objeto / Producto / Servicio
                  </Form.Label>
                  <Form.Group className="inline-label float-r">
                    <button id="btnRegisterForm" className="SearchButton">
                      <a href="">Registrar</a>
                    </button>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12} className="pb10 pl0 pr30">
                  <Form.Label
                    className="my-1 mr-2 inline-label font-medium ftn15"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Alimento
                  </Form.Label>
                  <Form.Group className="inline-label float-r">
                    <button
                      id="btnRegisterForm"
                      className="SearchButton"
                      href=""
                    >
                      <a href="">Registrar</a>
                    </button>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12} className="pb10 pl0 pr30">
                  <Form.Label
                    className="my-1 mr-2 inline-label font-medium ftn15"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Persona
                  </Form.Label>
                  <Form.Group className="inline-label float-r">
                    <button
                      id="btnRegisterForm"
                      className="SearchButton"
                      href=""
                    >
                      <a href="">Registrar</a>
                    </button>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12} className="pb10 pl0 pr30">
                  <Form.Label
                    className="my-1 mr-2 inline-label font-medium ftn15"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Trabajo
                  </Form.Label>
                  <Form.Group className="inline-label float-r">
                    <button id="btnRegisterForm" className="SearchButton">
                      <a href="https://deepdivedatascienceapi.typeform.com/to/rLxnTHhR#a=xxxxx">
                        Registrar
                      </a>
                    </button>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12} className="pb10 pl0 pr30">
                  <Form.Label
                    className="my-1 mr-2 inline-label font-medium ftn15"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Bebida
                  </Form.Label>
                  <Form.Group className="inline-label float-r">
                    <button
                      id="btnRegisterForm"
                      className="SearchButton"
                      href=""
                    >
                      <a href="">Registrar</a>
                    </button>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12} className="pb10 pl0 pr30">
                  <Form.Label
                    className="my-1 mr-2 inline-label font-medium ftn15"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Actividades
                  </Form.Label>
                  <Form.Group className="inline-label float-r">
                    <button
                      id="btnRegisterForm"
                      className="SearchButton"
                      href=""
                    >
                      <a href="">Registrar</a>
                    </button>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12} className="pb10 pl0 pr30">
                  <Form.Label
                    className="my-1 mr-2 inline-label font-medium ftn15"
                    htmlFor="inlineFormCustomSelectPref"
                  >
                    Comercio / Negocio
                  </Form.Label>
                  <Form.Group className="inline-label float-r">
                    <button id="btnRegisterForm" className="SearchButton">
                      <a href="https://deepdivedatascienceapi.typeform.com/to/TXWfaXdb#a=xxxxx">
                        Registrar
                      </a>
                    </button>
                  </Form.Group>
                </Col>
              </Form>
            </Col>
            <Col md={12} sm={12} className="pb50">
              <Accordion>
                <Card>
                  <Card.Header className="p0">
                    <Col md={12} sm={12} className="p0">
                      <h4 className="busqueda-title font-medium txt_random txt_left pb25 inline-label">
                        Agregar más entidades a registrar
                      </h4>
                      <Form.Group className="inline-label pl10 moreButton">
                        <CustomToggle eventKey="0">+</CustomToggle>
                      </Form.Group>
                    </Col>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="p0">
                      <Form inline>
                        <Col md={4} sm={12} className="pb10 pl0 pr30">
                          <Form.Label
                            className="my-1 mr-2 inline-label font-medium ftn15"
                            htmlFor="inlineFormCustomSelectPref"
                          >
                            Vivienda general
                          </Form.Label>
                          <Form.Group className="inline-label float-r">
                            <Example>
                              <a href="">Registrar</a>
                            </Example>
                          </Form.Group>
                        </Col>
                        <Col md={4} sm={12} className="pb10 pl0 pr30">
                          <Form.Label
                            className="my-1 mr-2 inline-label font-medium ftn15"
                            htmlFor="inlineFormCustomSelectPref"
                          >
                            Sala
                          </Form.Label>
                          <Form.Group className="inline-label float-r">
                            <Example>
                              <a href="https://deepdivedatascienceapi.typeform.com/to/HIjT1bjr#a=xxxxx">
                                Registrar
                              </a>
                            </Example>
                          </Form.Group>
                        </Col>
                        <Col md={4} sm={12} className="pb10 pl0 pr30">
                          <Form.Label
                            className="my-1 mr-2 inline-label font-medium ftn15"
                            htmlFor="inlineFormCustomSelectPref"
                          >
                            Cocina
                          </Form.Label>
                          <Form.Group className="inline-label float-r">
                            <Example>
                              <a href="https://deepdivedatascienceapi.typeform.com/to/FnOBm06P#a=xxxxx">
                                Registrar
                              </a>
                            </Example>
                          </Form.Group>
                        </Col>
                        <Col md={4} sm={12} className="pb10 pl0 pr30">
                          <Form.Label
                            className="my-1 mr-2 inline-label font-medium ftn15"
                            htmlFor="inlineFormCustomSelectPref"
                          >
                            Comedor
                          </Form.Label>
                          <Form.Group className="inline-label float-r">
                            <Example>
                              <a href="">Registrar</a>
                            </Example>
                          </Form.Group>
                        </Col>
                        <Col md={4} sm={12} className="pb10 pl0 pr30">
                          <Form.Label
                            className="my-1 mr-2 inline-label font-medium ftn15"
                            htmlFor="inlineFormCustomSelectPref"
                          >
                            Cuarto
                          </Form.Label>
                          <Form.Group className="inline-label float-r">
                            <Example>
                              <a href="https://deepdivedatascienceapi.typeform.com/to/JKk7bOKL#a=xxxxx">
                                Registrar
                              </a>
                            </Example>
                          </Form.Group>
                        </Col>
                        <Col md={4} sm={12} className="pb10 pl0 pr30">
                          <Form.Label
                            className="my-1 mr-2 inline-label font-medium ftn15"
                            htmlFor="inlineFormCustomSelectPref"
                          >
                            Espacio Exterior
                          </Form.Label>
                          <Form.Group className="inline-label float-r">
                            <Example>
                              <a href="https://deepdivedatascienceapi.typeform.com/to/GCQVEEFU#a=xxxxx">
                                Registrar
                              </a>
                            </Example>
                          </Form.Group>
                        </Col>
                      </Form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
            <Col md={12} sm={12}>
              <Carga />
            </Col>
          </Col>
        </Container>
      </Row>
    </Container>
  );
};

export default newProyect;
