import React, { useState } from "react";
import times from "lodash/times";
import parse from "html-react-parser";

import { Col, Table } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import Skeleton from "@material-ui/lab/Skeleton";

import "styles/generic.css";
// import questionData from '../../data/top_10_qa.json'

const QuestionAnswering = ({ questionData, searchTerm }) => {
  const [showContext, setShowContext] = useState(false);
  const [contextIndex, setContextIndex] = useState();

  const handleContext = (index) => {
    console.log("hernaaaan");
    console.log(questionData[index]);
    setShowContext(true);
    setContextIndex(questionData[index]);
  };

  const renderData = () =>
    questionData.length
      ? questionData.map((answer, index) => (
          <tr key={answer.score}>
            <td> {`${(answer.score * 100).toFixed(2).toString()}%`} </td>
            <td key={answer.full_answer}> {answer.answer} </td>
            <td className="w100 linkT linkContext" key={answer.context}>
              <button
                type="button"
                id={index}
                className="btn-gray2 context-button-small ftn11 text_left"
                onClick={() => handleContext(index)}
              >
                Ver contexto
              </button>
            </td>
          </tr>
        ))
      : times(9, () => (
          <tr>
            <td>
              <Skeleton variant="text" />
            </td>
            <td>
              <Skeleton variant="text" />
            </td>
            <td className="w100">
              <Skeleton variant="text" />
            </td>
          </tr>
        ));

  return (
    <>
      <div>
        <p className="ftn13 pb10 txt_bold">
          Top 10 respuestas ¿Qué es "
          {searchTerm ||
            (questionData.length ? questionData[0].answer.split(" ")[0] : "")}
          "? de 10,263 Documentos
          <Tooltip
            arrow
            title={`Esta gráfica muestra las respuestas más probables a la pregunta de ¿Qué
            es ____? Donde la línea en blanco representa el término buscado. Por
            ejemplo, ante la pregunta de ¿Qué es salud?, el modelo puede responder,
            "Salud es fisioterapia", "Salud es tiempo libre", etc. El índice de
            certeza se refiere a qué tan bien la respuesta responde a la pregunta.`}
            placement="top"
          >
            <InfoIcon style={{ width: 12, color: "#242424", marginLeft: 5 }} />
          </Tooltip>
        </p>
      </div>
      <div className="borderT">
        <Table className="ftn11 tableNewDashboard">
          <thead>
            <tr>
              <th className="w20">Certeza</th>
              <th colSpan="2">Respuesta</th>
            </tr>
          </thead>
          <tbody>{renderData()}</tbody>
        </Table>
      </div>
      {showContext && (
        <Col md={12} sm={12} className="back-gray borderR-15 mt20 p20">
          <div>
            <p className="ftn13 pb10"> {parse(contextIndex.context)} </p>
            <p className="ftn13 pb10">
              {" "}
              <b>Nombre documento: </b>{" "}
              {parse(contextIndex.doc_name ? contextIndex.doc_name : "")}
            </p>
            <p className="ftn13 pb10">
              <b>Ciudad: </b>{" "}
              {parse(
                contextIndex.context_source.ciudad
                  ? contextIndex.context_source.ciudad
                  : ""
              )}
            </p>
            <p className="ftn13 pb10">
              <b>Estado: </b>{" "}
              {parse(
                contextIndex.context_source.estado
                  ? contextIndex.context_source.estado
                  : ""
              )}
            </p>
            <p className="ftn13 pb10">
              <b>NSE: </b>{" "}
              {parse(
                contextIndex.context_source.nse
                  ? contextIndex.context_source.nse
                  : ""
              )}
            </p>
            <p className="ftn13 pb10">
              <b>Nombre proyecto: </b>
              {parse(
                contextIndex.context_source.proyecto
                  ? contextIndex.context_source.proyecto
                  : ""
              )}
            </p>
          </div>
        </Col>
      )}
    </>
  );
};

export default QuestionAnswering;
