// const url = "https://1astdjd11g.execute-api.us-west-2.amazonaws.com/api/v1";

const url = `${process.env.REACT_APP_BACKEND_VIZ_API_HOST}/api/v1`;

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept",
};

export async function getSemantics(term) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(
    `${url}/summary/general_semantics?term_to_lookup=${term}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }
  );
  // if(response.status >= 400 && response.status < 600){
  //     console.log("Capture Error Token")
  //     window.alert("Su sesión ha caducado. Por favor vuelva a iniciar sesión");
  //     window.location.href = '/';
  // }
  // else{
  //     return await response.json();
  // }
  return response.json();
}

export async function getNseComparison(term) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(
    `${url}/summary/nse_comparison?term_to_lookup=${term}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }
  );

  if (response.status >= 500 && response.status < 600) {
    console.log("Capturing error nse");
    return "Error: Sin suficiente información";
  }
  return response.json();
}

export async function getTopicBubbles() {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(`${url}/summary/topic_bubble`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  });
  return response.json();
}

export async function getTopicContexts(topicId) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(
    `${url}/summary/topic_contexts?topic_id=${topicId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }
  );
  return response.json();
}

export async function getStaticQa() {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(`${url}/summary/static_qa`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  });
  return response.json();
}

export async function getWordCloud(term) {
  const accessToken = localStorage.getItem("accessToken");
  const response = await fetch(
    `${url}/summary/word_cloud?term_to_lookup=${term}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }
  );
  return response.json();
}
