import React, { useEffect, useState } from "react";
import Graph from "react-graph-vis";
import times from "lodash/times";
import Skeleton from "@material-ui/lab/Skeleton";

import { Container, Row, Col, Table, Carousel } from "react-bootstrap";

import MainNav from "../../components/ui/navbar";
import { getAllContexts } from "./getContext";

const apiUrl = `${process.env.REACT_APP_BACKEND_API_HOST}/api/v2/network/`;


const dummyData = {
  counter: 5,
  graph: {
    nodes: [],
    edges: [],
  },
  events: {
    select: ({ nodes, edges }) => {
      console.log("Selected nodes:");
      console.log(nodes);
      console.log("Selected edges:");
      console.log(edges);
    },
  },
};

const selectEvents = {
  select: ({ nodes, edges }) => {
    console.log(nodes);
    console.log(edges);
  },
};

const Neo4J = () => {
  const accessToken = JSON.parse(localStorage.getItem("auth0"));
  const [networkState, setNetworkState] = useState(dummyData);
  const [numResultados, setNumResultados] = useState(10);
  const [edgeLengths, setEdgeLengths] = useState(100);
  const { graph, events } = networkState;
  const [searchTerm, setSearchTerm] = useState("");
  const [topTerms, setTopTerms] = useState([]);
  const [topTermsContext, setTopTermsContext] = useState([]);
  const [showContext, setShowContext] = useState(false);
  const [currentContext, setCurrentContext] = useState();

  const options = {
    width: "100%",
    layout: {
      randomSeed: undefined,
      improvedLayout: true,
      hierarchical: {
        enabled: false,
        levelSeparation: 150,
        nodeSpacing: 100,
        treeSpacing: 200,
        blockShifting: true,
        edgeMinimization: true,
        parentCentralization: true,
        direction: "UD", // UD, DU, LR, RL
        sortMethod: "hubsize", // hubsize, directed
      },
    },
    nodes: {
      shape: "dot",
      font: {
        color: "black",
        size: 18,
        strokeWidth: 5,
        strokeColor: "white",
      },
    },
    edges: {
      length: 400,
      color: {
        color: "#64748b",
        highlight: "#707272",
      },
      smooth: {
        type: "vertical",
        forceDirection: "none",
        roundness: 0.3,
      },
      font: {
        size: 14,
        align: "top",
      },
    },
    physics: {
      barnesHut: {
        gravitationalConstant: -6000,
        centralGravity: 0.4,
        springLength: 90,
        springConstant: 0.001,
      },
      minVelocity: 0.75,
    },
  };

  
  const calcEdgeLength = (numNodes) => {
    if (numNodes < 10) {
      return 200;
    }
    if (numNodes < 20) {
      return 300;
    }
    if (numNodes < 30) {
      return 400;
    }
    if (numNodes < 40) {
      return 500;
    }
    return 600
  }
  const handleContext = (index) => {
    console.log("handling context");
    if (topTermsContext.length === 0) return;
    console.log("there is available context for handle context");
    console.log(topTermsContext[index]);
    setShowContext(true);
    setCurrentContext(topTermsContext[index]);
  };
  async function fetchNetwork() {
    console.log("fetching the terms");
    await fetch(`${apiUrl}get_network?term=${searchTerm}&top_n=${numResultados}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken.id_token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("neo4j data");
        console.log(data);
        const newNetworkState = {
          counter: data.nodes.length,
          graph: data,
          events: selectEvents,
        };
        setNetworkState(newNetworkState);

        setEdgeLengths(calcEdgeLength(data.nodes.length));
      });
  }
  async function fetchTopTerms() {
    console.log("fetching the terms");
    await fetch(`${apiUrl}get_top_terms?term=${searchTerm}&top_n=${numResultados}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken.id_token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("fetched top terms data");
        console.log(data);
        setTopTerms(data.response);
      });
  }

  function renderContext() {
    const renderedContext = [];
    currentContext.map((singleContext, index) => {
      if (index < 11) {
        renderedContext.push(
          <Carousel.Item className="p40">
            <Row>
              <Col md={12} sm={12} className="pb10">
                <div className="contSlider h200">
                  <p>
                    <b>Ciudad: </b>
                    {currentContext[index].info.ciudad
                      ? currentContext[index].info.ciudad
                      : ""}
                  </p>
                  <p>
                    <b>Estado: </b>
                    {currentContext[index].info.estado
                      ? currentContext[index].info.estado
                      : ""}
                  </p>
                  <p>
                    <b>NSE: </b>
                    {currentContext[index].info.nse
                      ? currentContext[index].info.nse
                      : ""}
                  </p>
                  <p>
                    <b>Proyecto: </b>
                    {currentContext[index].info.proyecto
                      ? currentContext[index].info.proyecto
                      : ""}
                  </p>
                  <p>
                    <b>Documento: </b>
                    {currentContext[index].info.doc_name
                      ? currentContext[index].info.doc_name
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Carousel.Caption />
          </Carousel.Item>
        );
      }
      return "dumb";
    });
    return renderedContext;
  }

  useEffect(() => {
    async function getContexts() {
      if (topTerms.length === 0) return;
      getAllContexts(topTerms, accessToken).then((data) => {
        console.log("data from get all contexts");
        console.log(data);
        setTopTermsContext(data);
      });
    }
    console.log("Use effect getting all top terms context");
    getContexts();
  }, [topTerms]);

  const renderTopTerms = () =>
    topTerms.length
      ? topTerms.map((term, index) => (
          <tr key={term.num_docs}>
            <td> {`${term.num_docs.toFixed(2).toString()}`} </td>
            <td key={index}> {term.text} </td>
            <td className="w100 linkT linkContext" key={index}>
              <button
                type="button"
                id={index}
                className="btn-gray2 context-button-small ftn11 text_left"
                onClick={() => handleContext(index)}
              >
                Ver contexto
              </button>
            </td>
          </tr>
        ))
      : times(9, () => (
          <tr>
            <td>
              <Skeleton variant="text" />
            </td>
            <td>
              <Skeleton variant="text" />
            </td>
            <td className="w100">
              <Skeleton variant="text" />
            </td>
          </tr>
        ));

  return (
    <div>
      <Container fluid>
        <Row>
          <MainNav />
        </Row>
        <div
          style={{
            marginLeft: 50,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Row>
            <div style={{ display: "flex", marginBottom: "0.8rem" }}>
              <input
                type="text"
                placeholder="Buscar término"
                className="inputTerms"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                  
                }}
                style={{ marginRight: "0.8rem" }}
              />
              <input
                type="text"
                placeholder="num resultados"
                className="inputTerms"
                onChange={(event) => {
                  console.log("num de resultados: ")
                  console.log(event.target.value)
                  setNumResultados(event.target.value);
                  
                }}
                style={{ marginRight: "0.8rem" }}
              />
              <button
                type="button"
                className="SearchButton"
                onClick={() => {
                  fetchTopTerms();
                  fetchNetwork();
                }}
              >
                Buscar!
              </button>
            </div>
          </Row>
          <p className="ftn50 pb5 txt_bold">Top Respuestas ¿Qué es {`${searchTerm}`}? en 100 Documentos"</p>
          <Row style={{ marginBottom: "0.8rem" }}>
            
            <div className="borderT">
              <Table className="ftn11 tableNewDashboard">
                <thead>
                  <tr>
                    <th className="w20">Puntuación</th>
                    <th colSpan="2">Respuesta</th>
                  </tr>
                </thead>
                <tbody>{renderTopTerms()}</tbody>
              </Table>
            </div>
          </Row>
          {showContext ? (
            <Col md={12} sm={12} className="pt25">
              <Carousel className="contextFlechas">{renderContext()}</Carousel>
            </Col>
          ) : null}
          <p className="ftn50 pb5 txt_bold">Diagrama de Network</p>
          <Row>
            <Graph
              graph={graph}
              options={options}
              events={events}
              style={{
                height: "1300px",
                width: "85%",
                backgroundColor: "#F5F5F5",
                marginBottom: "2rem",
              }}
            />
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Neo4J;
