import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarCharEmotion2 = ({ labelsNegative, scoresNegative }) => {
  return (
    <div>
      <Bar
        data={{
          labels: labelsNegative,
          datasets: [
            {
              label: 'Comparativa de índice ',
              data: scoresNegative,
              backgroundColor: [
                '#003189',
                '#003189',
                '#003189',
                '#003189',
                '#003189',
              ],
              borderWidth: 1,
            },
          ],
        }}
        height={300}
        width={400}
        options={{
          maintainAspectRatio: false,
        }}
      />
    </div>
  );
};
export default BarCharEmotion2;
