import React, { useState, useEffect } from "react";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import isEmpty from "lodash/isEmpty";

import { ResponsiveSwarmPlot } from "@nivo/swarmplot";
import Highlighter from "react-highlight-words";

import ComponentWithState from "components/ComponentWithState";

import styles from "./styles.module.scss";

export default function RelatedTerms({ wordCloud, getWordCloudData, term }) {
  const [selected, setSelected] = useState({});
  const sizes = [
    minBy(wordCloud.data, "size")?.size,
    maxBy(wordCloud.data, "size")?.size,
  ];
  // const [filteredWordCloud, setFilteredWordCloud] = useState([]);

  useEffect(() => {
    console.log("Wordcloud", wordCloud);
  }, [wordCloud]);

  return (
    <div className={styles.relatedTerms}>
      <h4>Términos asociados a la palabra {term}</h4>
      <ComponentWithState
        state={wordCloud}
        reload={() => getWordCloudData(term)}
      >
        <div className={styles.dashboard}>
          <ResponsiveSwarmPlot
            data={
              wordCloud.loaded
                ? wordCloud.data.map((item) => ({
                    id: item.word,
                    ...item,
                    group: "group A",
                  }))
                : []
            }
            groups={["group A"]}
            colorBy="id"
            identity="id"
            value="size"
            valueScale={{
              type: "linear",
              min: sizes[0],
              max: sizes[1],
              reverse: false,
            }}
            size={{ key: "size", values: sizes, sizes: [70, 100] }}
            spacing={1}
            forceStrength={2}
            onClick={({ data }) => setSelected(data)}
            simulationIterations={100}
            borderColor={{
              from: "color",
              modifiers: [
                ["darker", 0.6],
                ["opacity", 0.5],
              ],
            }}
            margin={{ top: 70, right: 120, bottom: 100, left: 100 }}
            enableGridX={false}
            enableGridY={false}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            tooltip={(event) => (
              <div
                style={{
                  fontSize: 10,
                  paddingLeft: 14,
                  paddingRight: 14,
                  paddingTop: 8,
                  paddingBottom: 8,
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: event.color,
                  background: "rgba(255, 255, 255, 0.7)",
                  borderRadius: 5,
                }}
              >
                <b style={{ textTransform: "capitalize" }}>{event.data.word}</b>
                <span style={{ marginLeft: 20 }}>Size: {event.data.size}</span>
              </div>
            )}
          />
        </div>
      </ComponentWithState>
      {!isEmpty(selected) && (
        <p style={{ fontSize: 11 }} className="back-gray borderR-15 mt20 p20">
          <Highlighter
            className={styles.highlighter}
            highlightClassName={styles.foundWord}
            searchWords={[selected.word]}
            textToHighlight={selected.context}
          />
        </p>
      )}
    </div>
  );
}
