/* eslint-disable import/no-named-as-default */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
// import topData from "../../data/top_10_terminos.json";
import { Row, Col, Carousel } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Highlighter from "react-highlight-words";

import ComponentWithState from "components/ComponentWithState";
import EmotionNetwork from "views/graphic/EmotionNetwork";

import BarChar from "../../views/graphic/BarChar";
import styles from "./styles.module.scss";
import style from "styles/modules/newDashboard.module.scss";

const TopEmotion = ({
  topTermsSpecific,
  searchTerm,
  selectedEmotion,
  setSelectedEmotion,
  emotions,
  getTermsSpecificEmotionData,
  color,
  emotionGraph,
  getEmotionGraphData,
}) => {
  const [labels, setLabels] = useState();
  const [scores, setScores] = useState();
  const [showContextEmotion, setShowContextEmotion] = useState(false);
  const [contextIndexEmotion, setContextIndexEmotion] = useState();
  const [otherTerm, setOtherTerm] = useState();
  const [contextOutside, setContextOutside] = useState(null);

  useEffect(() => {
    const labelsData = [];
    const scoresData = [];
    if (topTermsSpecific.loaded) {
      topTermsSpecific?.data.forEach((top) => {
        labelsData.push(top.term);
        scoresData.push(top.score);
      });
    }
    setLabels(labelsData);
    setScores(scoresData);
  }, [topTermsSpecific.loaded]);

  useEffect(() => {
    getEmotionGraphData(searchTerm, selectedEmotion);
  }, [selectedEmotion]);

  const colors = [
    color,
    color,
    color,
    color,
    color,
    color,
    color,
    color,
    color,
    color,
  ];

  const handleContext = (e) => {
    setShowContextEmotion(true);
    setContextIndexEmotion(topTermsSpecific?.data[e.target.id].context.text);
    setOtherTerm(topTermsSpecific?.data[e.target.id].term);
    setContextOutside(
      <Carousel className="contextFlechas">
        {renderContext(topTermsSpecific?.data[e.target.id].context)}
      </Carousel>
    );
  };

  const onClickNode = (node) => {
    console.log(node);
  };

  function renderComparative() {
    const first = [];
    const second = [];

    if (topTermsSpecific.loaded) {
      topTermsSpecific.data.forEach((top, index) => {
        const classIndex = `col-11 p0 pt10 contComparative border_${selectedEmotion.toString()}`;
        if (index < 5) {
          first.push(
            <div className="d-flex">
              <Col md={1} sm={1} className="p0 col-1">
                <p className="fnt20 txt_bold pt10"> {index + 1}.</p>
              </Col>
              <Col md={11} sm={11} className={classIndex}>
                <Col md={3} className="ftn11">
                  {" "}
                  {top.term}{" "}
                </Col>
                <Col md={4} className="ftn11">
                  Score: {top.score.toFixed(2)}{" "}
                </Col>
                <Col md={6} className="ftn11 p0">
                  <button
                    type="button"
                    id={index}
                    className="btn-white context-button-small ftn11 text-center w100p p0"
                    onClick={handleContext}
                  >
                    Ver contexto
                  </button>
                </Col>
              </Col>
            </div>
          );
        } else {
          second.push(
            <div className="d-flex">
              <Col md={1} sm={1} className="p0">
                <p className="fnt20 txt_bold pt10"> {index + 1}.</p>
              </Col>
              <Col md={11} sm={11} className={classIndex}>
                <Col md={4} className="ftn11">
                  {" "}
                  {top.term}{" "}
                </Col>
                <Col md={4} className="ftn11">
                  Score: {top.score.toFixed(2)}{" "}
                </Col>
                <Col md={4} className="ftn11 p0">
                  <button
                    type="button"
                    id={index}
                    className="btn-white context-button-small ftn11 text-center w100p p0"
                    onClick={handleContext}
                  >
                    Ver contexto
                  </button>
                </Col>
              </Col>
            </div>
          );
        }
      });
    }

    return { first, second };
  }

  function renderContext(context) {
    const contextEmotion = [];
    context.map((text, index) => {
      if (!(index % 2) && index < 11) {
        contextEmotion.push(
          <Carousel.Item className="p40">
            <Row>
              <Col md={6} sm={12} className="pb10">
                <div className="contSlider h200">
                  <Highlighter
                    className={styles.highlighter}
                    highlightClassName={style.foundWord}
                    searchWords={[searchTerm, otherTerm]}
                    textToHighlight={contextIndexEmotion[index]}
                  />
                </div>
              </Col>
              <Col md={6} sm={12} className="pb10">
                <div className="contSlider h200">
                  <Highlighter
                    className={styles.highlighter}
                    highlightClassName={style.foundWord}
                    searchWords={[searchTerm, otherTerm]}
                    textToHighlight={contextIndexEmotion[index + 1]}
                  />
                </div>
              </Col>
            </Row>
            <Carousel.Caption />
          </Carousel.Item>
        );
      }
    });
    return contextEmotion;
  }

  return (
    <>
      <Col md={12} sm={12} className={clsx("pt25", styles.emotionButtons)}>
        <h4 className={styles.title}>
          Relación de “{searchTerm}” y la emoción:
        </h4>
        <div>
          {emotions.map((emotion) => (
            <Button
              variant="contained"
              className={clsx(styles.button, {
                [styles[emotion]]: true,
                [styles.selected]: selectedEmotion === emotion,
              })}
              onClick={() => {
                setSelectedEmotion(emotion);
                setContextOutside(null);
              }}
            >
              {emotion}
            </Button>
          ))}
        </div>
      </Col>
      <Col md={6} sm={12} className={clsx(styles.section, "pt25")}>
        <ComponentWithState
          state={emotionGraph}
          reload={() => getEmotionGraphData(searchTerm, selectedEmotion)}
        >
          <EmotionNetwork
            emotiongraph={emotionGraph.data}
            emotion={selectedEmotion}
            // setContextOutside={setContextOutside}
          />
        </ComponentWithState>
      </Col>
      <Col md={6} sm={12} className={clsx(styles.section, "pt25")}>
        <div>
          <p className="ftn13 pb10 txt_bold">
            Top 10 términos en todo el universo de búsqueda relacionado al
            término: “{searchTerm}” y a la emoción: "{selectedEmotion}"
            <Tooltip
              arrow
              title={`Lista de los diez términos más frecuentes dentro de la lista de
              párrafos en que aparece el término buscado. El índice representa la
              proporción de instancias en que aparecen ambos términos en un mismo
              contexto del total de párrafos en que aparece el término buscado
              asociado con la emoción "${selectedEmotion}".`}
              placement="top"
            >
              <InfoIcon
                style={{ width: 12, color: "#242424", marginLeft: 5 }}
              />
            </Tooltip>
          </p>
        </div>
        <ComponentWithState
          state={topTermsSpecific}
          reload={() =>
            getTermsSpecificEmotionData(searchTerm, selectedEmotion)
          }
        >
          <>
            <BarChar
              labels={labels}
              scores={scores}
              backgroundColors={colors}
            />
            <div>
              <p className="ftn13 pt10 txt_bold">
                Comparativa de índice de similitud con el término “{searchTerm}”
              </p>
            </div>
            <div style={{ padding: "10px 0 7%" }}>
              <Row>
                <Col md={6} sm={12}>
                  {renderComparative().first}
                </Col>
                <Col md={6} sm={12}>
                  {renderComparative().second}
                </Col>
              </Row>
            </div>
          </>
        </ComponentWithState>
      </Col>
      {/* {showContextEmotion ? (
        <Col md={12} sm={12} className="pt10">
          <Carousel className="contextFlechas">{renderContext()}</Carousel>
        </Col>
      ) : null} */}
      <Col md={12} sm={12}>
        {contextOutside}
      </Col>
    </>
  );
};

export default TopEmotion;
