import React, {useEffect} from 'react';
import { Doughnut, defaults, Chart } from 'react-chartjs-2';
import sum from 'lodash/sum';

// Chart.defaults.plugins.legend.position = 'right';
const defaultLegendClickHandler = Chart.defaults.plugins.legend.onClick;
const newLegendClickHandler = function (e, legendItem, legend) {
  const index = legendItem.datasetIndex;

  if (index > 1) {
    // Do the original logic
    defaultLegendClickHandler(e, legendItem, legend);
  } else {
    const ci = legend.chart;
    [ci.getDatasetMeta(0), ci.getDatasetMeta(1)].forEach(function (meta) {
      meta.hidden =
        meta.hidden === null ? !ci.data.datasets[index].hidden : null;
    });
    ci.update();
  }
};


const DoughnutChar = ({ semanticData }) => {
  const total = sum(semanticData.data);
  function getColor(token) {
    if (token === 'Limpieza') {
      return '#3464AA';
    }
    if (token === 'Recreación - Entretenimiento' || token === 'Entretenimiento') {
      return '#A84188';
    }
    if (token === 'Medios De Comunicación') {
      return '#E79C71';
    }
    if (token === 'Finanzas') {
      return '#AAC869';
    }
    if (token === 'Bebida') {
      return '#D95F56';
    }
    if (token === 'Laboral' || token === 'Trabajo') {
      return '#916085';
    }
    if (token === 'Comercio') {
      return '#74A15F';
    }
    if (token === 'Religión') {
      return '#DDB73E';
    }
    if (token === 'Alimento' || token === 'Alimentación') {
      return '#CC5950';
    }
    if (token === 'Consumo') {
      return '#6BA0A5';
    }
    if (token === 'Valores Y Cultura') {
      return '#B2A882';
    }
    if (token === 'Seguridad') {
      return '#888FAA';
    }
    if (token === 'Política Y Gobierno' || token === 'Política') {
      return '#B78A8E';
    }
    if (token === 'Transporte' || token === 'Movilidad') {
      return '#8CAFA3';
    }
    if (token === 'Vivienda') {
      return '#FFCE00';
    }
    if (token === 'Relaciones Sociales') {
      return '#D96231';
    }
    if (token === 'Tecnología') {
      return '#24A7E0';
    }
    if (token === 'Electrodoméstico') {
      return '#D3E1BE';
    }
    if (token === 'Familia') {
      return '#D43A71';
    }
    if (token === 'Educación') {
      return '#A9D4E4';
    }
    if (token === 'Deporte') {
      return '#70589C';
    }
    if (token === 'Vestimenta') {
      return '#F7E491';
    }
    if (token === 'Materiales') {
      return '#A7AFC1';
    }
    if (token === 'Salud Y Cuidado' || token === 'Salud y cuidado') {
      return '#F7E491';
    }
    return 'otro color';
  }
  
  const colors = [];
  semanticData.labels.map((label) => {
    colors.push(getColor(label))
  })
  
  useEffect(() => {
    console.log("Colors", colors)
  }, [colors])
  return (
    <div>
      <Doughnut
        data={{
          labels: semanticData.labels,
          datasets: [
            {
              label: 'Semanticas',
              data: semanticData.data.map((data) => (data / total) * 100),
              backgroundColor: colors,
              // [
              //   '#3464AA',
              //   '#A84188',
              //   '#E79C71',
              //   '#AAC869',
              //   '#D95F56',
              //   '#FFCE00',
              //   '#D96231',
              //   '#D43A71',
              //   '#70589C',
              //   '#24A7E0',
              //   '#6BA0A5',
              //   '#74A15F',
              //   '#888FAA',
              //   '#8CAFA3',
              //   '#D33F37',
              //   '#F3D29A',
              // ],
              borderWidth: 1,
            },
          ],
        }}
        height={300}
        width={300}
        options={{
          maintainAspectRatio: false,
          legend: {
            labels: {
              fontSize: 25,
            },
            onClick: newLegendClickHandler,
          },
          responsive: true,
        }}
      />
    </div>
  );
};
export default DoughnutChar;
